import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Footer from "../Common/footer";
import NavbarMain from "../Navbar/navbarmain";
import _ from 'underscore';
import './css/guides.css'

let context = null;
export default class GuideHomepage extends Component{
    constructor(props){
        super(props)
        context=this;
        this.state={
            resdata:[],
            tags:[],
            activeTag:'all',
        }
    }

    componentDidMount(){
        this.fetchAllGuides()
    }

    fetchAllGuides(){
        axios({
            url:'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guide/list',
            method:'get',
            crossDomain:true,
            responseType:'json',
        }).then((response)=>{
            this.setState({resdata:response.data})
            this.getTags(response.data)
        })
    }

    getTags(guides){        
        let tags = [];

        _.each(guides,function(guide){
        
          if(guide.tags!==undefined){  
            tags = _.union(tags,guide.tags);
          }
         
        });
        context.setState({"tags":tags});
        
    }

    filterByTag(tag){
        this.setState({activeTag:tag})
        let resdata = context.state.resdata;

        if(tag==='all'){
        context.setState({guides:resdata})
        }

        else{
        let filteredGuides = _.filter(resdata,function(guide){

            return guide.tags!=undefined ? guide.tags.includes(tag) : false;
            
            });
            
            
            context.setState({resdata:filteredGuides});
        }
    }

    render(){
        return(
            <div className='mainbody'>
                       <NavbarMain subHead='Legal-Finance' url='legal-finance'/>
                <div className='row'>
                    <img className='banner-image' src= {require('../../Images/legal_Page_Banner.png')}/>
                </div>
                <div className='row'>
                    <div className='col-md-12 col-lg-12'>
                        <div className='col-md-9 col-lg-9'>
                        {this.state.resdata.map((guide)=>(
                            <div className='col-md-4'>
                                <div className='guide-card-contianer'>
                                    <div className='guide-card' style={{backgroundImage:'url('+guide.imageArray[0].imageURL+')'}}>
                                        <Link to={'/legal-finance/guides/'+guide.id}>
                                        <div className='guide-card-content'>
                                           <div className='guide-ct-al'><div className='guide-ct-name'> {guide.guideName} </div>
                                            <div className='guide-ct-description'> {guide.guideDescription} </div>
                                           </div>
                                           {/* <i class="far fa-file-alt"></i> */}
                                        </div>
                                        <span className='guide-card-strip-h'></span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        
                        <div className='col-md-3'>
                        <div className="guide-filter-tag">
                            <h5 className="heading9">Tags</h5> 
                            <div className="cloud">
                            <button onClick={()=>this.filterByTag('all')} className={this.state.activeTag=='all' ? 'button99 activeTag' : 'button99'} ><h4 className="heading24">All</h4></button>    
                            {this.state.tags.map((tag) => (
                            tag!=''?
                            <button onClick={()=>this.filterByTag(tag)} className={this.state.activeTag==tag ? 'button99 activeTag' : 'button99'} ><h4 className="heading24">{tag}</h4></button>    :''
                            ))}
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}