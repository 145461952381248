import React, { Component } from 'react';
import '../Navbar/navbarmain.css';
import { Link, BrowserRouter } from 'react-router-dom';
import Topbar from '../Topbar/topbar';
import SideBar from '../Sidebar/SideBar';



export default class NavbarMain extends Component {
  constructor(props){
    super(props);
    this.state={
      openmenu:false,
    }
    
    this.closemenu=this.closemenu.bind(this)
    this.toggleMenu=this.toggleMenu.bind(this)
  }


openmenu(){
this.setState({openmenu:true,})
}

toggleMenu(){
      
  if(this.state.openmenu){
  
    this.setState({openmenu:false});
    
  }
  else{
    this.setState({openmenu:true});
    this.refs.child.setInitialState(this.props.subHead);
   
  }
}

closemenu(openmenu){
  if(openmenu){
    this.setState({openmenu:false,})
  }
}

openGuide(){
  sessionStorage.setItem('guideFilter','legal')
  window.location.replace('/legal-finance/guides')
}

 
  render(){
    return(
      <div>
      <div className='main-navbar'>
        <nav className=" navbar navbar-default modification" style={{marginBottom:'0px', paddingTop:'7px'}}>
          <div className='col-md-6'>
          <div>
            <ul className="nav navbar-nav navbar-contained"> 
            <li style={{float:'left',cursor:'pointer',padding:'10px'}} 
            onClick={this.toggleMenu}> 
            <i class="fa fa-bars" aria-hidden="true"></i> 
            </li>
            
            <SideBar ref='child' stateOpen={this.state.openmenu} closeMenu={this.closemenu} type={this.props.type} url={this.props.url} />
          
            
            <li > 
            <div className='company-logo'>
              <Link to ="/"> <img  className="businus-logo"src={require('../Training/images/businusSchool.png')}/> </Link>
            
              <div>{""}</div>
              <h5 className="subhead">{this.props.subHead}</h5>
              </div>
            </li>
            {this.props.showArticleBtn?
              <li className='sub-cat hidden-xs hidden-sm' style={{cursor:'pointer'}}>
              <Link to ="/legal-finance" className='highlight2'>Articles</Link>       
            </li>            
            :''}

              
              {/* <li className='sub-cat dropdown' style={{cursor:'pointer'}}>
                <a className="dropdown-toggle highlight2"  data-toggle="dropdown" href='#'>Company Law<span className="caret"></span></a>
        
                <ul className=" drop dropdown-menu">
                    <li><img className='image-law' src={require('../../Images/case-laws-icon.png')}/><span><Link to='/company-law/case-law' className='company'>Case Laws</Link>
                   
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/acts-icon.png')}/><span><Link to='/company-law/acts' className='company'>Acts </Link>
                  
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/rules-icon.png')}/><span><Link to='/company-law/rules' className='company'>Rules </Link>
                    
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/circulars-icon.png')}/><span><Link to='/company-law/circular-notification' className='company'>Circular & Notification </Link>
                 
                    </span> 
                    </li>
                  </ul>
                  </li> */}


                  {/* <li className='sub-cat dropdown' style={{cursor:'pointer'}}>
                  <a className="dropdown-toggle highlight2"  data-toggle="dropdown" href='#'>GST<span className="caret"></span></a>
        
                <ul className=" drop dropdown-menu">
                    <li><img className='image-law' src={require('../../Images/case-laws-icon.png')}/><span><Link to='/GST/case-law' className='company'>Case Laws</Link>
                   
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/acts-icon.png')}/><span><Link to='/GST/acts' className='company'>Acts </Link>
                   
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/rules-icon.png')}/><span><Link to='/GST/rules' className='company'>Rules </Link>
                   
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/circulars-icon.png')}/><span><Link to='/GST/circular-notification' className='company'>Circular & Notification </Link>
                   
                    </span> 
                    </li>
                  </ul>
                  </li> */}


                  {/* <li className='sub-cat dropdown' style={{cursor:'pointer'}}>
                  <a className="dropdown-toggle highlight2"  data-toggle="dropdown" href='#'>Custom<span className="caret"></span></a>
        
                <ul className=" drop dropdown-menu">
                    <li><img className='image-law' src={require('../../Images/case-laws-icon.png')}/><span><Link to='/custom/case-law' className='company'>Case Laws</Link>
                    
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/acts-icon.png')}/><span><Link to='/custom/acts' className='company'>Acts </Link>
                   
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/rules-icon.png')}/><span><Link to='/custom/rules' className='company'>Rules </Link>
                   
                    </span> 
                    </li>
                    <li><img className='image-law' src={require('../../Images/circulars-icon.png')}/><span><Link to='/custom/circular-notification' className='company'>Circular & Notification </Link>
                   
                    </span> 
                    </li>
                  </ul>

              </li> */}

              {/* <li className='sub-cat' style={{cursor:'pointer'}}>
                <a onClick={()=>this.openGuide()}>Guides</a>
              </li>  */}
        
              </ul>
          </div>
        </div>

         </nav>

         </div>
    
          <Topbar showBlogCategory={true} />
        
        </div>
    );
  }
}
