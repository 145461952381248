import React, { Component } from "react";
import axios from "axios";
import "./style.css";
import { Link, withRouter } from "react-router-dom"; 
import { Auth } from 'aws-amplify';
import { apiBaseUrl1,apiBaseUrl2 } from '../apiUtils';
let context = null;
class Login extends Component {
  constructor(props) {
		super(props);

		this.state = {
      isLoading: false,
      isWaiting: false,
			email: '',
      password: '',
      forBusinus:false,
      newPassword:false,user:{}
    };
    
    context = this;
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
  };
  
  checkUser() {

    Auth.currentAuthenticatedUser()
  
      .then(user => context.saveUserInfo(user))
  
      .catch(err => console.log(err))
  
  }//To set the new values in the state
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value }
    );


  }
  signOut() {
  
    Auth.signOut()
  
      .then(data => console.log(data))
  
      .catch(err => console.log(err))
  
  }
  
  setNewPassword =  async event =>{
    var user = this.state.user;
    var newPasswordValue = this.state.newPasswordValue;
    var email = this.state.email;
    const loggedUser = await Auth.completeNewPassword(
      user,              // the Cognito User Object
      newPasswordValue,       // the new password
      // OPTIONAL, the required attributes
      {
        email
      }
  );

  context.setState({password:newPasswordValue});


  context.loginSubmit();
  //context.saveUserInfo(loggedUser);
    
  }
  getNewPassword = (user)=>{

    this.setState({newPassword:true});
    this.setState({user});




  }
  loginSubmit = async event => {
	//	event.preventDefault();

		this.setState({ isLoading: true });

		try {
      const user  = await Auth.signIn(this.state.email, this.state.password);
      debugger;
    /*   if (user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      const code = getCodeFromUserInput();
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      const loggedUser = await Auth.confirmSignIn(
          user,   // Return object from Auth.signIn()
          code,   // Confirmation code  
          mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
  }  else */if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      // You need to get the new password and required attributes from the UI inputs
      // and then trigger the following function with a button click
      // For example, the email and phone_number are required attributes
      //const {username, email, phone_number} = getInfoFromUserInput();
      context.getNewPassword(user);
     
  } /* else if (user.challengeName === 'MFA_SETUP') {
      // This happens when the MFA method is TOTP
      // The user needs to setup the TOTP before using it
      // More info please check the Enabling MFA part
      Auth.setupTOTP(user);
  }  */else {
     context.saveUserInfo(user);
  }
} catch (err) {
  if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      alert(err.message);
  } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      alert(err.message);
  } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
      alert(err.message);
  } else if (err.code === 'UserNotFoundException') {
    alert(err.message); 
    // The error happens when the supplied username/email does not exist in the Cognito user pool
  } else {
    alert(err.message);
  }
}
 };
  
  saveUserInfo = (user)=>{
    sessionStorage.setItem("userId", user.attributes.sub);
    sessionStorage.setItem("isLoggedIn", "YES");
    sessionStorage.setItem("userName", user.attributes.name.split(" ",1));
    sessionStorage.setItem("email", user.attributes.email);
    window.location.replace('/')
    // this.fetchClientById(user.attributes.locale);
  
  }

  
  fetchClientById(id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getClientById/" +
        id
      )
      .then(function (response) {
       

        context.setState({isLoading:false});
        if(response.data.mode=='offline'){

          sessionStorage.clear();
          alert("Invalid User Name & Password");
          return;
        }
        

       
        if(response.data.modules!=undefined){
          sessionStorage.setItem("privileges",
          JSON.stringify(response.data.modules));
      
        }
      else{
        sessionStorage.setItem("privileges",
        JSON.stringify([]));
      }    
      
      
    sessionStorage.orgName = "dashboard";
    sessionStorage.orgId = "0";
    sessionStorage.mode = "online"//JSON.stringify(response.data.mode);
    

      //.setItem("organisationLogo",response.data.assignedOrganisation.logo);
   
    if(window.location.href.includes('app=businus.com')){
debugger;

  
        window.location.replace("http://businus.services?redirect_uri="+response.data.id+"&token="+new Date().getTime());


      }
      else{
        window.location.replace("http://clientdashboard.in?redirect_uri="+response.data.id+"&token="+new Date().getTime());


      }
   
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  componentDidMount(){

    if(window.location.href.includes('app=dashboard')){


        this.setState({isBusinus:true});

    }

  }


  
  forgetPassword  = (sendOtp)=>{

    if(!sendOtp){
      this.setState({forgotPassword:true})
    }
    else{

      if(this.state.email!=""){
        Auth.forgotPassword(this.state.email)
        .then(data => this.setState({changePassword:true}))
        .catch(err => console.log(err));
      }
      else{
        alert("email cannot be blank");
      }
      
  
    }

  }


  createNewPassword(){

    let username = this.state.email;
    let code = this.state.otp;
    let newPassword = this.state.newPasswordValue;

    Auth.forgotPasswordSubmit(username, code, newPassword)
    .then(data => window.location.reload())
    .catch(err => console.log(err));
  }


  render() {
    return (
      <div className="wrapper fadeInDown">
        <div id="formContent">
         

        
         <div className="row text-center">
                <img id="1" class="login-logo"
                  style={{ marginLeft: "-3px",width:"220px",height:"58px",marginTop:"23px" }}
                  // src={require("../../images/businuslogo.png")}
                /><br/>
              
               </div>
         
          <h2 className="hi-text" style={{marginTop:'40px'}}>Hi User!</h2>
          {this.state.newPassword  ?  <form>
          
            <div className="group">
              <label htmlFor="pass" className="label" />
              <input
                id="pass"
                name="email"
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
                className="input fadeIn second text-box logintext"
                placeholder="Email "
                data-type="password"
              />
            </div>
            <div className="group">
              <label htmlFor="user" className="label" />
              <input
                id="newPassword"
                name="newPasswordValue"
                type="password"
                onChange={this.handleChange}
                value={this.state.newPasswordValue}
                className="input fadeIn second text-box logintext"
                placeholder="New Password"
              />
              <br/>
              <small>Password should be of minimum 8 characters</small>
            </div>
            
            <div className="group">
              <input
                type="button"
                className="signinbutton"
                onClick={this.setNewPassword}
                style={{marginTop:'15px'}}
                value="Change Password"
              />
            </div>
            <hr />
            <div className="row" style={{ padding: "30px" }}>
              <div className="col-md-6">
                {" "}
                <span className="button googlebtn" />{" "}
              </div>
              <div className="col-md-6" />
            </div>
          </form> :
           this.state.forgotPassword  ?  <form>
       
           <div className="group">
             <label htmlFor="email" className="label" />
             <input
               id="pass"
               name="email"
               type="text"
               onChange={this.handleChange}
               value={this.state.email}
               disabled={this.state.changePassword}
               className="input fadeIn second text-box logintext"
               placeholder="Email "
               data-type="emaill"
              
             /><br/>
            {/*    <small>Password should be of minimum 8 characters</small> */}
           </div>
           
           {this.state.changePassword  ?  
      <span>
      <div className="group">
             <label htmlFor="otp" className="label" />
             <input
               id="otp"
               name="otp"
               type="text"
               onChange={this.handleChange}
               value={this.state.otp}
               className="input fadeIn second text-box logintext"
               placeholder="OTP"
             />
           </div>
           <div className="group">
             <label htmlFor="user" className="label" />
             <input
               id="newPassword"
               name="newPasswordValue"
               type="password"
               onChange={this.handleChange}
               value={this.state.newPasswordValue}
               className="input fadeIn second text-box logintext"
               placeholder="New Password"
             />
           </div>
            </span>: ''}
          {this.state.changePassword ?  <div className="group">
             <input
               type="button"
               className="signinbutton"
               onClick={()=>this.createNewPassword()}
               style={{marginTop:'15px'}}
               value="Change Password"
             />
           </div> :
           <div className="group">
           <input
             type="button"
             className="signinbutton"
             onClick={()=>this.forgetPassword(true)}
             style={{marginTop:'15px'}}
             value="Generate OTP"
           />
         </div> }
           <hr />
           <div className="row" style={{ padding: "30px" }}>
             <div className="col-md-6">
               {" "}
               <span className="button googlebtn" />{" "}
             </div>
             <div className="col-md-6" />
           </div>
         </form> :
          
          <form>
          <div className="group">
            <label htmlFor="user" className="label" />
            <input
              id="user"
              name="email"
              type="text"
              onChange={this.handleChange}
              value={this.state.email}
              className="input fadeIn second text-box logintext"
              placeholder="Username"
            />
          </div>
          <div className="group">
            <label htmlFor="pass" className="label" />
            <input
              id="pass"
              name="password"
              type="password"
              onChange={this.handleChange}
              value={this.state.password}
              className="input fadeIn second text-box logintext"
              placeholder="Password"
              data-type="password"
            />
          </div>
          {this.state.required ? (
            <small className="error">All Fields are required</small>
          ) : (
            <p />
          )}
          {this.state.error ? (
            <small className="error">{this.state.errorMessage}</small>
          ) : (
            <p />
          )}
          <div className="group">
            <input
              type="button"
              className="signinbutton"
              onClick={this.loginSubmit}
              value="Sign In"
            />
          </div>
          <hr />
          <p onClick={()=>this.forgetPassword(false)}>Forgot Password ?</p>
          <Link to="/register">New User?</Link>
          <div className="row" style={{ padding: "30px" }}>
            <div className="col-md-6">
              {" "}
              <span className="button googlebtn" />{" "}
            </div>
            <div className="col-md-6" />
          </div>
        </form>}
        </div>
      </div>
    );
  }
}

export default Login;
