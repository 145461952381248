
import React, {useState} from "react";
// import './comment.css'


export default function AddQuestion(props){
    
    const [question, setQuestion] = useState('');
    const [description, setDescription] = useState('');
    const [tag, setTag]=useState('');
    // const [tags] = useState([])

    // const handleDelete =(chipToDelete) =>()=>{
    //     setChipData((chips)=> chips.filter((chip)=> chip.key!==chipToDelete.key))
    // }

    const {handleQuestion,addTag,handleDelete} = props;              

   return(
            <div>

                <div className='card mt-4 mb-3'>
                    <div className=' card-header'> 
                       
                    </div>
                    <div className='card-body'>

                <textarea name='question' className='form-control adjustComment' placeholder='Ask Your Question...' 
                onChange={event=> setQuestion(event.target.value)} value={question}></textarea>

                <textarea name='description' className='form-control adjustComment' placeholder='Enter Decription (optional)...' 
                onChange={event=> setDescription(event.target.value)} value={description}></textarea>


                <div className="tagBlock adjustComment">
                    {props.tags!==undefined?
                        props.tags.map((tag)=>{
                            return( 
                                <div className="chip">
                                    {tag.tag}
                                    <span className="closeChip" onClick={event=>handleDelete(tag.key,)}>&times;</span>
                                </div>
                            )
                })
                :''
                }      
                    
               
                

                <textarea name='tag' className="form-control" placeholder="Enter Tags" 
                onChange={event=> setTag(event.target.value)} value={tag}></textarea>

                <button className="btn btn-primary mr-3" onClick={event=>{
                    addTag(tag,props.tags)
                    setTag('')
                    }}> Add Tags</button>
                </div>
                </div>
                </div>
                <div className='commentButton'>
                <button style={{marginRight:"1%", marginLeft:'-17px'}} className='btn btn-primary mr-3'
                onClick={event=> {
                    handleQuestion(question,description,props.tags)
                    setDescription('')
                    setQuestion('');
                }}> Post Question</button>

                <button className='btn btn-warn mr-3'
                onClick={event=> {
                    setDescription('');
                }}> Cancel</button></div>
            </div>
        )
    }
