import React, { Component } from 'react';
import './topbar.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

export default class Topbar extends Component {

  constructor(props){

  super();
  this.state = {
    openMenu : false,
    sessionId:"",
    name:"",
    profile:"",
    isLoggedIn:false,
    url:'',
    loading:false
    };

        this.toggleMenu = this.toggleMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
       
        this.searchfunction();

        $(document).keydown(function (evt) {
         if (evt.keycode=='40'){
          $('.hide-me').slideUp();
          $('.breadcrumb').slideUp();
          $('.show-me').css({top:"0px"});

         }
        });

$(window).scroll(function() {
  

  if ($(this).scrollTop()>0)
   {
      $('.hide-me').slideUp();
      $('.breadcrumb').slideUp();
      $('.show-me').css({top:"0px"});
   }
  else
   {
    $('.hide-me').slideDown();
    $('.breadcrumb').slideDown();
    $('.show-me').css({top:"41px"});
   }
   
});
}

componentDidMount(){
  if(sessionStorage.getItem("isLoggedIn")==="YES"){
    var name = sessionStorage.getItem("userName")
    name = name.trim()
    for(var i=0;i<name.length; i++){
      if(name[i]==='@'){
        name=name.slice(0,i)
        break
      }
    }

    this.setState({isLoggedIn:true});
    this.setState({name:name});
    this.setState({email:sessionStorage.getItem("email")});
    this.setState({profile:sessionStorage.getItem("profile")});
    var clientUrl = "https://alphrade-client-panel.firebaseapp.com/?token="+
                      sessionStorage.getItem("password")+"&email="+sessionStorage.getItem("email");
  
                      this.setState({clientUrl:clientUrl});
  
  }
}

logout(){
    
  sessionStorage.clear();
  Auth.signOut()
  .then(data => alert("You have been logged out"))
  .catch(err => alert(err));

}


  toggleMenu(){

    if(this.state.openMenu){
    
      this.setState({openMenu:false});
      
    }
    else{
      this.setState({openMenu:true});
     
    }
  }

  closeMenu(state){

    this.setState({openMenu:state});
    this.toggleMenu();

  }
   
  

  searchfunction(){
    $(document).ready(function(e){
      $('.search-panel .dropdown-menu').find('a').click(function(e) {
      e.preventDefault();
      var param = $(this).attr("href").replace("#","");
      var concept = $(this).text();
      $('.search-panel span#search_concept').text(concept);
      $('.input-group #search_param').val(param);
    });
  });
  }
  
  render(){
    return(<div>
      
      <div className="big" >
        {/* <script src="//code.jquery.com/jquery-1.11.1.min.js"></script> */}


<div className="here row fixed-top1 ">

<div className="topbar " style={{marginRight:'0px'}}>
    
    

    <ul className="Navbar1 col-md-12 col-lg-12 col-sm-12 col-xs-12" style={{display:'inline-flex',width:'100%',listStyle:'none'}}>   
      {this.props.showBlogCategory?  <li className="major-icon list-inline hidden-xs"><Link to='/legal-finance' >Legal & Finance</Link></li>:""}
        {this.props.showBlogCategory?<li className="major-icon list-inline hidden-xs"><Link to='/exim' >EXIM</Link></li>:""}
        {this.props.showBlogCategory?<li className="major-icon list-inline hidden-xs"><Link to='/stories-insights' >Stories & Insights</Link></li>:""}
        {this.props.showBlogCategory?<li className="major-icon list-inline hidden-xs"><Link to='/opportunity-growth' >Opportunity & Growth</Link></li>:'' }    
      
        {!this.props.showBlogCategory?<li className='col-md-9 col-lg-9 col-sm-9 col-xs-7'></li>:<li className='col-md-5 col-lg-5 col-sm-5 col-xs-7'></li>}

        {/*<li className="major-icon list-inline"> <Link to='/strategic-training' >Training</Link> </li>    */} 
         {/* <li className="nav-item login-nav-item list-inline"> */}
           
        
        {/* <a href="http://accounts.businus.com">  </a> */}
        {/* <a><h6 className=" text-center heading90"><i className=" user-icon fa fa-phone" aria-hidden="true"></i>+91 8287012248</h6></a> */}        
        {/* </li> */}
       
        
        {/* <li> */}
        {this.state.isLoggedIn===false?
        <li className=" col-md-2 col-lg-2 col-sm-2 col-xs-2 text-right list-inline loginButton" > 
         <a target="_blank" href="http://accounts.businus.com?app=businus.school">Login/Register</a> 
        {/* <a id='loginRegister' href="/login">Login/Register</a>  */}
        </li>
        :
        <li className=" col-md-2 col-lg-2 col-sm-2 col-xs-2 text-right list-inline loginOpt" > 
        <div> 
         <a  style={{marginTop:'-70px'}} className="nav-link " href="#" id="product-toggle" role="button" data-toggle="dropdown" >
         <h6 className=" text-center heading30">
        <i className=" user-icon fa fa-user" aria-hidden="true"></i>

      {/* {this.state.profile!="" ? <img src={this.state.profile} /> : ''} */}<span style={{color:"#777",fontSize:'14px'}}>
      {this.state.name!="" ? 'Hi '+ this.state.name : 'Login/Register' } </span></h6></a>
       <div className="dropdown-menu dropdown-menu-center dropdown-content" arealabelledby="product-toggle">
             <ul>
          <li className="dropdown-item1 navmain-item" >  <a className=" navmain-item" href= "/account" >Account</a> </li> 
          <li className="dropdown-item1 navmain-item">  <a  className=" navmain-item" onClick={this.logout} href= "/" >Logout</a></li> 
             </ul>
             </div></div></li>
        }   

        <li className="nav-item login-nav-item list-inline">
        
       {/* <BusinusLogin/> */}
       </li>

       
	</ul>
</div>
</div>

      </div>

</div>

    );
  }
}
