import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import NavbarMain from '../../Navbar/navbarmain';
import Footer from '../footer';
import './sitemap.css';
import {sitemap} from './sitemaplinks';


export default class Sitemap extends Component{


constructor(props){

super();
this.state = {



}
console.log(sitemap);


}






  render(){
   return(
    <div className="mainbody sitemap">
      <NavbarMain />
      <div className="container">
      <div className="row">
      <div className="col-md-12" style={{paddingTop:'65px',paddingLeft:'2%'}}>
<h3>Businus School <span><small>Sitemap</small></span></h3>
</div>
</div>

<div className="container-fluid" style={{paading:'0 2%'}}>
    <div className="row">
    {sitemap.map((pageType, index) =>
     <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
     <div className="row">
          <h4 className="site-heading">{pageType.title}</h4>
          </div>
     <div className="row">

         <ul className="list-sitemap">
         {pageType.childs.map((child)=>

                <li><Link to={child.link}>  {child.title} </Link></li>

)}




         </ul>
     </div>
        </div>
    
    )}
    
       
      
    </div>
</div>



</div>
<Footer/>
  </div>





    );
  }
}
