import React from "react";
import SkeletonElement from "../SkeletonElement";

const ThirdColumnSkeleton = ()=>{
    return(
        <div>
        <div className="skeleton-wrapper">
            <div className="skeleton-article">
                <SkeletonElement type="title"/>
                {[1,2,3].map((n)=> 
                <div className="skeleton-para">
                    <SkeletonElement type="sk-text" />
                    <SkeletonElement type="sk-text" />
                    <SkeletonElement type="sk-text" />
                    <SkeletonElement type="sk-text" />
                    <SkeletonElement type="sk-text" />
                </div>
                )}
            </div>
        </div>

        </div>
    )
}

export default ThirdColumnSkeleton
