import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../style/resource.css";
import "../Comments/comment.css"
import EximNavbarMain from "./EximNavbar";
import Truncate from 'react-truncate';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import _ from 'underscore';
import GroupComment from '../Comments/GroupComment'
import GroupQuestion from "../QnA/GroupQnA";
import ReadOnly from "../Editor/ReadOnly";
import SkeletonBlog from "../Skeleton/SkeletonBlog";
import SkeletonOtherBlog from "../Skeleton/SkeletonOtherBlog";
import ReactTooltip from 'react-tooltip';
import Footer from "../Common/footer";
import { apiBaseUrl } from "../apiUtils";

export default class EximDedicatedPage extends Component {
constructor(props) {
  super();
  this.state={
  opencont:true,
  openshare1:false,
  openshare2:false,
  copied:false,
  shareurl:'',
  openmore1:false,
  openmore2:false,
  external:[],
  internal:[],
  identity:'',
  guides:[],
  tabdata:[],
  styletab:17,
  tabids:[],
  tabcontent:12,
  allid:[],
  heading:'',
  subHeading:'',
  content:'',
  bookmark:false,
  allbookmark:[],
  by:'',
  email:'',
  categoryArray:[],
  category:"",
  showContentSummary1:false,
  showContentSummary2:false,
  };
  this.getBlogsAndGuides=this.getBlogsAndGuides.bind(this);
}
componentWillMount(){
  let id = window.location.href.split('/').pop();
  
window.onbeforeunload = function () {
  window.scrollTo(0, 0);
}

  let urlArray = window.location.href.split('?').pop()


  this.userDetail()
  this.getbookmarks();
  this.fetchAllCategory()
  // this.getBlogsAndGuides(id)
  this.getBlogsAndGuides() 
}

userDetail(){
  if(sessionStorage.getItem("isLoggedIn")==="YES"){
    var name = sessionStorage.getItem("userName")
    name = name.trim()
    for(var i=0;i<name.length; i++){
      if(name[i]==='@'){
        name=name.slice(0,i)
        break
      }
    }

    this.setState({email:sessionStorage.getItem("email"),isLoggedIn:true,by:name});  
  }
}

async fetchAllCategory(){
  await axios({       
      url: (apiBaseUrl+'blogCategory/list'),
      method: 'GET',
      crossDomain: true,
      responseType: 'json', 
    }).then((response) => {   
      this.setState({categoryArray:response.data})  
   });  
}


getBlogsAndGuides() {

  var api = (apiBaseUrl+'blog/list');
  var blogResData=[]
      axios.get(api)
      .then(function (response) {
        for(var i=0;i< response.data.length;i++){
          if(response.data[i].learningCategory==='exim'){
            blogResData.push(response.data[i])
          }
        }
        
        let id = this.fetchTheBlog(blogResData)

        this.setState({guides:blogResData.slice(0,5),data:blogResData});
        
        this.gettab(id)                        // calling the function so that article will open in new tab 
       
       }.bind(this))
       .catch(function (error) {
         console.log(error);
       });
}

fetchTheBlog(blogData){
  // let urlParam=window.location.href.split('?').pop()
  let urlParam=window.location.href.split('?')[1]

  let urlBlgHeading=urlParam.split("=")[1]
  
  console.log('url blog name:',urlParam,urlBlgHeading,blogData)

  for(var i=0;i<blogData.length;i++){
    let urlHeading = blogData[i].heading.replaceAll(' ','_')
    urlHeading = urlHeading.trim('_')
    let urlBlogId = blogData[i].blogId.split('-').pop()
    let urlBlg=urlHeading+'_'+urlBlogId
    if(urlBlg===urlBlgHeading){
      return(blogData[i].id)
    }
  }
}

// this function is used to copy the link to clickboard and e is the id which is passed through function.
copy =(e)=>{
  
    var link=window.location.href
    var textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
   

  this.setState({copied:e})
}


download(heading) {
  
  var input= document.getElementById('divcontents');
  if(document.getElementById('video')!=undefined){
    var element = document.getElementById('video')
    element.parentNode.removeChild(element)
  }
  
  const modalContent = document.getElementById("modalContent")
  // var classElement = document.querySelectorAll("#embedded")
  // var classElement = document.getElementById("embedded")
  var classElement = [...document.getElementsByClassName("embed-tool")].map(n => n && n.remove());
  // classElement.parentNode.removeChild(classElement)
  
  // return classElement.parentNode.removeChild(classElement)
  // classElement.remove()
  var deletedModalContent = document.getElementById("modalContent")
  document.getElementById("modalContent").parentNode.replaceChild(modalContent,deletedModalContent)

  html2canvas(input).then(function(canvas){
    var imgWidth = 210
    var pageHeight = 208
    var imgheight = canvas.height*imgWidth/canvas.width
    var heightleft = imgheight
    var position = 0

    const imgData = canvas.toDataURL('image/png')
    var doc = new jsPDF('p','mm','a4')

    doc.addImage(imgData,'PNG',0,position,imgWidth,imgheight)
    heightleft -= pageHeight;

  

    while(heightleft>=0){
      position = heightleft-imgheight
      doc.addPage();
      doc.addImage(imgData,'PNG',20,position,imgWidth,imgheight);
      heightleft -= pageHeight
    }
    
     doc.save(heading+'.pdf')
  })

  // var pdfInMM=210
    // const pdf= new jsPDF();                                       //jsPDF is reactjs module  used to create a pdf 
    // pdf.fromHTML(input.innerHTML,15,15,{
    //   "width":170,
    //   "elementHandlers":
    // })
  
    //  var temp= input.textContent || input.innerText;                // storing the value in input in temp variabls
    // console.log('temp',temp)
    // var split=pdf.splitTextToSize(temp,260)             // splitting the words in content and storing in the form of array. Here all the words will treat as a single element
    // pdf.setFontSize(16);                                // setting fontsize for heading
    // pdf.text(70,10,this.state.heading)                  //showing heading on screen. Here (70 is the left margin and 10 is top margin
    // pdf.setFontSize(14)                                 // setting fontsize for sub-heading
    // pdf.text(70,30,this.state.subHeading)                //showing sub-heading on screen 
    // pdf.setFontSize(12)                                   // setting fontsize for content
    // var y=45;                                            // setting initial top margin value for first page and it will be greater because there is heading and subheading on the first page so that it doesn't overlap
    //for loop is used to exract the each element in the variable temp storing the actual content and display it one by one by dynamically changing the values of the variables 
    // for(var i=0;i<split.length;i++){                    //when y reach  at certain value we will add new page and we will start displaying content in that page, otherwise the content will be out of frame 
    //   if(y > 280){
    //     y=10;
    //     pdf.addPage();
    //   }
    //   pdf.text(8,y,split[i]);
    //   y=y+7;
    // }
    
  //   pdf.save('download.pdf');
   
  // console.log('cont value',this.state.file)
}

print=(id)=>{
  
  var content = document.getElementById("readOnly");                              // all content will save here
  var heading = document.getElementById("heading");
  var subHeading = document.getElementById("sub-heading")
  var pri = document.getElementById("ifmcontentstoprint").contentWindow;          // open up the print functionality on new page
  
  pri.document.open();
  pri.document.write(heading.innerHTML);
  pri.document.write(subHeading.innerHTML);
  pri.document.write(content.innerHTML);
  pri.document.close();
  pri.print();

}

share(i){
  if(i==='1'){
      this.setState({shareurl:window.location.href, openshare1:!this.state.openshare1,openmore1:false,showContentSummary1:false})
  }
  else{
      this.setState({shareurl:window.location.href, openshare2:!this.state.openshare2,openmore2:false,showContentSummary2:false})
  }
 }

more(i){
  if(i==='1'){
      this.setState({openmore1:!this.state.openmore1,openshare1:false,showContentSummary1:false})
  }
  else{
      this.setState({openmore2:!this.state.openmore2,openshare2:false,showContentSummary2:false})
  }
  
}

contentSummary(i){
  if(i===1){
    this.setState({showContentSummary1:!this.state.showContentSummary1,openmore1:false,openshare1:false})
  }
  else{
    this.setState({showContentSummary2:!this.state.showContentSummary2,openmore2:false,openshare2:false})
  }
}

getbookmarks(){
  return axios({
    url: (apiBaseUrl+'bookmark/list'),
    method: 'GET',
    crossDomain: true,
    responseType: 'json', // important
  }).then((response) => {
    let allbookmark=[]
    for(var i =0; i<response.data.length; i++){
      if(response.data[i].userId===sessionStorage.getItem("userId")){
        allbookmark.push(response.data[i])
      }
    }
    this.setState({allbookmark:allbookmark}) ;

 })
}

checkbookmark(id){
  var bookmark = this.state.allbookmark;
  var flag = false

  for (var i=0; i<bookmark.length; i++){
    if(bookmark[i].blogId===id){
      flag=true
      break
    }
  }
  if(flag){
    this.setState({bookmark:true})
  }
  else{
    this.setState({bookmark:false})
  }
}

bookmarks(tabDetail,id){
  var bookmark = this.state.allbookmark
  var flag = true
  if(sessionStorage.getItem("isLoggedIn")==="YES"){
    for(var i=0; i<bookmark.length; i++){
      if(bookmark[i].blogId===id){
        this.deletebookmark(bookmark[i].id)
        flag = false
        break
      }
    }
    if(flag){
      this.updatebookmark(tabDetail)
    }
  }
  else{
    alert("You are not logged in")
  }
}

updatebookmark(data){
  var data = data
  var bookmark={
    bookmarkType:'blog',
    userId:sessionStorage.getItem("userId"),
    blogId:data.id,
    title:data.heading,
    by:sessionStorage.getItem("userName"),
    email:sessionStorage.getItem("email"),
    learningCategory:'exim'
  }
  axios({
    url: (apiBaseUrl+'bookmark/add/'),
    method:'POST',
    data: bookmark,
  }).then((response)=>{
    if(response.status){
      this.setState({bookmark:true})
      this.getbookmarks()
    }
  })
}

deletebookmark(id){
  axios({
    url: (apiBaseUrl+'bookmark/get/' + id),
    method: 'Delete',
  }).then((response) => {
    if(response.status){
      this.setState({bookmark:false})
      this.getbookmarks()
    }
  });
}

gettab(id){
  let category=""
  var val=this.state.tabdata
 
  for(var i=0;i<this.state.data.length;i++){
      if(this.state.data[i].id===id){
        for(var j=0; j<this.state.categoryArray.length;j++){
          if(this.state.categoryArray[j].id===this.state.data[i].category){
            category=this.state.categoryArray[j].category
          }
         }

       var m=this.state.data[i].heading
       var n=this.state.data[i].subHeading

        //allid is used to store ids of all the content which are open as a form of tab.
        //if id is present in allid it simply active the clicked tab
        if(this.state.allid.includes(id)){
          this.isactivetab(id)     
        }

        // saving the data in json format so that we can map it and use it in further code. 
        // if id is not present in allid then it will add all the data corresponding to that id in the previuosly created json variable
        //and add the id in allid
      else{
        val.push({
          id:id,
          heading:this.state.data[i].heading,
          subHeading:this.state.data[i].subHeading,
          content:this.state.data[i].content,
          authorDescription:this.state.data[i].authorDescription,
          authorImageURL:this.state.data[i].authorImageURL,
          authorName:this.state.data[i].authorName,
          blogImageCredit:this.state.data[i].blogImageCredit,
          blogImageURL:this.state.data[i].blogImageURL,
          category:this.state.data[i].category,
          datePosted:this.state.data[i].datePosted,
          postedBy:this.state.data[i].postedBy,
          readTime:this.state.data[i].readTime,
          source:this.state.data[i].source,
          sourceUrl:this.state.data[i].sourceUrl,
          tags:this.state.data[i].tags,
          more: this.state.data[i].more,
          blogId: this.state.data[i].blogId,
          contentSummary:this.state.data[i].contentSummary
          })
          this.state.allid.push(id)
          if(this.state.data[i].more!= undefined){
            this.setState({external:this.state.data[i].more.external})   
          }
          
      }  
      
      }
  }

  
  var st=this.state.styletab       //set the default tab size value to st
  var tc=this.state.tabcontent     // set the default number of alphabet visible in tab to tc

  //if new tab is not open then the value of st and tc will remains same
if(this.state.tabids.includes(id)){
  st=this.state.styletab
  tc=this.state.tabcontent}
   //if the new tab is open then the value of st and tc will change 
    // the size and number of alphabet visible in tab will decrement by 1  
else{
  this.state.tabids.push(id)
  if(this.state.styletab>8){
    st=st-1
  }
  if(this.state.tabcontent>=5){
    tc=tc-1
  }
}



  //if new tab is not open then the value of st and tc will remains same


this.setState({openshare2:false,openmore2:false, identity:id,styletab:st,tabcontent:tc,heading:m,subHeading:n,copied:true,tabdata:val,category:category}) 
this.checkbookmark(id)

}

// to  remove the tab and tab content when user clicked on cross.
removetab =(i)=>{
 
  // here we storing all the data related to tabs into a temparory variable so that we can perform all the operation on them
  // after all the operation we will assign these varaibles value to their respective state.
  var activeid= this.state.allid[i]
  var newTabdata = this.state.tabdata;
  var newallid= this.state.allid;
  // deleting the tab data and id from allid and tab id 
  var del = newTabdata.splice(i,1);
  var del1= newallid.splice(i,1);
  var del2= this.state.tabids.splice(i,1)
  //  assigning the tab width to st and number of character to tc
  var st=this.state.styletab
  var tc=this.state.tabcontent
 
  //increasing the size of tab and number of character after cancelling the tab 
  if(this.state.styletab<14){
    st=st+1  
  }
  if(this.state.tabcontent<12){
    tc=tc+1
  }
  
  //here we decide which tab should open if we cancel the tab
  //if we cancel some other tab instead of which we are reading then the same tab will remains open  
  // if we cancel the reading tab and it is first tab in the queue then the next tab will open
  //if we cancel the reading tab and it is not the first  tab then the previous tab will open
  if(activeid==this.state.identity){
    // if(temp_i==i){
      if(i==0){
        var id=this.state.allid[i]
     }
    if(i!=0){
      var id=this.state.allid[i-1]
     }
    // }
  } 
   else{
     var id=this.state.identity
   }
  
  this.setState({tabdata:newTabdata,allid:newallid,styletab:st,tabcontent:tc,identity:id});
  // this.isactivetab(id)
  this.isactivetabcont(id)

}

// this function used for active class tab 
activetab(id){
  var ex_temp=[]
  var in_temp=[]
  var more= {}
  var category=""
  for(var i=0; i<this.state.tabdata.length;i++){
    if(this.state.tabdata[i].id==id){
      for(var j=0; j<this.state.categoryArray.length;j++){
        if(this.state.categoryArray[j].id===this.state.tabdata[i].category){
          category=this.state.categoryArray[j].category
        }
      }
      more = this.state.tabdata[i].more
     }
}

  if(more!=undefined){
    ex_temp=more.external
    in_temp=more.internal
    
     
  }
  this.setState({identity:id,internal:in_temp,external:ex_temp,category:category})
  this.checkbookmark(id);
}

//this function will set more and share condition as false so that when user opt for popup then they will also close 
closeall(){
  this.setState({openmore1:false,openshare1:false,opencont:true})
} 
//this function will set more and share condition as false so that when user close the popup then they will also close
closeall1(){
  this.setState({openmore2:false,openshare2:false})
}

isactivetab(id){
 
  return ((id==this.state.identity)?'activetab':'notactivetab')
}

//this the function used for active class for content
isactivetabcont(id){
  return  id+ ' tab-pane fade '+ (id==this.state.identity?' active in':'') 
}


render() {

return(

<div className="mainbody">
       <EximNavbarMain subHead='Exim' url='exim'/>
       
    <div className="khub_blog_whole">
    <div className="onlyproductsection">
    <div className="row khub-scr-mt">
    <div className="col-md-1"></div>

    <div className="row hidden-xs hidden-sm">
      <div className='col-md-7'>
                  <ul style={{marginLeft:'-3px'}} className="nav nav1 nav-pills set-resmargin" >
   
                  {this.state.tabdata.map((tab,index) =>(

                  <li className='te' key={index} style={{width:this.state.styletab+'%'}}  ><a data-toggle="tab" href={"#tab"+tab.id} className={this.isactivetab(tab.id)} style={{fontSize:'12px'}} onClick={()=>this.activetab(tab.id)}>
                  <a data-for='tab-heading' data-tip={tab.heading}>  {tab.heading.substring(0,this.state.tabcontent)}</a>  
                  </a><span className='closetab1' onClick={()=>this.removetab(index)}><i class="fas fa-times"></i></span>
                  <ReactTooltip place='top' id='tab-heading' />
                  </li> 
                 
                   ))}
                  </ul>
                    </div>
                    </div> 

<div className="col-md-8 col-lg-8">
{this.state.tabdata.length>0?
                    <div class="tab-content">
            {this.state.tabdata.map((tab,index) =>(

            <div id={"tab"+tab.id} className={this.isactivetabcont(tab.id)}>
            {this.state.opencont?

<div>
   
<div className="dedicatedstuff">
<div className="row">


<div className='resourcepage-line sticky'>
    <div>
          <div className="btn hidden-sm hidden-xs" style={{color:'black',float:'left',marginRight:'1%'}} data-toggle="modal" data-target={'.'+index} onClick={()=> this.closeall1()}>
           <span  data-for="resize" data-tip="Maximize" className="glyphicon glyphicon-resize-full"></span> </div>
         
            <ReactTooltip place="top" id="resize"/> 
          <div className="btn " style={{color:'black',marginRight:'1%'}} onClick={()=>this.download(tab.heading)}>
          {/* <span data-for="download" data-tip="Download" className="glyphicon glyphicon-download-alt"></span> 
               <ReactTooltip place="top" id="download"/>  */}
            </div>

            <div className='more hidden-sm hidden-xs' >                  
            <h5 data-for='more' data-tip='More' style={{paddingTop:'-3px',fontSize:'14px',float:'right',marginTop:'8px',cursor:'pointer'}} onClick={()=> this.more('2')}>  More 
            </h5>                  
          <ReactTooltip place='bottom' id='more' />
          </div>

          <div  className='more hidden-sm hidden-xs' style={{marginTop:'-3px'}}>                  
            <h5 data-for='summary' data-tip='Summary' style={{paddingTop:'-5px',fontSize:'14px'}} onClick={()=> this.contentSummary(2)}>  Summary </h5>                  
            <ReactTooltip place='bottom' id='summary' />
          </div>

            <div data-for="share" data-tip="Share"  className='share'>                    
                    <div onClick={()=>this.share('2')}>
                   
                  <i  data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="fas fa-share-alt"></i>                 
                  </div>
                  </div>    <ReactTooltip place="top" id="share"/> 

           
            {this.state.bookmark?
             <div  data-for="bookmark" data-tip="Bookmark"  className='bookmark1'  onClick={()=> this.bookmarks(tab,tab.id)}> <span><i class="fas fa-bookmark"></i></span></div>
             :<div  data-for="bookmark" data-tip="Bookmark" className='bookmark1' onClick={()=> this.bookmarks(tab,tab.id)}><span><i class="far fa-bookmark"></i></span>
            </div>
            }  <ReactTooltip place="top" id="bookmark"/> 
          {this.state.copied?
            <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(false)}><i class="far fa-copy"></i>
            </div> :
            <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(true)}><i class="fas fa-copy"></i>
              </div>}
              <ReactTooltip place="top" id="copy"/> 
            {/* <div data-for="print" data-tip="print"   title='Print' className='printresource'>                  
              <div  onClick={()=> this.print('content1')}> <i class="fas fa-print"></i> </div>                  
              </div>
              <ReactTooltip place="top" id="print"/>  */}
              </div>
            </div>
  </div>
  <div className="row">
 
  <div className="col-md-8">
  <div className="heads text-left">


<div id={"tab"+tab.id} className='resourcepage-line hidden-xs hidden-sm'>

  
{this.state.opencont?
<div>

<div className={"modal fade "+ index} tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" id={tab.id}>
<div className="modal-dialog modal-lg" style={{marginRight:'11%',width:'80%'}}>
<div className="modal-content">   
<div className="modal-header1">

  <div className='bottom-line2 sticky-top'>
              
               <div className="btn button-color" data-dismiss='modal' aria-label='close' onClick={()=> this.closeall()}>
                <span data-for="resize"
               data-tip="Minimize" className="glyphicon glyphicon-resize-small"></span> 
                <ReactTooltip place="bottom" id="resize"/> 
                 </div>
               {/* <div className="btn button-color" onClick={()=>this.download()}>
                <span data-for="download" data-tip="Download" className="glyphicon glyphicon-download-alt"></span> 
                <ReactTooltip place="bottom" id="download"/> 
                 </div> */}
                 <div  className='more'>                  
                   <h5 data-for='more' data-tip='More' style={{paddingTop:'-5px',fontSize:'14px'}} onClick={()=> this.more('1')}>  More </h5>                  
                   <ReactTooltip place='bottom' id='more' />
                 </div>
                 <div  className='more'>                  
                   <h5 data-for='summary' data-tip='Summary' style={{paddingTop:'-5px',fontSize:'14px'}} onClick={()=> this.contentSummary(1)}>  Summary </h5>                  
                   <ReactTooltip place='bottom' id='summary' />
                 </div>
                 <div className='share'>                    
                       <div onClick={()=>this.share('1')}>
                   <i data-for="main"
               data-tip="Share"
               data-iscapture="true" class="fas fa-share-alt"></i>      
               <ReactTooltip id="main"/>              
                   </div>
                   </div>
                 {!this.state.bookmark?
                 <div data-for="bookmark" data-tip="Bookmark" className='bookmark' onClick={()=> this.bookmarks(tab,tab.id,this.state.countbookmark)}> <span><i class="far fa-bookmark"></i></span>
                 </div>:
                  <div data-for="bookmark" data-tip="Bookmark" className='bookmark'  onClick={()=> this.bookmarks(tab,tab.id,this.state.countbookmark)}> <span><i class="fas fa-bookmark"></i></span></div>
                 }
                   <ReactTooltip place="bottom" id="bookmark"/> 
            {this.state.copied?
            <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(false)}><i class="far fa-copy"></i>
            </div> :
            <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(true)}><i class="fas fa-copy"></i>
            </div>}
          <ReactTooltip place="top" id="copy"/> 
               
</div>


  
  <div className="row">  
  <h5 className="modal-title" id="exampleModalLongTitle">
 
  {this.state.openshare1?
            <div >
            <div className='shareall3'>
          <a href={"https://www.facebook.com/sharer.php?u="+this.state.shareurl} target="blank">
           <div className='share-option'>  
           <i className="fab fa-facebook-square"></i></div></a>

           <a href={"mailto: ?body= "+this.state.shareurl} target="_top">
           <div className='share-option'>
           <i className="fas fa-envelope"></i>
           </div></a>

           <a href={"https://api.whatsapp.com/send?text="+this.state.shareurl} target="_blank"> 
           <div className='share-option'>    
           <i class="fab fa-whatsapp"></i></div></a>

           <a href={"https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url="+this.state.shareurl} target="_blank"> 
           <div className='share-option'>    
           <i class="fab fa-twitter"></i></div></a>

           <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+this.state.shareurl} target="_blank"> 
           <div className='share-option'>    
           <i class="fab fa-linkedin"></i></div></a>
           </div>
             </div> :'' 
            }

{this.state.openmore1?
this.state.external!=[] && this.state.internal!=[]?
<div >
<div className='arrow-up3'></div>
<div className='moreopt2'>
  {this.state.external!=''?
  <table className='table'>
  <thead>
  <tr>
   <th scope='col'>Source</th>
   <th scope='col'>Name</th>
   <th scope='col'>URL</th>
  </tr>
  </thead>
  {this.state.external.map((show_more,index)=>(
  
  <tbody>
  <tr>
  <td>
    <div style={{color:'#353333'}}>
    <small>{show_more.src}</small></div>
  </td>
  <td>
    <small> <a style={{color:'#6b6868'}}>{show_more.name}</a> </small>
  </td>
  <td>
    <small> <a href={show_more.url} target="_blank" style={{color:'blue'}}>{show_more.url}</a> </small>
  </td>
  </tr>
  </tbody>
  
  
  ))} </table>:
  <div> No Information Found
    </div>
  }

</div>



</div>:''
:''}

{this.state.showContentSummary1?
<div>
  <div className="arrow-up3"/>
  <div className="moreopt2">
    {tab.contentSummary!==undefined && tab.contentSummary.trim()!==''?
      <article> {tab.contentSummary} </article>:<div> No Information Found </div>
    }
  </div>

</div>:''
}


< div className="col-md-7 col-sm-7 col-xs-12">
<div ><button type='button' className='categorypage1'>{this.state.category}</button></div><br/>
{tab.authorName!==''?
<div className="contentfullpage1  text-left">By {tab.authorName} </div>:''
}
{tab.datePosted!==''?
<div className="contenttfullpage  text-left"> {tab.datePosted} </div>:''
}
{tab.readTime!==''?
<div className="contenttfullpage  text-left"> {tab.readTime}mins read</div>:''
}<br/>
</div>
<div className="col-md-5 col-sm-5 col-xs-12"></div>
{tab.source!==''?
<div className="col-md-2">
<div className="contentttfullpage text-right"> <a href={tab.sourceUrl} target='_blank'> Source: {tab.source}</a></div>
</div>:''
} 
<br/>

  </h5>
  <div className='col-md-12'></div>
  <center className='heading-case1' id="heading">{tab.heading}</center>
  <center className='subheading-case' id="sub-heading">{tab.subHeading}</center>
  <div className="col-md-8">

  </div>
  <div className="col-md-2"></div>
  </div>
  <div style={{margin:'2%'}} id="modalContent">
  <ReadOnly data={tab.content}/></div>
  {/* <div className="descriptionstuff" id='cont' dangerouslySetInnerHTML ={{__html: tab.content}}>
 </div> */}
 <div className='row'>
   <div className='col-md-12'>
  <div className='tagcont'>
 <div className="col-md-2"><h5 className="contenttag">Tags:</h5></div>
<div className="col-md-8">
  <div className='tagname'>
{tab.tags!='' ? tab.tags.map((tag, index) => tag.trim()!==''?<button type="button" className="button260 text-center"><h4 className="heading24">{tag}</h4></button>:""):''}
</div></div>
<div className="col-md-2"></div>
</div>
</div>
</div>

 <div >
   

<div className="imagecredit">
<div className="row authorrow">
{tab.authorImageURL!=undefined?
  <div className="col-md-2 col-sm-5 col-xs-5">
<img src={tab.authorImageURL} className="author-image"/>
</div>:''
}

<div className="col-md-8 col-5">
<div className="author-head">About the Author: </div> 
<div className='des'>{tab.authorName}</div>
<div className='name'>{tab.authorDescription}</div>
</div>


</div>
</div>      
</div>
</div>
</div>
</div>
</div>
  
  </div>
  :''}</div>

  </div>
  </div>
  <div className="col-md-2"></div>
  </div>
  <div className="row">
  
  {this.state.openshare2?
            <div >
            <div className='shareall2'>
          <a href={"https://www.facebook.com/sharer.php?u="+this.state.shareurl} target="blank">
           <div className='share-option'>  
           <i className="fab fa-facebook-square"></i></div></a>

           <a href={"mailto: ?body= "+this.state.shareurl} target="_top">
           <div className='share-option'>
           <i className="fas fa-envelope"></i>
           </div></a>

           <a href={"https://api.whatsapp.com/send?text="+this.state.shareurl} target="_blank"> 
           <div className='share-option'>    
           <i class="fab fa-whatsapp"></i></div></a>

           <a href={"https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url="+this.state.shareurl} target="_blank"> 
           <div className='share-option'>    
           <i class="fab fa-twitter"></i></div></a>

           <a href={"https://www.linkedin.com/shareArticle?mini=true&url="+this.state.shareurl} target="_blank"> 
           <div className='share-option'>    
           <i class="fab fa-linkedin"></i></div></a>
           </div>
             </div> :'' 
            }

{this.state.openmore2?
<div>
<div className='arrow-up4'></div>
<div className='moreopt3' >
{this.state.external!=''?
<table className='table'>
<thead>
<tr>
 <th scope='col'>Source</th>
 <th scope='col'>Name</th>
 <th scope='col'>URL</th>
</tr>
</thead>
{this.state.external.map((show_more,index)=>(



<tbody>
<tr>
<td>
  <div style={{color:'#353333'}}>
  <small>{show_more.src}</small></div>
</td>
<td>
  <small> <a style={{color:'#6b6868'}}>{show_more.name}</a> </small>
</td>
<td>
  <small> <a href={show_more.url} target="_blank" style={{color:'blue',cursor:'pointer'}}>{show_more.url}</a> </small>
</td>
</tr>
</tbody>


))} </table>:
<div>
  No Information Found
</div>
}

</div>



</div>
:''}

{this.state.showContentSummary2?
<div className="khub-content-summary">
  <div className="arrow-up3"/>
  <div className="moreopt2">
    {tab.contentSummary!==undefined && tab.contentSummary.trim()!==''?
      <article> {tab.contentSummary} </article>:<div> No Information Found </div>
    }
  </div>

</div>:''
}

  <div id='divcontents'>
    <div className='row'>
  < div className="col-md-7 col-lg-7 col-sm-7 col-xs-8" >
<div><button type='button' className='categorypage'>{this.state.category} </button></div><br/>
{/* <div id='content1'> */}
{tab.authorName!==''?
<div className="contentpage1  text-left">By {tab.authorName} </div>:''
}
{tab.datePosted!==''?
<div className="contenttpage  text-left"> {tab.datePosted} * {tab.readTime}mins read</div>:""}
</div>
<div className="col-md-5 col-lg-5 col-sm-5 col-xs-4">
{tab.source!==''?
<div className="contentttpage text-right"> <a href={tab.sourceUrl} target='_blank'> Source: {tab.source}</a></div>:''}
</div>
<div className='col-md-2'></div></div>
  <center className="head1 " >{tab.heading}</center>
  <center className="subheading ">{tab.subHeading}</center>


  <div className="col-md-2"></div>
 <div id='readOnly' style={{margin:'none'}}>
  <ReadOnly data={tab.content}/></div>
 
 </div>
 </div>
 <iframe id="ifmcontentstoprint" style={{height:"0px", width:"0px", position:"absolute"}}></iframe>

<div className="row">

<div className="col-md-8">
{tab.tags!='' ? tab.tags.map((tag, index) => tag.trim()!==''? <button type="button" className="button260 text-center" style={{marginTop:'20px'}}><h4 className="heading24">{tag}</h4></button>:""):''}
</div>
<div className="col-md-2"></div>
</div>

</div>

{tab.contentSummary!==undefined && tab.contentSummary.trim()!==''?
<div className="summary-container">
<h2 className="author-head">
    Summary:
  </h2>
  <div style={{padding:"0 10px"}} className="ce-paragraph">
    {tab.contentSummary}
  </div>
</div>:""}

{tab.more.external.length>0?
        <div className="khub_blog_ref">
          <div className="row">
          <h4 className="author-head"> More Resources </h4>
          {tab.more.external.map((ext)=>(
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-res-container">
              <div style={{fontSize:'1.3rem'}} className="col-lg-4 col-md-4 col-sm-4 col-xs-4"> {ext.src} </div>
              <div style={{fontSize:'1.3rem'}} className="col-lg-4 col-md-4 col-sm-4 col-xs-4 hidden-sm hidden-xs"> {ext.name} </div>
              <div className="col-lg-4 col-md-4 col-sm-8 col-xs-8 mr-res-url"> <a href={ext.url} target='_blank' style={{color:'blue',cursor:'pointer'}}> </a> {ext.url} </div>
            </div>
          ))}</div>
        </div>:""        
}

<div className="imagecredit">
<div className="row authorrow">
<div className="author-head">About the Author</div>

{tab.authorImageURL!=undefined?
  <div className="col-md-2 col-sm-5 col-xs-5">
<img src={tab.authorImageURL} className="author-image"/>
</div>:''
}

<div className="col-md-8 col-sm-5 col-xs-5">
{/* <div className="author">About Author: </div> */}
<div className='des'>{tab.authorName}</div>
<div className='name'>{tab.authorDescription}</div>
</div>


</div>
</div>


              
      </div>      :''}
    
    
    </div>

      ))}</div>:
      <div> <SkeletonBlog/> </div>
      }
      
      <div className="row ">
        <div className='col-md-1'></div>
        <div className='col-md-11 col-sm-12 col-xs-12'>
          {this.state.guides.length!==0?

        <div className="comments-section">
        <ul className='nav nav-tabs'>
          <li><a data-toggle='tab' href="#comment">Comment</a></li>
          {/* <li className="hidden-xs hidden-sm" style={{}}><a data-toggle='tab' href="#QnA">QnA</a></li> */}

        </ul>
        <div className=''>
              <div id='comment' className='tab-pane fade in active'>
                <GroupComment blogTabId={this.state.identity}/>  
              </div>
              {/* <div id="QnA" className='tab-pane fade'>
                <GroupQuestion blogTabId={this.state.identity}/>
              </div> */}
        </div>
        </div>:""}
        </div>

        </div>

      </div>
      
    
    <div className="col-md-3 text-center" >
      {this.state.guides.length!==0?
      
      <div>
    <div className="sidebar" >
    <div>
     <div>
<div className="headstrip head2">Other {this.props.type}</div>
  <div className="">

<div className="mainbody">
       
    <div className="whole">
    <div className="onlyproductsection1">




<div className="guidestuff" id="e2">

<div className="row">
<div className="col-md-1"></div>
<div className="col-md-8 col-sm-12 col-xs-12">

{this.state.guides.map((guide, index) => ( 
  
  <div className="cardstuffside">
    <div className="row">
    <div className="col-md-3 col-xs-4 col-sm-4">
      {guide.blogImageURL!=undefined?
        
            <div className="">
      <img className="image4" src={guide.blogImageURL}/>
    
  </div>:''
      }</div>
  
<div className="col-md-9 col-xs-7 col-sm-8">

{guide.blogType!=="curated"?
      <div className="cardcontent">
        {/* Below code will be used only for larger screen */}
        <h5 className="headstuff hidden-sm hidden-xs"><div id={guide.id} onClick={()=>this.gettab(guide.id)}>{guide.heading}</div></h5>
          <div className="content hidden-sm hidden-xs">
           <Truncate lines={2} ellipsis ={<span>...<div id={guide.id} onClick={()=>this.gettab(guide.id)} style={{color:'#1d1dbc',cursor:'pointer'}}>Read More</div></span>}>
            <p dangerouslySetInnerHTML={{__html:guide.truncateContent}}></p>
                </Truncate> 
          </div>
          <h5 className="headstuff visible-sm visible-xs"><a href={'/exim/resources/'+guide.id} target='_blank'>{guide.heading}</a></h5>
          <div className="content visible-sm visible-xs">
           <Truncate lines={2} ellipsis ={<span>...<div id={guide.id} href={'/exim/resources/'+guide.id} target='_blank' style={{color:'#1d1dbc',cursor:'pointer'}}>Read More</div></span>}>
            <p dangerouslySetInnerHTML={{__html:guide.truncateContent}}></p>
                </Truncate> 
          </div>
      </div>:
      <div className="cardcontent">
        <h5 className="headstuff"><a href={guide.sourceUrl} target="_blank">{guide.heading}</a></h5>
          <div className="content">
           <Truncate lines={2} ellipsis ={<span>...<div id={guide.id} href={guide.sourceUrl} target="_blank" style={{color:'#1d1dbc',cursor:'pointer'}}>Read More</div></span>}>
            <p dangerouslySetInnerHTML={{__html:guide.truncateContent}}></p>
           </Truncate> 
            </div>
      </div>
      }
      </div>

     
      </div>
      </div>
             
))}  
            
</div>
</div>
</div>

</div>

</div>

 </div>

     </div>
          </div> 


    </div>
    
    </div>
    <button type="button" className="button25  view text-center">
       <Link to="/exim">  <h6 className=" text-center heading99">View all</h6></Link></button>
      </div>:
       <div><SkeletonOtherBlog/></div>}
    </div>
    
    
    </div>

</div>

{/* <div className="row ">
<div className='col-md-1'></div>
<div className='col-md-7'>
  {this.state.guides.length!==0?

<div className="comments-section">
<ul className='nav nav-tabs'>
  <li><a data-toggle='tab' href="#comment">Comment</a></li>
  <li style={{}}><a data-toggle='tab' href="#QnA">QnA</a></li>

</ul>
<div className='tab-content'>
      <div id='comment' className='tab-pane fade in active'>
        <GroupComment blogTabId={this.state.identity}/>  
      </div>
      <div id="QnA" className='tab-pane fade'>
        <GroupQuestion blogTabId={this.state.identity}/>
      </div>
</div>
</div>:""}
</div>
<div className='col-md-4'></div>

</div> */}

</div>
 <Footer />

 </div>

     
      
    );
  }
}