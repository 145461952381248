import React, {Component} from 'react'
import './QnA.css'

export default class AnswerReply extends Component{
    constructor(props){
        super(props)
        this.state={
            answerReplyArray:this.props.answer.slice(0,3),
            lastIndex:3,
            isMoreAnswer:this.props.answer.length>=4?true:false,
            isLessAnswer:false,
        }
        
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.answer===this.props.answer){
            this.setState({answerReplyArray:nextProps.answer.slice(0,3)})
        }
        else{
            this.setState({answerReplyArray:nextProps.answer.slice(0,3),isLessAnswer:false,isMoreAnswer:nextProps.answer.length>=4?true:false})
        }
    }

    moreAnswerComment(){
        let answerReplyArray =  this.props.answer.slice(0,this.state.lastIndex+5)
        let isMoreAnswer = this.props.answer.length<=answerReplyArray.length?false:true
        this.setState({answerReplyArray:answerReplyArray,lastIndex:this.state.lastIndex+5,isLessAnswer:true,isMoreAnswer:isMoreAnswer})
    }

    lessAnswerComment(){
        this.setState({answerReplyArray:this.props.answer.slice(0,3),lastIndex:3,isLessAnswer:false,isMoreAnswer:true})
    }

    render(){
        return(
            <div>

                                {/* <div style={{minHeight:'16vh',height:'auto',paddingLeft:'3%'}}>
                                    {answer.answer}

                                    <div className='user-info-answer'> 
                                    <div style={{fontSize:'12px',color:'grey'}}> answered {answer.time} </div> 
                                    <img style={{width:'22%',paddingRight:'3%'}} src={require('../../Images/alphabet-images/A_logo.png')} />
                                    <span style={{fontSize:'15px',color:'#3c3cd4'}}>
                                        {answer.name}
                                    </span>
                                </div> 

                                </div>   */}


                                {/* {this.state.answerReplyArray!=[]? */}
                                {this.state.answerReplyArray.map((answerReply,index)=>(
                                    <div className='questionReply'>
                                        <span style={{marginLeft:'2%'}}>
                                            {answerReply.comment}</span>  -&nbsp;
                                            <a className='user-info-question-reply' style={{color:'blue'}}> {answerReply.name +' '} </a> 
                                       <span style={{fontSize:'12px',color:'grey'}}> {answerReply.time} </span> 
                                    
                                    </div>
                                ))
                                // :''
                                }

                                <div className='seeAnswerReply col-md-12 col-lg-12'>

                                

                                {this.state.isMoreAnswer?
                                    <span onClick={()=>this.moreAnswerComment()}>
                                        Show more comments
                                    </span>:''
                                }

                                {this.state.isLessAnswer?
                                    <span onClick={()=>this.lessAnswerComment()}>
                                        hide comments
                                    </span>:""

                                }
                                </div>

                                {/* {!this.props.isAnswerReply?
                                    <div className='addComment'>
                                        <div style={{paddingBottom:"1%"}} onClick={()=> this.props.handleAnswerReply()}>add a comment </div>
                                    </div>:""
                                }

                                {this.props.isAnswerReply?
                                    <div style={{paddingLeft:'2%'}}>
                                        <textarea className='form-control' style={{width:'88%'}} placeholder='add a comment...' name='answerReply' onChange={event=>this.props.answerReply(event.target.value)}>
                                        </textarea>
                                        <div style={{marginTop:'1%'}}>
                                        <button style={{marginRight:'1%'}} className="btn btn-primary mr-3" onClick={()=> this.props.postAnswerReply('',this.props.answer.answerkey)}> Post a Comment </button>
                                        <button className="btn btn-warn mr-3" onClick={()=>this.props.cancelAnswerReply('')}> Cancel </button></div>
                                    </div>:''
                                } */}
                            </div>
        )
    }
}