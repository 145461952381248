import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../style/resource.css";
import "../../style/knowledge-hub.css";
import 'rodal/lib/rodal.css'
import Truncate from 'react-truncate';
import OpportunityNavbarMain from "./OpportunityNavbar";
import _ from 'underscore';
import axios from 'axios';
import '../../style/value-plus1.css';
import '../../style/value-plus.css';
import '../Navbar/navbarmain.css';
import more_image from '../../Images/more-image.png';
import summary_image from '../../Images/summary-image.png'
import Footer from "../Common/footer";
import {apiBaseUrl, apiBaseUrl1} from '../apiUtils';

let context=null;
export default class OpportunityLandingPage extends Component {

constructor(props) {
  super();
  
  context = this;

this.state= {
  guides:[],
  allGuides:[],
  tags:[],
  activeCat:'all', 
  activeTag:'all',
  limit:10,
  limitIncrementer:5,
  noGuidesAnyMore:true,
  catagory:[],
  selected:[],
  data:[],
  categoryArray:[],
  bannerArray:[],
  cardSliderFlag:null,
  cardSliderIndex:null,
  tagArrayLimit:[]
};
  this.getBlogsAndGuides = this.getBlogsAndGuides.bind(this);
  this.fetchCategoryById = this.fetchCategoryById.bind(this);
  this.fetchBannerImage = this.fetchBannerImage.bind(this)

}
componentDidMount(){
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  

  this.getBlogsAndGuides(this);
  this.fetchAllCategory(this)
  this.fetchBannerImage(this,'opportunity-growth')
  // this.filterURLParam()

}

fetchAllCategory(that){
 let temp=[]
  return axios({       
      url: (apiBaseUrl+'blogCategory/list'),
      method: 'GET',
      crossDomain: true,
      responseType: 'json', 
    }).then((response) => {  
      for(var i=0;i<response.data.length;i++){
        if(response.data[i].learningCategory==='opportunity-growth'){
          temp.push(response.data[i])
        }
      }
      that.setState({categoryArray:temp})
      that.filterURLParam(temp)
   });  
}

filterURLParam=(categoryData)=>{

  let urlArray = window.location.href.split('?')
 setTimeout(()=>{
  if(urlArray.length===2){
    urlArray=urlArray.pop()

    if(urlArray.split('=')[0]==='cl'){
      
      let cat=''
      for(var i=0; i<categoryData.length;i++){
        if(categoryData[i].category===urlArray.split('=').pop()){
          cat=categoryData[i].id
        }
      }
      this.filterByCat(cat)
    }
    else{
      // this.filterByTag(urlArray.split('=').pop())
      this.filterByTag('all')
    }
  }
},2000)

}

fetchBannerImage(that,filter){
  return axios({       
    url: (apiBaseUrl1+'banner/list'),
    method: 'GET',
    crossDomain: true,
    responseType: 'json', 
  }).then((response) => {  
    let tempBanner =[]
    tempBanner.push(_.find(response.data,function(banner){
      return banner.bannerType===filter
    }))
    that.setState({bannerArray:tempBanner})
 });
}


viewMore(){
let allGuides = Array.from(context.state.allGuides);
let currentLimit = context.state.limit;
let limitIncrementer = context.state.limitIncrementer;


  currentLimit = currentLimit + limitIncrementer;

  context.setState({limit:currentLimit});
if(currentLimit>=allGuides.length){
  context.setState({noGuidesAnyMore:false});
}

context.setState({guides:allGuides.splice(0,currentLimit)});

}

getBlogsAndGuides(that) {

  let limit = that.state.limit;

  var api = (apiBaseUrl+'blog/list');
  let tagArray=[]
  let tag=[]
    axios.get(api)
    .then(function (response) {
      
      var array= []
      for (var i=0; i<response.data.length;i++){
        if(response.data[i].learningCategory==='opportunity-growth'){
          array.push(response.data[i])
          tagArray.push(response.data[i].tags)
          // for (var j=0;j<response.data[i].tags.length;j++){
          //   if(response.data[i].tags[j].trim()!=='' && !tagArray.includes(response.data[i].tags[j])){
          //     tagArray.push(response.data[i].tags[j])
          //   }
          // }
        }
      }
      
      for(var i=0;i<tagArray.length;i++){
        for(var j=0;j<tagArray[i].length;j++){
          if(tagArray[i][j].trim()!=='' && !tag.includes(tagArray[i][j].trim())){
            tag.push(tagArray[i][j].trim())
          }
        }
      }
    array = _.sortBy(array,'date').reverse()
    


   let allGuides=Array.from(array)
   let currentLimit = context.state.limit;
    if (currentLimit>=allGuides.length){
      context.setState({noGuidesAnyMore:false})
    }

    // that.setState({allGuides:allGuides});
    // that.getTags(this,array);
    that.setState({allGuides:allGuides,guides:array.splice(0,limit),tagArrayLimit:tag.splice(0,15)});
    
    })
  }

filterByTag(tag){
this.setState({activeTag:tag})
let allGuides = context.state.allGuides;

if(tag=='all'){
  context.setState({guides:allGuides})
}

else{
  let filteredGuides = _.filter(allGuides,function(guide){

    return guide.tags!=undefined ? guide.tags.includes(tag) : false;
    
    });
    
    
    context.setState({guides:filteredGuides});
}


}


filterByCat(cat){
  context.setState({activeCat:cat})
  let allGuides = context.state.allGuides;
  if(cat=='all'){
    context.setState({guides:allGuides});
      
  }
  else{
    let filteredGuides = _.filter(allGuides,function(guide){
  
      return guide.category!=undefined ? guide.category.includes(cat) : false;
      
      });
      context.setState({guides:filteredGuides}); 
  }
}


getTags(that,guides){
let c=[]
let tags = [];
let catagory = [];

let i = 1;
_.each(guides,function(guide){

  if(guide.tags!==undefined && guide.tags!==''){  
    tags = _.union(tags,guide.tags);
  }
  if(guide.catagory!=undefined){
    c.push(guide.catagory);
  }
  if(guide.catagory!=undefined){
    catagory = _.union(catagory,c)
  }
 
});
let tagArrayLimit=tags.splice(0,10)

that.setState({"tags":tags,tagArrayLimit:tagArrayLimit});
that.setState({"catagory":catagory});

var data=[];
for(var j=0;j<tags.length;j++){
 var val={
    'value':tags[j]
  }
  data.push(val)

  
}
this.setState({'data':data})

}

// isactive(value){
//   for(var i=0; i<this.state.catagory; i++){
//     return ((value==this.state.catagory[i])?'active1':'default')
      
//   }
 
// }

fetchCategoryById(cat){
if(this.state.categoryArray.length>0){
  for(var i=0;i<this.state.categoryArray.length;i++){
    if(this.state.categoryArray[i].id===cat){
      return(this.state.categoryArray[i].category)
    }
  }
}
  
}

handleSlider=(i,index)=>{
  if(this.state.cardSliderIndex!==null && index!==this.state.cardSliderIndex){
    document.getElementById('more_sum_slider'+this.state.cardSliderIndex).classList.remove("slider-active")
  }
  

    if(this.state.cardSliderFlag===i){
      document.getElementById('more_sum_slider'+index).classList.toggle("slider-active")
    }
    else{
      document.getElementById('more_sum_slider'+index).classList.add('slider-active')
    }

  this.setState({cardSliderFlag:i,cardSliderIndex:index})
}

routeToDedicatedPage(blogId,heading){
  // sessionStorage.setItem('blogPrimaryId',blogId,heading)
  
  let urlHeading = heading.replaceAll(' ','_')
  urlHeading = urlHeading.trim('_')
  let urlBlogId = blogId.split('-').pop()
  let urlBlg=urlHeading+'_'+urlBlogId
  // console.log('route url:',urlHeading)
  window.location.replace('/opportunity-growth/resources?blg='+urlBlg)
}

render() {
return(

<div className="mainbody">
 <OpportunityNavbarMain subHead='Opportunity' url='opportunity'/>    

<div className='row' style={{marginTop:'9%'}}>
  <img className='banner-image' src= {this.state.bannerArray.length===1 && this.state.bannerArray[0]!==undefined?this.state.bannerArray[0].bannerImageUrl:require('../../Images/default_banner_img.png')}/>
</div>
<div className='col-md-12 col-lg-12 col-sm-12 khub_all_bl_con'>
  <div className="col-md-1 col-lg-1 col-sm-1"></div>
<div className="col-md-8 col-lg-8 col-sm-12">
<div className="onlyproductsection">

<div className="guidestuff" >
<div className="row">

<div className="back col-md-9 col-lg-9 col-sm-12" >
{this.state.guides.map((guide, index) => (
        <div className="col-md-12 col-lg-12">
          <div className="col-md-1 col-lg-1 khub-blogcard-opt hidden-xs hidden-sm">
            {guide.more.external.length>0?
            <div onClick={()=>this.handleSlider('more_'+index,index)}> <img style={{height:"60px"}} src={more_image} /> </div>
            :""}
            {guide.contentSummary!==undefined && guide.contentSummary.trim()!==''?
            <div onClick={()=>this.handleSlider('sum_'+index,index)}> <img src={summary_image} /> </div>
            :""}
            
          </div>
          <div id={'more_sum_slider'+index}  className="col-md-10 col-lg-10 khub-blogcard-opt-dd">
            {this.state.cardSliderFlag==='more_'+index?<div className="">
            <table className='table'>
              <thead>
              <tr>
              <th scope='col khub_blg_more_cl_head'>Source</th>
              <th scope='col khub_blg_more_cl_head'>Name</th>
              <th scope='col khub_blg_more_cl_head'>URL</th>
              </tr>
              </thead>
              {guide.more.external.map((show_more,index)=>(
               <tbody>
              <tr>
              <td>
                <div style={{color:'#353333'}}>
                <small>{show_more.src}</small></div>
              </td>
              <td>
                <small> <a style={{color:'#6b6868'}}>{show_more.name}</a> </small>
              </td>
              <td>
                <small> <a href={show_more.url} target="_blank" style={{color:'blue'}}>{show_more.url}</a> </small>
              </td>
              </tr>
              </tbody>
              ))} </table>

            </div>: 
            <div> 
              {/* {this.state.cardSliderFlag==='sum_'+index?<div> */}
              {guide.contentSummary!==undefined && guide.contentSummary.trim()!==''?<article> {guide.contentSummary} </article>:<div> No Information Found </div>}</div>
              // :"" }</div>
              }</div>
          
        <div className="article-style ">
          <div className="row">            
          <div>
          <h4 className={guide.blogType!="curated"?"resourcecategory":"resourcecategorycurated"}> <div>
          {guide.more.external.length>0?<h4 className="khub-blg-mr-sum-hd visible-xs visible-sm"  onClick={()=>this.handleSlider('more_'+index,index)}> More </h4>:""}
          {guide.contentSummary!==undefined && guide.contentSummary.trim()!==''?<h4 className="khub-blg-mr-sum-hd visible-xs visible-sm" onClick={()=>this.handleSlider('sum_'+index,index)}> Summary </h4>:""}
            <h4 className="heading25"> {this.fetchCategoryById(guide.category)} </h4>
          </div>
        </h4>
            </div>

      <div className=" col-md-8 col-lg-8 col-sm-9 col-xs-9 khub_card_blog_content">
      <h5 className="headstuff1">  {guide.blogType!="curated"?
        // <Link to={"/opportunity-growth/resources/"+guide.id}>{guide.heading}</Link>
        <div style={{cursor:'pointer'}} onClick={()=>this.routeToDedicatedPage(guide.blogId,guide.heading)}> {guide.heading} </div>
        :<a href={guide.sourceUrl} target='_blank'>{guide.heading}</a>}</h5>
          <div className="content" id='cont'>
           <Truncate lines={2} ellipsis ={<span>...{guide.blogType!="curated"?<Link to={"/opportunity-growth/resources/"+guide.id} style={{color:'#1d1dbc'}}>Read More</Link>:<a href={guide.sourceUrl} target='_blank'>Read More</a>} 
           </span>}>
             <p dangerouslySetInnerHTML={{__html:guide.truncateContent}}></p>
               
            </Truncate> 
            </div>
            <div className="row">
              <div className="col-md-8 col-lg-8 col-sm-8 col-xs-7">
      {guide.blogType!=='curated'?
      guide.authorName!==''?
      <div className="row" style={{marginTop:'0dpx'}}>
      <div className=" contentt detail10 text-left" style={{paddingLeft:'16px'}}>By {guide.authorName} </div>
      
      </div>:''
    : guide.sourceUrl!==''?
    <div className="row" style={{marginTop:'0dpx'}}>
    <spam className=" contentt detail10 text-left" style={{paddingLeft:'16px'}}>Source: <a className=" contentt detail10 text-left" href={guide.sourceUrl} target="_blank"> {guide.source} </a> </spam>
    
    </div>:''
    }
    </div>
    
             <div className="col-md-4 col-lg-4 col-sm-4 col-xs-5">
             <div className="row">
               {guide.datePosted!==''?
             <div className="col-md-5 col-sm-5 col-lg-5 col-xs-12" style={{padding:"0"}}>
             <div className="contentt detail20 text-left">{guide.datePosted} * {guide.readTime} mins read </div>
             </div>
               :''
              }
            
             </div>
             </div>
</div>
             <div className="khub_tag_btn_cont">
             {guide.tags!='' ? guide.tags.splice(0,4).map((tag, index) => tag.trim()!==''?<button type='button' className=' button260 '><h4 className="heading24">{tag}</h4></button>:""):''}
           
           
             </div>
             </div>
             {guide.blogImageURL!==undefined?
             <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 khub_blog_image_container">   
                <img className="image" src={guide.blogImageURL}/>
            </div>
             :''
            }
        
            </div>
            </div>
            </div> 
    ))}
    <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
    {this.state.noGuidesAnyMore? 
        <div className="view-more-button" onClick={()=>this.viewMore()}>
          
            
            <div >
            View More
            </div>
            <i style={{paddingLeft:'36%'}} class="fas fa-angle-double-down"></i>
        </div>   :""}
    </div>

         
</div>

<div className="col-md-1"></div>
</div>
</div>

</div>

</div>
<div className="col-md-2 col-lg-2 col-sm-12 tag-cat-container">
<div className="filters-cat">
<h5 className="heading9">
  Categories
</h5>
<ul className="cloud"> 
<li onClick={()=>this.filterByCat("all")}><h4 className={this.state.activeCat=='all' ? 'sidetag activeCat' : 'sidetag'}>All</h4></li> 

{this.state.categoryArray.map((cat, index) => ( 
  <li onClick={()=>this.filterByCat(cat.id)}><h4 className={this.state.activeCat==cat.id ? 'sidetag activeCat' : 'sidetag'}>{cat.category}</h4></li> 
   
 ))}

 </ul> 

 </div> 

 <div className="filters-tag">
<h5 className="heading9">

  Tags</h5> 

 <div className="cloud">

 <button
  // onClick={()=>this.filterByTag('all')} 
  className={this.state.activeTag=='all' ? 'button99 activeTag' : 'button99'} ><h4 className="heading24">All</h4></button>    

{this.state.tagArrayLimit.map((tag, index) => (
  tag!=''?
  <button
  //  onClick={()=>this.filterByTag(tag)} 
   className={this.state.activeTag==tag ? 'button99 activeTag' : 'button99'} ><h4 className="heading24">{tag}</h4></button>    :''
  // <button className={this.state.activeTag==tag ? 'button99 activeTag' : 'button99'} ><h4 className="heading24">{tag}</h4></button>    :''
))}
</div>

</div>
</div> 
</div>

<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12" style={{padding:0}}>
  <Footer/>
</div>

         
  
  </div>    
    );
  }
}