export const sitemap = [

    {
        title: "Legal & Finance",
        childs: [ {
            
                    link: "../legal-finance",
                    title: "Article"
            },
                                


        ]
    },
    {
        title: "Exim",
        childs: [{

                link: "../exim",
                title: "Article"
            },
        ]
    },
    {
        title: "Stories & Insights",
        childs: [{

                link: "../stories-insights",
                title: "Article"
            },
        ]
    },
    
    {
        title: "Opportunity & Growth",
        childs: [{

                link: "../opportunity-growth",
                title: "Article"
            }
,        ]
    },


]