import React,{ Component } from "react";
import axios from 'axios'
import NavbarMain from "../Navbar/navbarmain";
import ReactTooltip from 'react-tooltip';
import ReadOnly from "../Editor/ReadOnly";
import Modal from 'react-modal';
import Footer from "../Common/footer";

const customStyles = {
    content :{
        top:'50%',
        left: "50%",
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width:'80%',
        padding:'20px',
        height:'70vh',
        marginTop:"7vh"
    }
}

export default class GuideDedicatedPage extends Component{
    constructor(props){
        super(props)
        this.state={
            learningType:sessionStorage.getItem('guideFilter'),
            guideChapterList:[],
            guideBlogList:[],
            currentBlogId:'',
            guideTab:[],
            allid:[],
            tabids:[],
            styleTab:17,
            tabContent:20,
            guideTab:[],
            isGuideTabActive:'',
            copied:false,
            openShare1:false,
            openShare2:false,
            openMore1:false,
            openMore2:false,
            modalIsOpen:false,
        }
        this.collapseChapterBlog = this.collapseChapterBlog.bind(this)
    }

    componentDidMount(){
        let guideId = window.location.href.split('/').pop() ;
        
        this.fetchGuideChapter(guideId)
        
    }

    fetchGuideChapter(guideId){
        let temp_data=[]
        return axios({       
            url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guideChapter/list/',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => {
              for(var i=0;i<response.data.length;i++){
                if(response.data[i].guideId===guideId && response.data[i].learningType===this.state.learningType){
                    temp_data.push(response.data[i])
                }
              }
          this.setState({guideChapterList:temp_data})
          this.fetchGuideBlog(guideId)
         });
    }

    fetchGuideBlog(guideId){
        let temp_data=[]
        return axios({       
            url: 'https://q7ay5k66xi.execute-api.us-east-1.amazonaws.com/dev/businus/guideBlog/list/',
            method: 'GET',
            crossDomain: true,
            responseType: 'json', 
          }).then((response) => {
              for(var i=0;i<response.data.length;i++){
                if(response.data[i].guideId===guideId && response.data[i].learningType===this.state.learningType){
                    temp_data.push(response.data[i])
                }
              }
          this.setState({guideBlogList:temp_data})
            for(var i=0;i<temp_data.length;i++){
                if(temp_data[i].chapter===this.state.guideChapterList[i].id){
                    this.selectGuideBlog(temp_data[i].id)
                    this.collapseChapterBlog(0)
                    break
                }
          }
         });
    }

    selectGuideBlog(id){
        this.setState({currentBlogId:id})
        this.openGuideTab(id)
    }

    openGuideTab(id){
        var val = this.state.guideTab

        for(var i=0;i<this.state.guideBlogList.length;i++){
            if(this.state.guideBlogList[i].id===id){
                if(this.state.allid.includes(id)){
                    // this.isactiveTab(id)
                }
                else{
                    val.push(this.state.guideBlogList[i])
                    this.state.allid.push(id)   
                }
            }
        }

        var st = this.state.styleTab
        var tc = this.state.tabContent

        if(this.state.tabids.includes(id)){
            st = this.state.styleTab
            tc=this.state.tabContent
        }
        else{
            this.state.tabids.push(id)
            if(this.state.styleTab>8){
                st=st-1
            }
            if(this.state.tabContent>=5){
                tc=tc-1
            }
        }
        
        this.setState({
            styleTab:st,
            tabContent:tc,
            guideTab:val,
            openShare2:false,
            openMore2:false,
            copied:true
        })
    }

    removeGuideTab(i){
        var activeId = this.state.allid[i]
        var newTabData = this.state.guideTab
        var newallid = this.state.allid

        newTabData.splice(i,1)
        newallid.splice(i,1)
        this.state.tabids.splice(i,1)

        var st = this.state.styleTab
        var tc = this.state.tabContent

        if(this.state.styleTab>14){
            st=st+1
        }
        if(this.state.tabContent<12){
            tc=tc+1
        }

        if(activeId === this.state.currentBlogId){
            if(i===0){
                var id= this.state.allid[i]
            }
            else if(i!==0){
                var id = this.state.allid[i-1]
            }
        }
        else{
            var id = this.state.currentBlogId
        }

        this.setState({
            guideTab:newTabData,
            allid:newallid,
            styleTab:st,
            tabContent:tc,
            currentBlogId:id
        })
        // this.isactivetabcont(id)
    }

    activeBlogTab(id){
        this.setState({currentBlogId:id})
    }

    openModal(){
        this.setState({modalIsOpen:true,openShare1:false,openMore1:false})
    }

    closeModal=()=>{
        this.setState({modalIsOpen:false,openMore2:false,openShare2:false})
    }

    copy(e){
        var link=window.location.href
        var textField = document.createElement('textarea')
        textField.innerText = link
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
       
    
      this.setState({copied:e})
    }

    share(index){
        if(index===1){
            this.setState({shareurl:window.location.href,openShare1:!this.state.openShare1,openMore1:false})
        }
        else{
          this.setState({shareurl:window.location.href, openShare2:!this.state.openShare2,openMore2:false})
        }
    }

    more(index){
        if(index===1){
            this.setState({openMore1:!this.state.openMore1,openShare1:false})
        }
        else{
            this.setState({openMore2:!this.state.openMore2,openShare2:false})
        }
    }

    collapseChapterBlog(index){
        const acc =document.getElementById('accordin-header'+index)
        const panel = acc.nextElementSibling;
        panel.classList.toggle('collapse-active')
        acc.classList.toggle('collapse-active')
    }

    returnGuideBlog(id){
        let guideBlogList = this.state.guideBlogList
        let returnGuideBlogList = []
        for(var i=0;i<guideBlogList.length;i++){
            if(guideBlogList[i].chapter===id){
                returnGuideBlogList.push(guideBlogList[i])
            }
        }
        return(<div>
            {returnGuideBlogList.map((blog)=>(
            <div onClick={()=>this.selectGuideBlog(blog.id)} 
            className={this.state.currentBlogId===blog.id?'guide-blog-index-bl-h blog-active':'guide-blog-index-bl-h '}> {blog.heading} </div>  
            ))}
        </div>
        )
    }

    render(){
        return(
            <div className='mainbody'>
                <NavbarMain subHead='Legal-Finance' url='legal-finance'/>
                <div className='row'>
                    <div className='col-md-12 col-lg-12'>
                        {/* Sidebar Index */}
                        <div className='col-md-3 col-lg-3' style={{paddingLeft:'0'}}>
                            <div className='guide-blog-index-container'>
                                {this.state.guideChapterList.map((guide,index)=>(
                                    <div>
                                        <div className='guide-blog-index-ch-n accordin-header' id={'accordin-header'+index} onClick={() =>this.collapseChapterBlog(index)}> <i className='fas fa-angle-right'></i> {guide.chapter} </div>
                                        <div className='accordin-body'> {this.returnGuideBlog(guide.id)} </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-md-9 col-lg-9' style={{position:'relative'}}>
                            {/* Content at Right Side */}
                            <div className='row'>
                                <ul style={{marginLeft:'-3px'}} className='nav nav1 nav-pills set-resmargin'>
                                    {this.state.guideTab.map((tab,index)=>(
                                        <li className='te' key={index} style={{width:this.state.styleTab+'%'}}  ><a data-toggle="tab" href={"#tab"+tab.id} className={tab.id===this.state.currentBlogId?"activetab":"notactivetab"} style={{fontSize:'12px'}} onClick={()=>this.activeBlogTab(tab.id)}>
                                        <a data-for='tab-heading' data-tip={tab.heading}>  {tab.heading.substring(0,this.state.tabContent)} ...</a>  
                                        </a><span className='closetab1' onClick={()=>this.removeGuideTab(index)}><i class="fas fa-times"></i></span>
                                        <ReactTooltip place='top' id='tab-heading' />
                                        </li> 
                                    ))}
                                </ul>
                            </div>                                 
                                
                                <div className='row' style={{width:"98%"}}>
                                {this.state.guideTab.length!==0?
                                    <div className='guide-blog-topbar'>
                                    <div className='tab-content'>
                                        {this.state.guideTab.map((tab,index)=>(
                                    <div style={{display:this.state.currentBlogId===tab.id?"block":'none'}}>
                                    
                                    {/* Content feature strip */}
                                    <div className='row guide-top-strip sticky'>
                                <div>
                                    <div className="btn " style={{color:'black',float:'left',marginLeft:'-16%'}} data-toggle="modal" data-target={'.'+index} onClick={()=> this.openModal()}>
                                    <span  data-for="resize" data-tip="Maximize" className="glyphicon glyphicon-resize-full"></span> </div>
                                        <ReactTooltip place="top" id="resize"/> 
                                        <div className='more' >                  
                                        <h5 data-for='more' data-tip='More' style={{paddingTop:'-3px',fontSize:'14px',float:'right',marginTop:'8px',cursor:'pointer'}} onClick={()=> this.more(1)}>  More </h5>                  
                                        <ReactTooltip place='bottom' id='more' />
                                        </div>

                                        <div data-for="share" data-tip="Share"  className='share'>                    
                                                <div onClick={()=>this.share(1)}> 
                                            <i  data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="fas fa-share-alt"></i>   </div></div>               
                                            <ReactTooltip place="top" id="share"/> 

                                    {this.state.copied?
                                        <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(false)}><i class="far fa-copy"></i>
                                        </div> :
                                        <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(true)}><i class="fas fa-copy"></i>
                                        </div>}
                                        <ReactTooltip place="top" id="copy"/> 
                                        </div></div>

                                    <div className='row' style={{padding:"20px"}}>                                        

                                    {this.state.openShare1?
                                    <div className='row'>
                                        <div className='guide-shareall-dropdown' style={{marginLeft:'118vh'}}>
                                    <a href={"https://www.facebook.com/sharer.php?u=https://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="blank">
                                    <div className='share-option'>  
                                    <i style={{color:'#01949e'}} className="fab fa-facebook-square guide-share-icon-font"></i></div></a>

                                    <a href={"mailto: ?body= http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_top">
                                    <div className='share-option'>
                                    <i style={{color:'#c91b1b'}} className="fas fa-envelope guide-share-icon-font"></i>
                                    </div></a>

                                    <a href={"https://api.whatsapp.com/send?text=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
                                    <div className='share-option'>    
                                    <i style={{color:'green'}} class="fab fa-whatsapp guide-share-icon-font"></i></div></a>

                                    <a href={"https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
                                    <div className='share-option'>    
                                    <i class="fab fa-twitter guide-share-icon-font"></i></div></a>

                                    <a href={"https://www.linkedin.com/shareArticle?mini=true&url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
                                    <div className='share-option'>    
                                    <i class="fab fa-linkedin guide-share-icon-font"></i></div></a>
                                    </div></div>
                                         :'' 
                                        }


                                <div className='col-md-3 col-lg-3'>
                                {tab.authorName!==''?
                                    <div className="guide-contentpage text-left">By {tab.authorName} </div>:''}
                                {tab.readTime!==''?
                                    <div className="guide-contentpage text-left"> {tab.readTime} * mins read</div>:''
                                }
                                </div>
                                <div className='col-md-6 col-lg-6'></div>
                                {tab.sourceUrl!=''?
                                <div className="col-md-3 col-lg-3">
                                <div className="guide-source text-right"> <a href={tab.sourceUrl} target='_blank'> Source: {tab.source}</a></div>
                                </div>:''
                                }
                                 
                                <center className='guide-heading-case' id="heading">{tab.heading}</center>
                                <center className='subheading-case' id="sub-heading">{tab.subHeading}</center>
                                <div className='guide-main-c'>
                                    <ReadOnly data={tab.content} />
                                </div>
                                <div className='row'>
                                    <div className='tagcont'>
                                        <div className='col-md-2 col-lg-2'><h5 className='guide-contenttag'>Tags:</h5></div>
                                        <div className='col-md-8'><div className='tagname'>
                                        {tab.tags!==''?tab.tags.map((tag)=>(<button type='button' className='button260 text-center'> <h4 className='heading24'> {tag} </h4> </button>)):""}
                                        </div></div>
                                    </div>
                                </div></div>
                                {this.state.openMore1?
                                    tab.more.length!==0?
                                <div>
                                    <div className='guide-arrow-up' style={{marginTop:'-23%',marginLeft:'97%'}}></div>
                                    <div className='guide-moreOpt' style={{marginTop:'-22%',marginLeft:'2%'}}>
                                        <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='guide-more-table-heading' scope='col'>Source</th>
                                                <th className='guide-more-table-heading' scope='col'>Name</th>
                                                <th className='guide-more-table-heading' scope='col'>URL</th>
                                            </tr>
                                        </thead>

                                        {tab.more.map((show_more)=>(
                                        <tbody>
                                        <tr>
                                            <td>
                                                <div className='guide-more-table-d' style={{color:'#353333'}}>
                                                    <small> {show_more.source} </small>
                                                </div>
                                                </td>
                                            <td>
                                                <div className='guide-more-table-d'>
                                                    <small> <a style={{color:'#6b6868'}}> {show_more.name} </a></small>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='guide-more-table-d' style={{color:'#353333'}}>
                                                    <small> <a href={show_more.url} target="_blank" style={{color:'blue'}}> {show_more.url} </a></small>
                                            </div>
                                            </td>
                                            </tr>
                                        </tbody>
                                        ))}

                                        </table>
                                    </div>
                                </div>:<div>No Information Found</div>:""
                                }
                                <div>

                                </div>
                                </div>
                                        ))}</div>
                                        </div>:''
                                    }</div>
                                
                                {/* Modal Code start from here */}

                                <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                style={customStyles}
                                >

                            <div className='row' style={{width:"98%"}}>
                                {this.state.guideTab.length!==0?
                                    <div className='guide-modal-blog-topbar'>
                                    <div className='tab-content'>
                                        {this.state.guideTab.map((tab,index)=>(
                                    <div style={{display:this.state.currentBlogId===tab.id?"block":'none'}}>
                                    
                                    {/* Content feature strip */}
                                    <div className='row guide-modal-top-strip sticky'>
                                <div>
                                    <div className="btn " style={{color:'black',float:'left',marginLeft:'1%'}} data-toggle="modal" data-target={'.'+index} onClick={()=> this.closeModal()}>
                                    <span  data-for="Minimize" data-tip="Minimize" className="glyphicon glyphicon-resize-small"></span> </div>
                                        <ReactTooltip place="top" id="Minimize"/> 
                                        <div className='more' >                  
                                        <h5 data-for='more' data-tip='More' style={{paddingTop:'-3px',fontSize:'14px',float:'right',marginTop:'8px',cursor:'pointer'}} onClick={()=> this.more()}>  More </h5>                  
                                        <ReactTooltip place='bottom' id='more' />
                                        </div>

                                        <div data-for="share" data-tip="Share"  className='share'>                    
                                                <div onClick={()=>this.share(2)}> 
                                            <i  data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="fas fa-share-alt"></i>   </div></div>               
                                            <ReactTooltip place="top" id="share"/> 

                                    {this.state.copied?
                                        <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(false)}><i class="far fa-copy"></i>
                                        </div> :
                                        <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(true)}><i class="fas fa-copy"></i>
                                        </div>}
                                        <ReactTooltip place="top" id="copy"/> 
                                        </div></div>

                                    <div className='row' style={{padding:"20px"}}>                                        

                                    {this.state.openShare2?
                                    <div className='row'>
                                        <div className='guide-shareall-dropdown' style={{marginLeft:'130vh'}}>
                                    <a href={"https://www.facebook.com/sharer.php?u=https://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="blank">
                                    <div className='share-option'>  
                                    <i style={{color:'#01949e'}} className="fab fa-facebook-square guide-share-icon-font"></i></div></a>

                                    <a href={"mailto: ?body= http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_top">
                                    <div className='share-option'>
                                    <i style={{color:'#c91b1b'}} className="fas fa-envelope guide-share-icon-font"></i>
                                    </div></a>

                                    <a href={"https://api.whatsapp.com/send?text=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
                                    <div className='share-option'>    
                                    <i style={{color:'green'}} class="fab fa-whatsapp guide-share-icon-font"></i></div></a>

                                    <a href={"https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
                                    <div className='share-option'>    
                                    <i class="fab fa-twitter guide-share-icon-font"></i></div></a>

                                    <a href={"https://www.linkedin.com/shareArticle?mini=true&url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
                                    <div className='share-option'>    
                                    <i class="fab fa-linkedin guide-share-icon-font"></i></div></a>
                                    </div></div>
                                         :'' 
                                        }


                                <div className='col-md-3 col-lg-3'>
                                {tab.authorName!==''?
                                    <div className="contentfullpage1  text-left">By {tab.authorName} </div>:''}
                                {tab.readTime!==''?
                                    <div className="contenttfullpage text-left"> {tab.readTime} * mins read</div>:''
                                }
                                </div>
                                <div className='col-md-6 col-lg-6'></div>
                                {tab.sourceUrl!=''?
                                <div className="col-md-3 col-lg-3">
                                <div className="guide-source text-right"> <a href={tab.sourceUrl} target='_blank'> Source: {tab.source}</a></div>
                                </div>:''
                                }
                                 
                                <center className='guide-heading-case' id="heading">{tab.heading}</center>
                                <center className='subheading-case' id="sub-heading">{tab.subHeading}</center>
                                <div className='guide-main-c'>
                                    <ReadOnly data={tab.content} />
                                </div>

                                <div className='row'>
                                    <div className='tagcont'>
                                        <div className='col-md-2 col-lg-2'><h5 className='guide-contenttag'>Tags:</h5></div>
                                        <div className='col-md-8'><div className='tagname'>
                                        {tab.tags!==''?tab.tags.map((tag)=>(<button type='button' className='button260 text-center'> <h4 className='heading24'> {tag} </h4> </button>)):""}
                                        </div></div>
                                    </div>
                                </div></div>
                                
                                {this.state.openMore2?
                                    tab.more.length!==0?
                                <div>
                                    <div className='guide-arrow-up' style={{marginLeft:'96%',marginTop:'-21%'}}></div>
                                    <div className='guide-moreOpt' style={{marginLeft:'1%',marginTop:"-20%"}}>
                                        <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='guide-more-table-heading' scope='col'>Source</th>
                                                <th className='guide-more-table-heading' scope='col'>Name</th>
                                                <th className='guide-more-table-heading' scope='col'>URL</th>
                                            </tr>
                                        </thead>

                                        {tab.more.map((show_more)=>(
                                        <tbody>
                                        <tr>
                                            <td>
                                                <div className='guide-more-table-d' style={{color:'#353333'}}>
                                                    <small> {show_more.source} </small>
                                                </div>
                                                </td>
                                            <td>
                                                <div className='guide-more-table-d'>
                                                    <small> <a style={{color:'#6b6868'}}> {show_more.name} </a></small>
                                                </div>
                                            </td>
                                            <td>
                                            <div className='guide-more-table-d' style={{color:'#353333'}}>
                                                    <small> <a href={show_more.url} target="_blank" style={{color:'blue'}}> {show_more.url} </a></small>
                                            </div>
                                            </td>
                                            </tr>
                                        </tbody>
                                        ))}

                                        </table>
                                    </div>
                                </div>:<div>No Information Found</div>:""
                                }
                                </div>
                                        ))}</div>
                                        </div>:''
                                    }</div>

                                </Modal>
                               
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                           {this.state.guideTab.length!==0?
                           <Footer/>:''} 
                    </div>

            </div>
        )
    }
}