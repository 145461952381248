import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonOtherBlog =()=>{
    return(
        <div className="skeleton-wrapper light">
            {[1,2,3,4].map((n)=>
            <div className="skeleton-profile">
            <div>
                <SkeletonElement type="thumbnail"/>
            </div>
            <div>
                <SkeletonElement type="title-ob"/>
                <SkeletonElement type="sk-text"/>
                <SkeletonElement type="sk-text"/>
            </div>
        </div>
            )}
            
        </div>
    )
}

export default SkeletonOtherBlog