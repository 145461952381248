import React, {Component} from 'react'
import axios from 'axios'
import _ from 'underscore'
import AddQuestion from './AddQnA'
import QnA from './QnA'
import Moment from 'react-moment'
import {Link} from 'react-router-dom'
import { apiBaseUrl } from '../apiUtils'

export default class GroupQuestion extends Component{
    constructor(props){
        super(props)
        this.state={
            key:-1,
            blogTabId:this.props.blogTabId,
            name:'',
            img:'',
            questionId:'',
            isquestions:false,
            lastIndex:2,
            allquestion:[],
            description:'',
            questions:[],
            tagArray:[],
            tag:'',
            isPost:false,
            profilePic:[
                {alphabet:'A',img:"A_logo"},
                {alphabet:'B',img:"B_logo"},
                {alphabet:'C',img:"C_logo"},
                {alphabet:'D',img:"D_logo"},
                {alphabet:'E',img:"E_logo"},
                {alphabet:'F',img:"F_logo"},
                {alphabet:'G',img:"G_logo"},
                {alphabet:'H',img:"H_logo"},
                {alphabet:'I',img:"I_logo"},
                {alphabet:'J',img:"J_logo"},
                {alphabet:'K',img:"K_logo"},
                {alphabet:'L',img:"L_logo"},
                {alphabet:'M',img:"M_logo"},
                {alphabet:'N',img:"N_logo"},
                {alphabet:'O',img:"O_logo"},
                {alphabet:'P',img:"P_logo"},
                {alphabet:'Q',img:"Q_logo"},
                {alphabet:'R',img:"R_logo"},
                {alphabet:'S',img:"S_logo"},
                {alphabet:'T',img:"T_logo"},
                {alphabet:'U',img:"U_logo"},
                {alphabet:'V',img:"V_logo"},
                {alphabet:'W',img:"W_logo"},
                {alphabet:'X',img:"X_logo"},
                {alphabet:'Y',img:"Y_logo"},
                {alphabet:'Z',img:"Z_logo"},
            ]
        }
        this.renderQuestion= this.renderQuestion.bind(this)
        this.handleQuestion = this.handleQuestion.bind(this)
        this.addTag = this.addTag.bind(this)
        // this.handleQuestionReply = this.handleQuestionReply.bind(this)
        this.fetchAllQuestion = this.fetchAllQuestion.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
    }

    componentDidMount(){
        var blogTabId = window.location.href.split("/").pop()

        this.createProfile()
        this.setState({blogTabId:blogTabId})
        this.fetchAllQuestion(blogTabId)
    }

    componentWillReceiveProps(nextProps){
        var blogTabId = nextProps.blogTabId
        this.setState({blogTabId:blogTabId,})

        if(nextProps.blogTabId!== this.props.blogTabId){
            this.fetchAllQuestion(blogTabId)
        }
        
    }

    componentDidUpdate(prevProps,prevState){
        if(this.state.isPost){
            if(prevState.isPost!==this.state.isPost){
                this.fetchAllQuestion(this.props.blogTabId)
                this.setState({isPost:false})              
            }
        }
    }

    createProfile(){
        if(sessionStorage.getItem("isLoggedIn")==="YES"){
            let name = sessionStorage.getItem("userName")
            name = name.trim()
            for(var i=0;i<name.length; i++){
              if(name[i]==='@'){
                name=name.slice(0,i)
                break
              }
            }
    
            let profile = this.state.profilePic
            let img =""
    
            for(var i=0; i<profile.length; i++){
                if(name[0].toUpperCase()===profile[i].alphabet){
                    img = profile[i].img 
                }
            }
            this.setState({name:name,img:img})
        }
    }

    fetchAllQuestion(blogTabId){
        var questions = []
        var isquestion = false
        var questionId =""
        axios({
            url: (apiBaseUrl+'comment/list'),
            method:'GET',
            crossDomain:true,
            response:'JSON',
          }).then((response)=>{
              for(var i=0;i<response.data.length;i++){
                  if(response.data[i].blogId===blogTabId){
                      if(response.data[i].type==='QnA'){
                    questions = response.data[i]
                    isquestion = true
                    questionId = response.data[i].id
                    
                    }
                  }
              }
            
            this.setState({
                allquestion:questions,
                questions:isquestion?questions.questions:questions,
                isquestion:isquestion,
                questionId:questionId})
          })
    }
    

    addQuestion(questions){
       
            let data = {
                blogId:this.props.blogTabId,
                questions:questions,
                type:"QnA"
            }
            axios({
                url: (apiBaseUrl+'comment/add'),
                method:'POST',
                data:data,
              }).then((response)=>{
                if(response.status===200){
                    this.setState({isPost:true})
                }
              })
            //   this.fetchAllQuestion(this.state.blogTabId)

    }

    updateQuestion(id,questions){
        let data ={
            questions: questions,
        }
        axios({
            url: (apiBaseUrl+'comment/update/'+id),
            method:'POST',
            data:data,
          }).then((response)=>{
            if(response.status===200){
                this.setState({isPost:true})
            }
          })
        //   this.fetchAllQuestion(this.state.blogTabId)
    }

    renderQuestion(that){
        const questions = this.state.questions
        questions.map(caption=>{
            // const{id, question} = caption
            return(
                
            <div className='' key={caption.id}> {caption.question}</div>
            )
        })
    }

    handleQuestion(question,description,tags){
  

    if(sessionStorage.getItem("isLoggedIn")==="YES"){
        if(question!=''){

            let tagArray =[]

            for(var i=0;i<tags.length;i++){
                tagArray.push(tags[i].tag)
            }

            let questions = this.state.questions
      
            let time = new Date()
            questions.unshift({
                questionKey:questions.length+1, 
                pic:this.state.img,
                name:this.state.name,
                question: question,
                tags:tagArray,
                answer:[],
                time: time,
                description:description
            })
    
            if(this.state.isquestion){
                this.updateQuestion(this.state.questionId,questions)
            }
            else{
                this.addQuestion(questions)
            }
            
            this.setState({questions: questions,isquestion:true,tagArray:[]})
        }
     }

        else{
            alert("Please Login before questioning")
        }
    }

    // handleQuestionReply(key,question){

    //         let questions = this.state.questions
    //         let time = new Date()
    //         for(var i =0; i<questions.length; i++){
    //             if(questions[i].key===key){
    //                 questions[i].reply.unshift({
    //                     replyKey:questions[i].reply.length+1,
    //                     pic:this.state.img,
    //                     name:this.state.name,
    //                     question:question,
    //                     time:time
    //                 })
    //             }
    //         }
  
    //         // this.updateQuestion(this.state.questionId,questions)
    //         this.setState({questions:questions})
            
        
    // }

    setTag(tag){
        this.setState({tag:tag})
    }

    addTag(tag,tagArray){
        let array=tagArray
        array.unshift({
            key:array.length+1,
            tag:tag
        })
        this.setState({tagArray:array,tag:''})
    }

    handleDelete(key){
        let array = this.state.tagArray
        for(var i=0; i<array.length; i++){
            if(array[i].key===key){
                array.splice(i,1)
            }
        }
        this.setState({tagArray:array})
    }

    render(){
        return(
            <div className="allquestion">
                
                <AddQuestion handleDelete={this.handleDelete} tags={this.state.tagArray} addTag={this.addTag} handleQuestion={this.handleQuestion} />

                {/* <div className="tagBlock">
                    {this.state.tagArray!==[]?
                    this.state.tagArray.map((tag)=>(
                        <div className="chip">
                            {tag.tag}
                            <span className="closeChip" onClick={()=>this.handleDelete(tag.key)}>&times;</span>
                        </div>
                    )):''}

                    <textarea name='tag' className="form-control" placeholder="Enter Tags" 
                    onChange={event=> this.setTag(event.target.value)} value={this.state.tag}></textarea>

                    <button className="btn btn-primary mr-3" onClick={()=>this.addTag(this.state.tag,this.state.tagArray)}> Add Tags</button>
                </div> */}

                {this.state.isquestion?
                    this.state.questions.map((caption,index)=>(
                        <div className='singleQuestion'>
                            <img class="commentPic" src={require('../../Images/alphabet-images/'+caption.pic+'.png')}></img>
                            <span className="commentName">
                                {caption.name}
                            </span>
                            <Moment className='commentTime' fromNow>{caption.time}</Moment>
                            <div className='question'>
                                <Link to={'/businus/legal/blogs/'+caption.questionKey+"/"+this.state.questionId}>  {caption.question}  </Link> 
                            </div>
                            <div className='questionDescription'>
                                {caption.description}
                            </div>

                            {caption.tags!==undefined?
                            <div style={{marginLeft:"4%"}}>
                                {caption.tags.map((tag)=>(
                                    <span className='tagsClass'>
                                        {tag}
                                    </span>
                                ))}
                                </div>
                                :''
                            }
                            
                            </div>
                        
                        // <QnA 
                        
                        // key={caption.id} 
                        // id={caption.questionKey} 
                        // pic={caption.pic}
                        // name={caption.name} 
                        // question={caption.question} 
                        // // reply={caption.reply} 
                        // description={caption.description}
                        // time={caption.time}
                        // handleQuestionReply={this.handleQuestionReply} />

                     )):''
                } 
                
            </div>
        )
    }
}