import React, {Component} from 'react'
import AddComment from './AddComment'
import Comment from './Comments'
import axios from 'axios'
import _ from 'underscore'
import { apiBaseUrl } from '../apiUtils'

export default class GroupComment extends Component{
    constructor(props){
        super(props)
        this.state={
            key:-1,
            blogTabId:this.props.blogTabId,
            name:'',
            img:'',
            commentId:'',
            iscomment:false,
            lastIndex:2,
            allComment:[],
            comments:[],
            profilePic:[
                {alphabet:'A',img:"A_logo"},
                {alphabet:'B',img:"B_logo"},
                {alphabet:'C',img:"C_logo"},
                {alphabet:'D',img:"D_logo"},
                {alphabet:'E',img:"E_logo"},
                {alphabet:'F',img:"F_logo"},
                {alphabet:'G',img:"G_logo"},
                {alphabet:'H',img:"H_logo"},
                {alphabet:'I',img:"I_logo"},
                {alphabet:'J',img:"J_logo"},
                {alphabet:'K',img:"K_logo"},
                {alphabet:'L',img:"L_logo"},
                {alphabet:'M',img:"M_logo"},
                {alphabet:'N',img:"N_logo"},
                {alphabet:'O',img:"O_logo"},
                {alphabet:'P',img:"P_logo"},
                {alphabet:'Q',img:"Q_logo"},
                {alphabet:'R',img:"R_logo"},
                {alphabet:'S',img:"S_logo"},
                {alphabet:'T',img:"T_logo"},
                {alphabet:'U',img:"U_logo"},
                {alphabet:'V',img:"V_logo"},
                {alphabet:'W',img:"W_logo"},
                {alphabet:'X',img:"X_logo"},
                {alphabet:'Y',img:"Y_logo"},
                {alphabet:'Z',img:"Z_logo"},
            ],
            isCommentPost:false,
        }
        this.renderComment= this.renderComment.bind(this)
        this.handleComment = this.handleComment.bind(this)
        this.handleCommentReply = this.handleCommentReply.bind(this)
        this.fetchAllComment = this.fetchAllComment.bind(this)
    }

    componentDidMount(){
        var blogTabId = window.location.href.split("/").pop()

        this.createProfile()
        this.setState({blogTabId:blogTabId})
        this.fetchAllComment(blogTabId)
    }

    componentWillReceiveProps(nextProps){
        var blogTabId = nextProps.blogTabId
        this.setState({blogTabId:blogTabId,})
        if(nextProps.blogTabId!== this.props.blogTabId){
            this.fetchAllComment(blogTabId)
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(this.state.isCommentPost){
            if(prevState.isCommentPost!==this.state.isCommentPost){
                this.fetchAllComment(this.props.blogTabId)
                this.setState({isCommentPost:false})
            }
        }

    }

    createProfile(){
        if(sessionStorage.getItem("isLoggedIn")==="YES"){
            let name = sessionStorage.getItem("userName")
            name = name.trim()
            for(var i=0;i<name.length; i++){
              if(name[i]==='@'){
                name=name.slice(0,i)
                break
              }
            }
    
            let profile = this.state.profilePic
            let img =""
    
            for(var i=0; i<profile.length; i++){
                if(name[0].toUpperCase()===profile[i].alphabet){
                    img = profile[i].img 
                }
            }
            this.setState({name:name,img:img})
        }
    }

    fetchAllComment(blogTabId){
        var comments = []
        var iscomment = false
        var commentId =""
        axios({
            url:(apiBaseUrl+'comment/list'),
            method:'GET',
            crossDomain:true,
            response:'JSON',
          }).then((response)=>{
              for(var i=0;i<response.data.length;i++){
                  if(response.data[i].blogId===blogTabId){
                      if(response.data[i].type==='comment'){
                    comments = response.data[i]
                    iscomment = true
                    commentId = response.data[i].id
                      }
                  }
              }
              
            this.setState({
                allComment:comments,
                comments:iscomment?comments.comments:comments,
                iscomment:iscomment,
                commentId:commentId})
          })
        
    }
    

    addComment(comments){
        let data = {
            blogId:this.props.blogTabId,
            comments:comments,
            type:'comment'
        }
        axios({
            url:(apiBaseUrl+'comment/add'),
            method:'POST',
            data:data,
          }).then((response)=>{
            if(response.status===200){
                this.setState({isCommentPost:true})
            } 
          })

        //   this.fetchAllComment(this.state.blogTabId)
    }

    updateComment(id,comments){
        let data ={
            comments: comments,
        }
        axios({
            url:(apiBaseUrl+'comment/update/'+id),
            method:'POST',
            data:data,
          }).then((response)=>{
             if(response.status===200){
                 this.setState({isCommentPost:true})
             }
          })
        //   this.fetchAllComment(this.state.blogTabId)
    }

    renderComment(that){
        const comments = this.state.comments
        comments.map(caption=>{
            // const{id, comment} = caption
            return(
                
            <div className='' key={caption.id}> {caption.comment}</div>
            )
        })
    }

    handleComment(comment){
  

    if(sessionStorage.getItem("isLoggedIn")==="YES"){
        let comments = this.state.comments
      
        let time = new Date()
        comments.unshift({
            key:comments.length+1, 
            pic:this.state.img,
            name:this.state.name,
            comment: comment,
            reply:[],
            time: time
        })

        if(this.state.iscomment){
            this.updateComment(this.state.commentId,comments)
        }
        else{
            this.addComment(comments)
        }
        
        this.setState({comments: comments,iscomment:true})
     }

        else{
            alert("Please Login before commenting")
        }
    }

    handleCommentReply(key,comment){

            let comments = this.state.comments
            let time = new Date()
            for(var i =0; i<comments.length; i++){
                if(comments[i].key===key){
                    comments[i].reply.unshift({
                        replyKey:comments[i].reply.length+1,
                        pic:this.state.img,
                        name:this.state.name,
                        comment:comment,
                        time:time
                    })
                }
            }
            this.updateComment(this.state.commentId,comments)
            this.setState({comments:comments})
            
        
    }

    render(){
        return(
            <div className="allComment">
                
                <AddComment handleComment={this.handleComment} />
                {this.state.iscomment?
                    this.state.comments.map((caption,index)=>(
                        <Comment 
                        key={caption.id} 
                        id={caption.key} 
                        pic={caption.pic}
                        name={caption.name} 
                        comment={caption.comment} 
                        reply={caption.reply} 
                        time={caption.time}
                        handleCommentReply={this.handleCommentReply} />
                    )):''
                }
                
            </div>
        )
    }
}