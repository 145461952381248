import React, { Component } from 'react';
import Footer from '../footer';
import '../about.css'
import _ from "underscore";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css'
import axios from "axios";
import NavbarMain from '../../Navbar/navbarmain';
import { apiBaseUrl5 } from '../../apiUtils';


let context = null;

export default class Contact extends Component {


  constructor(props) {
    super();
    context = this;
    this.state = {
      name: "",
      title: "",
      company: "",
      subject: "",
      email: "",
      phone: "",
      message: "",
      success: false,
      formErrors: { email: '', phone: '' },
      formValid: true,
      emailValid: false,
      phoneValid: false,
    };
    this.submitForm = this.submitForm.bind(this);
    context = this;
  }


  hide() {
    this.setState({ success: false });
  }

  isValidate() {

    var status = false;



    if (this.state.name.length > 0 &&
      this.state.title.length > 0 &&
      this.state.company.length > 0 &&
      this.state.phone.length > 0 &&
      this.state.email.length > 0 &&
      this.state.message.length > 0 && 
      this.state.emailValid &&
      this.state.phoneValid
      ) {


      status = true;
    }





    return status;



  }

  
  submitForm(e) {
    this.setState({ showError: false });
    if (!this.isValidate()) {
      this.setState({ showError: true });
      return true;


    }

    this.setState({ success: false });
    e.preventDefault();
    axios.post(apiBaseUrl5 + "businusquery/query",
      {
        "name": this.state.name,
        "phone": this.state.phone,
        "email": this.state.email,

        "message": this.state.message,
        "company": this.state.company,
        "employee": this.state.employee,
        "cityName": this.state.cityName,
        "state": this.state.stateName,
        "title": this.state.title,
        "source": "contactUs",
        "queryType": "contactUs",
        "subject": "Contact Us : Businus.com",
        "type": 1
      })
      .then(function (response) {
        if (response.status == 200) {
          context.setState({ success: true });
          context.setState({ pageNo: 5 });
          
          context.resetFields();
      
        } else {

        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  resetFields() {

    this.setState({
      name: "",
      title: "",
      company: "",
      subject: "",
      email: "",
      phone: "",
      cityName: "",
      productName: "",
      message: "",
      stateName: "",
      typeOfEntity: "Type of entity",
      employee: "",
      productCat:"Select Options"

    })
  }
  onChange = e => {
    // Because we named the inputs to match their corresponding values in state, it's
    // super easy to update the state

    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    }, () => { this.validateField(name, value) });
  };

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let phoneValid = this.state.phoneValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Invalid';
        break;
      // case 'password':
      //   passwordValid = value.length >= 6;
      //   fieldValidationErrors.password = passwordValid ? '' : ' is too short';
      //   break;
      case 'phone':
        phoneValid = value.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
        fieldValidationErrors.phone = phoneValid ? '' : 'Invalid';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      phoneValid: phoneValid
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : 'is-invalid');
  }

  render() {
    return (







      <div className="mainbody">
        <NavbarMain />
        <div className="request1 requestmap">
          <div class="container khub_contact_req">
            <h3 class="mt-4 mb-3"></h3>
            {/*<!-- Content Row -->*/}
            <div class="row">
              {/*  <!-- Map Column -->*/}
              <div class="col-md-8 mb-4 map">
                {/*<!-- Embedded Google Map -->*/}
                <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=10,%20j%20block,%20green%20park,%20new%20delhi-110016+(Value%20Plus%20Consultants)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
              </div>
              {/*<!-- Contact Details Column -->*/}
              <div class="col-lg-4 mb-4">
                <h3 class="heading details">Contact Details</h3>
                <p>
                  <div className="details"> J-10, Green Park Main</div>
                 
                  <div className="details"> New Delhi- 110016</div>
                </p>
                <p></p>
                <p className="details" >
                  <abbr className="details" title="Email">Mail us at</abbr>:
          <a className="details" href=" info@valueplus.io"> businus.com@gmail.com
        
            </a>
                </p>
                <p className="details" >
                  <abbr className="details" title="Hours">We are available from</abbr>: Monday to Friday, 10 AM to 6 PM
        </p>
              </div>
              {/*  <!-- Contact Form -->
    <!-- In order to set the email address and subject line for the contact form go to the bin/contact_me.php file. -->*/}
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 mb-4">
                  <h4 style={{fontSize:"1.3em"}}>Send us a Message</h4>
                  <div name="sentMessage" id="query" novalidate>

                    <input type="hidden" class="form-control1" id="subject" value="NA" required data-validation-required-message="Please enter your name." />
                    <div class="control-group form-group">
                      <div class="controls">
                        <label className='col-lg-8 col-md-8 col-sm-8 col-xs-8'> Title:</label>
                        <select onChange={this.onChange} value={this.state.title} className="form-control" name="title" placeholder="Type of entity">
                          <option selected disabled hidden>Select Title</option>
                          <option>Mr</option>
                          <option>Ms</option>
                          <option>Mrs</option>

                        </select>

                      </div>
                    </div>
                    <div class="control-group form-group">
                      <div class="controls">
                        <label className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>Name:</label>
                        <input type="text" class="form-control" value={this.state.name} onChange={this.onChange} name="name" id="name" required data-validation-required-message="Please enter your name." />
                        <p class="help-block"></p>
                      </div>
                    </div>
                    <div class="control-group form-group">
                      <div class="controls">
                        <label className='col-lg-8 col-md-8 col-sm-8 col-xs-8'> Name of your company:</label>
                        <input type="text" class="form-control"
                          onChange={this.onChange} name="company" value={this.state.company}
                          id="company" required data-validation-required-message="Please enter your name." />
                        <p class="help-block"></p>
                      </div>
                    </div>
                    <div class="control-group form-group">
                      <div class="controls">
                        <label className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>Contact No:</label>
                        <input type="number" class={`form-control ${this.errorClass(this.state.formErrors.phone)}`}
                          onChange={this.onChange} name="phone" value={this.state.phone}
                          id="phone" required data-validation-required-message="Please enter your phone number." />
                      </div>
                    </div>
                    <div class="control-group form-group">
                      <div class="controls">
                        <label className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>Email Address:</label>
                        <input type="email"
                          onChange={this.onChange} name="email"
                          class={`form-control ${this.errorClass(this.state.formErrors.email)}`} id="email" value={this.state.email} required data-validation-required-message="Please enter your email address." />
                      </div>
                    </div>
                    <div class="control-group form-group">
                      <div class="controls">
                        <label className='col-lg-8 col-md-8 col-sm-8 col-xs-8'>Your Message</label>
                        <textarea rows="10" cols="100"
                          onChange={this.onChange} name="message"
                          class="form-control" id="message" value={this.state.message} required data-validation-required-message="Please enter your message" maxlength="999" style={{ resize: "none" }}></textarea>
                      </div>
                    </div>
                    <div id="success"></div>
                    {/*For success/fail messages*/}
                    <button type="submit" onClick={this.submitForm} class="btn btn-primary" id="sendMessageButton" disabled={!this.isValidate()}>Send Message</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Rodal visible={this.state.success} height={100} className="pop-up" animation="flip" onClose={this.hide.bind(this)} >
          <div><p>Thank you! Your response has been recorded, we will get back to you shortly</p></div>
        </Rodal>
      </div>

    );
  }
}
