import React, { Component } from 'react';
import '../Navbar/navbarmain.css';
import './App.css'
import { Link } from 'react-router-dom';
import Topbar from '../Topbar/topbar';
import SideBar from '../Sidebar/SideBar';

export default class HomepageNavbarMain extends Component {
  constructor(props){
    super(props);
    this.state={
      openmenu:false,
    }
    
    this.closemenu=this.closemenu.bind(this)
    this.toggleMenu=this.toggleMenu.bind(this)
  }

  openmenu(){
    console.log()
    this.setState({openmenu:true,})
    }
    
    toggleMenu(){
          
      if(this.state.openmenu){
      
        this.setState({openmenu:false});
        
      }
      else{
        this.setState({openmenu:true});
        this.refs.child.setInitialState(this.props.subHead);
       
      }
    }
    
    closemenu(openmenu){
      if(openmenu){
        this.setState({openmenu:false,})
      }
    }

  render(){
    return(
      <div>
      <div className='khub-homepage-main-navbar'>
        <nav className=" navbar navbar-default modification" style={{marginBottom:'0px', paddingTop:'7px'}}>
          <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
          <div>
            <ul className="nav navbar-nav navbar-contained">
              {/* <button className='col-md-2 col-lg-2 col-sm-2 col-xs-2 khub_navbar_menuButton' > */}
            <li onClick={()=>this.toggleMenu()} style={{float:'left',cursor:'pointer',margin:'10px'}} 
            >
            <i class="fa fa-bars" aria-hidden="true"></i>
             {/* click me */}
             </li>
            {/* </button> */}
            
            <SideBar ref='child' stateOpen={this.state.openmenu} closeMenu={this.closemenu} type='' url='' />
          
            <li > 
            <div className='khub_hmp_company_logo'>
              <Link to ="/"> <img  className="businus-logo"src={require('../Training/images/businusSchool.png')}/> </Link>
              <div>{""}</div>
              <h5 className="subhead">{this.props.subHead}</h5>
              </div>
            </li>

                <li className='sub-cat hidden-xs hidden-sm' style={{cursor:'pointer'}}>
                     <Link to ="/legal-finance" className='highlight2'>Legal & Finance</Link>
                </li>
                <li className='sub-cat hidden-xs hidden-sm' style={{cursor:'pointer'}}>
                     <Link to ="/exim" className='highlight2'>EXIM</Link>
                </li>
                <li className='sub-cat hidden-xs hidden-sm' style={{cursor:'pointer'}}>
                     <Link to ="/stories-insights" className='highlight2'>Stories & Insights</Link>
                </li>
                <li className='sub-cat hidden-xs hidden-sm' style={{cursor:'pointer'}}>
                     <Link to ="/opportunity-growth" className='highlight2'>Opportunity & Growth</Link>
                </li>

              </ul>
          </div>
        </div>
         </nav>

         </div>
          <Topbar showBlogCategory={false}/>
        </div>
    );
  }
}
