import React from "react";
import Shimmer from "./Shimmer";
import SkeletonElement from "./SkeletonElement";

const SkeletonBlog = ()=>{
    return(
        <div>
        <div className="skeleton-wrapper light">
            <div className="skeleton-article">
                <SkeletonElement type="title"/>
                {[1,2,3].map((n)=> 
                <div className="skeleton-para">
                    <SkeletonElement type="sk-text" key={n}/>
                    <SkeletonElement type="sk-text" key={n}/>
                    <SkeletonElement type="sk-text" key={n}/>
                    <SkeletonElement type="sk-text" key={n}/>
                    <SkeletonElement type="sk-text" key={n}/>
                </div>
                )}
            </div>
            {/* <Shimmer/> */}
        </div>
        <div className="skeleton-wrapper light">
            <div className="skeleton-author">
            <div className="skeleton-profile">
            <div>
                <SkeletonElement type="thumbnail"/>
            </div>
            <div>
                <SkeletonElement type="title-ob"/>
                <SkeletonElement type="sk-text"/>
                <SkeletonElement type="sk-text"/>
            </div>
        </div>
                </div>
                {/* <Shimmer/> */}
            </div>
        </div>
    )
}

export default SkeletonBlog
