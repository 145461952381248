import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import './sidebar.css';


export default class SideBar extends Component{


constructor(props){

super();
this.state = {
legal:false,
exim:false,
wealth:false,
opportunity:false,
companyLaw:false,
gst:false,
custom:false,

}
}


setInitialState(page){
  if(page==="Legal & Finance"){
    this.setState({legal:true});
  }
  else if(page==="Exim"){
    this.setState({exim:true,});
  }

  else if(page==="Opportunity"){
    this.setState({opportunity:true,});
  }

  else if(page==="Wealth"){
    this.setState({wealth:true,});
  }

  else if(page==="CompanyLaw"){
    this.setState({companyLaw:true,legal:true});
  }

  else if(page==="Custom" ){
    this.setState({custom:true,legal:true});
  }

  else if(page==="GST"){
    this.setState({gst:true,legal:true});
  }


}

toggleMenu(state,menu){



         state ?  this.setState({[menu]:false}) : this.setState({[menu]:true});

    }





  render(){
    var open = {
        width: "290px"
      };

      var close={
        width: "0px"
      }

    return(
<div>
  
 <div id="mySidenav" style={this.props.stateOpen ? open : close} className="sidenav">
    <div className='khub_logo_container'>
      <a href="javascript:void(0)" className="closebtn" onClick={ () => this.props.closeMenu(this.props.stateOpen) } ><i className="fa fa-angle-left khub_Sidebar_cls_mn_btn"></i></a> <Link to="/" className="title-menu " value="productsMenu"><img className='khub_sidebar_logo_img' src={require("../Training/images/businusSchool.png")} /></Link>
    </div>
    <div>
    <a href="javascript:void(0)" value="legalMenu" onClick={ () => this.toggleMenu(this.state.legal,"legal") } id="legal" ><img className='image-law-1' src={require("../../Images//company_law.png")}/><span>Legal & Finance</span></a>
    <a href="javascript:void(0)" value="eximMenu" onClick={ () => this.toggleMenu(this.state.exim,"exim") } id="exim"><img className='image-law-1' src={require("../../Images//exim_icon.png")}/> <span>EXIM</span></a>
    <a href="javascript:void(0)" value="wealthMenu" onClick={ () => this.toggleMenu(this.state.wealth,"wealth") } id="wealth"><img className='image-law-1' src={require("../../Images//wealth_icon.png")}/><span>Stories & Insights</span></a>
    <a href="javascript:void(0)" value="opportunityMenu" onClick={ () => this.toggleMenu(this.state.opportunity,"opportunity") } id="opportunity"><img className='image-law-1' src={require("../../Images//opportunity_icon.png")}/><span>Opportunity & Growth </span></a>
    </div>
    </div>




  <div style={this.state.legal ? open : close} class="sidenav">


  <a href="javascript:void(0)" className="closebtn" onClick={ () => this.toggleMenu(this.state.legal,"legal") }><i className="fa fa-angle-left" ></i></a> <Link to="/"  value="productsMenu"><h3 className="title-menu">Legal & Finance</h3></Link>

    {/* <a href="javascript:void(0)" class="submenuItem" onClick={ () => this.toggleMenu(this.state.companyLaw,"companyLaw") }  value="companyLawmenu" id="companyLaw"><img className='image-law-1' src={require("../../Images//company_law.png")} style={{marginRight:'8px'}} />Company Law</a>
    <a href="javascript:void(0)" class="submenuItem" onClick={ () => this.toggleMenu(this.state.gst,"gst") }  value="gstmenu" id="gst"><img className='image-law-1' src={require("../../Images//gst-icon.png")} style={{marginRight:'8px'}} />GST </a>
    <a href="javascript:void(0)" class="submenuItem" onClick={ () => this.toggleMenu(this.state.custom,"custom") } value="custommenu" id="custom"><img className='image-law-1' src={require("../../Images//customs.png")} style={{marginRight:'8px'}} />Custom </a> */}
    <Link className="mainTabs" to='/legal-finance'><img className='image-law-1' src={require('../../Images//articles.png')}/> Articles </Link>
    </div>


   <div id="eximMenu" style={this.state.exim ? open : close} class="sidenav">


   <a href="javascript:void(0)" className="closebtn" onClick={ () => this.toggleMenu(this.state.exim,"exim") }><i className="fa fa-angle-left" ></i></a> <Link to="/"  value="productsMenu"><h3 className="title-menu">EXIM</h3></Link>

   <Link to='/exim' id="exim" className="heading3"><img className='image-law-1' src={require('../../Images//khub-article_icon.png')}/>Articles</Link>
    </div>

  <div id="wealthMenu" style={this.state.wealth ? open : close} class="sidenav">


<a href="javascript:void(0)" className="closebtn" onClick={ () => this.toggleMenu(this.state.wealth,"wealth") } ><i className="fa fa-angle-left" style={{fontSize:'30px',color:'grey',marginTop:'-5px',marginLeft:'20px'}}></i></a> <Link to="/" className="title-menu" value="docsMenu"><h3 className="title-menu">Stories & Insights</h3></Link>
<Link to='/stories-insights' id="wealth" className="heading3"><img className='image-law-1' src={require('../../Images//khub-article_icon.png')}/>Articles</Link>
  </div>


<div id="opportunityMenu" style={this.state.opportunity ? open : close} class="sidenav">


<a href="javascript:void(0)" className="closebtn" onClick={ () => this.toggleMenu(this.state.opportunity,"opportunity") } ><i className="fa fa-angle-left" ></i></a> <Link to="/" className="title-menu" value="resourcesMenu"><h3 className="title-menu">Oppportunity & Growth</h3></Link>

<Link to='/opportunity-growth' id="opportunity" className="heading3"><img className='image-law-1' src={require('../../Images//khub-article_icon.png')}/>Articles</Link>
 
</div>




{/* 
  <div id="companyLawMenu" style={this.state.companyLaw ? open : close}  class="sidenav">
  <a href="javascript:void(0)" className="back" onClick={ () => this.toggleMenu(this.state.companyLaw,"companyLaw") } className="closebtn"><i className="fa fa-angle-left" style={{fontSize:'30px',color:'grey',marginTop:'-5px',marginLeft:'20px'}}></i></a> <Link to="/" className="title-menu" value="productsMenu"><h3 className="title-menu">Company Law</h3></Link>

  <span><Link to='/company-law/case-law' id="company-law" className="heading3"><img className='image-law-1' src={require('../../Images//case-laws-icon.png')}/>Case Laws</Link></span>  
<span> <Link to='/company-law/acts' id="company-law" className="heading3"><img className='image-law-1' src={require('../../Images//acts-icon.png')}/>Acts</Link></span> 
<span> <Link to='/company-law/rules' id="company-law" className="heading3"><img className='image-law-1' src={require('../../Images//rules-icon.png')}/>Rules</Link></span> 
<span> <Link to='/company-law/circular-notification' id="company-law" className="heading3"><img className='image-law-1' src={require('../../Images//circulars-icon.png')}/>Circular & Notification</Link></span> 


  </div> */}

  {/* <div style={this.state.gst ? open : close} class="sidenav">
  <a href="javascript:void(0)" className="back" onClick={ () => this.toggleMenu(this.state.gst,"gst") } className="closebtn"><i className="fa fa-angle-left" style={{fontSize:'30px',color:'grey',marginTop:'-5px',marginLeft:'20px'}}></i></a> <Link to="/" className="title-menu" value="productsMenu"><h3 className="title-menu">GST</h3></Link>
  
  <span><Link to='/gst/case-law' id="gst" className="heading3"><img className='image-law-1' src={require('../../Images//case-laws-icon.png')}/>Case Laws</Link></span>  
<span> <Link to='/gst/acts' id="gst" className="heading3"><img className='image-law-1' src={require('../../Images//acts-icon.png')}/>Acts</Link></span> 
<span> <Link to='/gst/rules' id="gst" className="heading3"><img className='image-law-1' src={require('../../Images//rules-icon.png')}/>Rules</Link></span> 
<span> <Link to='/gst/circular-notification' id="gst" className="heading3"><img className='image-law-1' src={require('../../Images//circulars-icon.png')}/>Circular & Notification</Link></span> 


  

  </div> */}


  {/* <div style={this.state.custom ? open : close} class="sidenav">
  <a href="javascript:void(0)" className="back" onClick={ () => this.toggleMenu(this.state.custom,"custom") } className="closebtn"><i className="fa fa-angle-left" style={{fontSize:'30px',color:'grey',marginTop:'-5px',marginLeft:'20px'}}></i></a> <Link to="/"  value="productsMenu"><h3 className="title-menu">Custom</h3></Link>
  <span><Link to='/custom/case-law' id="custom" className="heading3"><img className='image-law-1' src={require('../../Images//case-laws-icon.png')}/>Case Laws</Link></span>  
<span> <Link to='/custom/acts' id="custom" className="heading3"><img className='image-law-1' src={require('../../Images//acts-icon.png')}/>Acts</Link></span> 
<span> <Link to='/custom/rules' id="custom" className="heading3"><img className='image-law-1' src={require('../../Images//rules-icon.png')}/>Rules</Link></span> 
<span> <Link to='/custom/circular-notification' id="custom" className="heading3"><img className='image-law-1' src={require('../../Images//circulars-icon.png')}/>Circular & Notification</Link></span> 


 
    </div> */}


  </div>





    );
  }
}
