import React, {Component} from 'react'
import axios from 'axios'
import NavbarMain from '../Navbar/navbarmain'
import './QnA.css';
// import Footer from "../Common/footer";
import AnswerReply from './answerReply';
import { apiBaseUrl } from '../apiUtils';

export default class DedicatedQnA extends Component {
    constructor(props){
        super(props)
        this.state= {
            questions:[],
            data:[],
            time:'',
            date:'',
            pic:"",
            // isQuestionReply:false,
            questionReply:'',
            key:'',
            questionReplyArray:[],
            answer:'',
            answerArray:[],
            answerReplyArray:[],
            answerReply:'',
            isAnswerReply:false,
            id:'',
            allQuestion:[],
            tagArray:[]
        }
        this.fetchById = this.fetchById.bind(this)
    }

    componentDidMount(){
        var urlArray = window.location.href.split('/')
        var id = urlArray[urlArray.length-1]
        var key = urlArray[urlArray.length-2]

        key = parseInt(key)

        this.setState({key:key,id:id})

        this.fetchById(id,key)
    }

    fetchById(id,key){
        var questions=[]
        var time =''
        var date =''
        var pic =''
        axios({
            url: (apiBaseUrl+'comment/get/'+id),
            method:'GET',
            crossDomain:true,
            response:'JSON',
        }).then((response)=>{
            var temp= response.data.questions
            for(var i=0; i<temp.length; i++){
                if(temp[i].questionKey===key){
                    questions=temp[i]
                    var d= new Date(temp[i].time)
                    time= d.toLocaleTimeString([],{hour:'2-digit',minute:'2-digit'})
                    date = d.toLocaleDateString([],{year:'numeric',month:'short',day:'numeric'})
                    pic = temp[i].pic
                }
            }
            this.setState({
                allQuestion:temp,
                questions:questions,
                // questionReplyArray:questions.reply, 
                data:response.data,
                time:time,
                date:date,
                answerArray:questions.answer,
                tagArray:questions.tags,
                // answerReplyArray:questions.answer.reply,
                pic:pic
                })
        })
    }

    // handleQuestionReply(){
    //     this.setState({isQuestionReply:!this.state.isQuestionReply})
    // }

    handleAnswerReply(){
        this.setState({isAnswerReply:!this.state.isAnswerReply})
    }

    questionReply(questionReply){
        this.setState({questionReply:questionReply})
    }

    answerReply(answerReply){
        this.setState({answerReply:answerReply})
    }

    // postQuestionReply(blankReply){
    //     if(sessionStorage.getItem("isLoggedIn")==="YES"){
            
    //         if(this.state.questionReply!=''){

    //             let allQuestion = []
    //             let questions = this.state.allQuestion
    //             var d = new Date()
    //             let time = d.toLocaleTimeString([],{hour:'2-digit',minute:'2-digit'})
    //             let date = d.toLocaleDateString([],{year:'numeric',month:'short',day:'numeric'})
    
    //             time = date +' at '+time
    
    //             for(var i=0; i< questions.length;i++){
    //                 if(questions[i].questionKey===this.state.key){
    //                     questions[i].reply.unshift({
    //                         questionReplykey:questions[i].reply.length+1,
    //                         pic:'A_logo',
    //                         name:'admin',
    //                         comment:this.state.questionReply,
    //                         time:time
    //                     })
    //                     allQuestion=questions
    //                     questions=questions[i]
    //                 }
    //             }
                 
                   
    
    //             this.setState({questions:questions,allQuestion:allQuestion})
    //             this.handleQuestionReply()
    //             this.questionReply(blankReply)
    //             this.update(allQuestion)
    //         }
    //     }
    //     else{
    //         alert("Please Login First")
    //     }

    // }

    postAnswerReply(blankReply,key){

        if(sessionStorage.getItem("isLoggedIn")==="YES"){
            if(this.state.answerReply!=''){
            
                let answerkey = parseInt(key)
                let questions = this.state.allQuestion
                let allQuestion =[]
                let answers = questions.answer
        
                var d = new Date()
                let time = d.toLocaleTimeString([],{hour:'2-digit',minute:'2-digit'})
                let date = d.toLocaleDateString([],{year:'numeric',month:'short',day:'numeric'})
        
                time = date+" at "+time
        
                for(var i=0; i<questions.length;i++){
                    var flag =questions[i].questionKey===this.state.key
                    if(questions[i].questionKey===this.state.key){
                        
                        for(var j=0; j<questions[i].answer.length; j++){                
                                if(questions[i].answer[j].answerkey===answerkey){
                                questions[i].answer[j].reply.unshift({
                                    answerReplykey:questions[i].answer[j].reply.length+1,
                                    pic:'A_logo',
                                    name:'admin',
                                    comment:this.state.answerReply,
                                    time:time
                                })
                            }
                        }
                        allQuestion=questions
                        questions=questions[i]
                    }
                }
                this.setState({questions:questions,allQuestion:allQuestion})
                this.handleAnswerReply()
                this.answerReply(blankReply)
                this.update(allQuestion)
                }
        }
        else{
            alert("Please Login First")
        }
        
    }

    // cancelQuestionReply(){
    //     this.setState({questionReply:'',isQuestionReply:false})
    // }

    cancelAnswerReply(){
        this.setState({answerReply:'',isAnswerReply:false})
    }

    answer(answer){
        this.setState({answer:answer})
    }

    postAnswer(blankReply,key){
        if(sessionStorage.getItem("isLoggedIn")==="YES"){
            if(this.state.answer!=''){

            let questions = this.state.allQuestion
            let allQuestion =[]
            
            var d = new Date()
            let time = d.toLocaleTimeString([],{hour:'2-digit',minute:'2-digit'})
            let date = d.toLocaleDateString([],{year:'numeric',month:'short',day:'numeric'})
    
            time = date +' at '+ time
            
            for(var i=0;i<questions.length;i++){
                if(questions[i].questionKey===key){
                    questions[i].answer.unshift({
                        answerkey:questions[i].answer.length+1,
                        pic:'A_logo',
                        name:'admin',
                        answer:this.state.answer,
                        time:time,
                        reply:[]
                })
                allQuestion=questions
                questions=questions[i]
            }
        }
                this.setState({questions:questions,allQuestion:allQuestion})
                this.answer(blankReply)
                this.update(allQuestion)
        }
    }
        else{
            alert("Please Login First")
        }
    }

    update(questions){
        let data ={
            questions: questions,
        }
        axios({
            url: (apiBaseUrl+'comment/update/'+this.state.id),
            method:'POST',
            data:data,
          }).then((response)=>{
             console.log(response.data)
          })
        //   this.fetchById(this.state.id,this.state.key)
    }


    render(){
        return(
            <div>
                <div>
                    <NavbarMain/>
                </div>
                <div className='questionAsked'>
                        <div className="dedicatedQuestion">
                            <div> {this.state.questions.question} </div>
                        
                        </div>

                        <div>
                        <div className="dedicatedDescription"> 
                        {this.state.questions.description} 
                        
                        {this.state.tagArray!==undefined?
                            <div style={{marginTop:'3%'}}>
                                {this.state.tagArray.map((tag)=>(
                                    <span className="tagsDedicatedClass">
                                        {tag}
                                    </span>
                                ))}
                            </div>:''
                        }
                        
                        
                        <div className='user-info'>
                            <div style={{fontSize:'12px',color:'grey'}}> asked {this.state.date} at {this.state.time} </div> 
                            <img style={{width:'22%',paddingRight:'3%'}} src={require('../../Images/alphabet-images/A_logo.png')} />
                            <span style={{fontSize:'15px',color:'#3c3cd4'}}>
                                {this.state.questions.name}
                            </span>
                        </div>
                        </div>

                        {/* <span className='questionTime' style={{color:'#687a86'}}> Asked </span>  <Moment style={{color:'#444647'}} className='questionTime' fromNow>{this.state.questions.time}</Moment>
                        {this.state.questionReplyArray!=[]?
                            this.state.questionReplyArray.map((reply,index)=>(
                                <div className='questionReply'>
                                    <span style={{marginLeft:'2%'}}>
                                        {reply.comment}</span>  -&nbsp;
                                       <a className='user-info-question-reply' style={{color:'blue'}}> {reply.name +' '} </a> 
                                       <span style={{fontSize:'12px',color:'grey'}}> {reply.time} </span> 
                                    
                                </div>
                            )):''
                            
                        } */}
                        

                        {/* {!this.state.isQuestionReply?
                            <div className='addCommentDedicated'>
                                <div style={{paddingBottom:"1%"}} onClick={()=> this.handleQuestionReply()}>Add a Comment </div>
                            </div>
                        :""} */}

                        {/* {this.state.isQuestionReply?
                            <div style={{paddingLeft:'2%'}}>
                                <textarea className='form-control' style={{width:'88%'}} placeholder='add a comment...' name='reply' onChange={event=>this.questionReply(event.target.value)}>
                                </textarea>
                                <div style={{marginTop:'1%'}}>
                                <button style={{marginRight:'1%'}} className="btn btn-primary mr-3" onClick={()=> this.postQuestionReply('',this.state.key)}> Post a Comment </button>
                                <button className="btn btn-warn mr-3" onClick={()=>this.cancelQuestionReply('')}> Cancel </button></div>
                            </div>:''
                        } */}

                        </div>

                        <div style={{paddingTop:'2%',paddingBottom:'2%'}} className="dedicatedAnswers">
                           {this.state.answerArray.length} Answers
                        </div>

                        
                        {this.state.answerArray!=[]?
                        this.state.answerArray.map((answer,index)=>(
                           <div className='answer'>
                                <div style={{minHeight:'16vh',height:'auto',paddingLeft:'3%'}}>
                                    {answer.answer}

                                    <div className='user-info-answer'> 
                                    <div style={{fontSize:'12px',color:'grey'}}> answered {answer.time} </div> 
                                    <img style={{width:'22%',paddingRight:'3%'}} src={require('../../Images/alphabet-images/A_logo.png')} />
                                    <span style={{fontSize:'15px',color:'#3c3cd4'}}>
                                        {answer.name}
                                    </span>
                                </div> 

                                </div>  

                                {answer.reply!=[]?
                                    <AnswerReply       
                                    answer={answer.reply}
                                    />
                                :""}
                                
                                

                                {/* {answer.reply!=[]?
                                answer.reply.map((answerReply,index)=>(
                                    <div className='questionReply'>
                                        <span style={{marginLeft:'2%'}}>
                                            {answerReply.comment}</span>  -&nbsp;
                                            <a className='user-info-question-reply' style={{color:'blue'}}> {answerReply.name +' '} </a> 
                                       <span style={{fontSize:'12px',color:'grey'}}> {answerReply.time} </span> 
                                    
                                    </div>
                                ))
                                :''} */}


                                {/* {this.state.isMoreAnswerComment?
                                    <div onClick={()=>this.moreAnswerComment()}>
                                        Show more comments
                                    </div>:''
                                }

                                {this.state.isLessAnswerComment?
                                    <div onClick={()=>this.lessAnswerComment()}>
                                        hide comment
                                    </div>:""

                                } */}


                                {!this.state.isAnswerReply?
                                    <div className='addComment'>
                                        <div style={{paddingBottom:"1%"}} onClick={()=> this.handleAnswerReply()}>add a comment </div>
                                    </div>:""
                                }

                                {this.state.isAnswerReply?
                                    <div style={{paddingLeft:'2%'}}>
                                        <textarea className='form-control' style={{width:'88%'}} placeholder='add a comment...' name='answerReply' onChange={event=>this.answerReply(event.target.value)}>
                                        </textarea>
                                        <div style={{marginTop:'1%'}}>
                                        <button style={{marginRight:'1%'}} className="btn btn-primary mr-3" onClick={()=> this.postAnswerReply('',answer.answerkey)}> Post a Comment </button>
                                        <button className="btn btn-warn mr-3" onClick={()=>this.cancelAnswerReply('')}> Cancel </button></div>
                                    </div>:''
                                }
                            </div>
                        )):''
                        }

                    <div className='your-answer' style={{paddingTop:'2%',paddingBottom:'2%'}}>
                        Your Answer
                    </div>

                    <div>
                        <textarea className='form-control' style={{width:'94%',marginBottom:'1%'}} name='answer' value={this.state.answer} onChange={event=>this.answer(event.target.value)}></textarea>
                    </div>

                <button style={{marginRight:'1%'}} className="btn btn-primary mr-3" onClick={()=>this.postAnswer('',this.state.key)}>Post Your Answer</button>
                <button className="btn btn-warn mr-3" onClick={()=>this.answer('')}>Cancel</button>

            </div>
            
            {/* <Footer /> */} 
        </div>

        )
    }
}