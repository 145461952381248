import React, { Component } from "react";
import axios from "axios";
import './App.css';
import Footer from "../Common/footer";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import _ from 'underscore';
import Truncate from "react-truncate";
import linkedIn from './images/linkedIn-flat-img.png';
import Youtube from './images/youtube-round-img.png';
import Instagram from './images/Instagram_img.png';
import Facebook from './images/faceook-round-img.png';
import Twitter from './images/Twitter-flat-img.png';
import HomepageNavbarMain from "./HomepageNavbar";
import { apiBaseUrl, apiBaseUrl1} from "../apiUtils";

export default class Homepage extends Component{

    constructor(props){
        super(props)
        this.state={
            name:'',
            email:'',
            resData:[],
            resDataNavbar:[],
            resDataCard:[],
            resCategory:[],
            legalFinance:[],
            exim:[],
            wealth:[],
            opportunity:[],
            filterCategory:[],
            categoryType:'legal-finance',
            tagArray:[],
            verticalSlider:[],
            horizontalSlider:[]
        }
    }
    componentDidMount(){
        this.fetchAllData()
        this.fetchAllCategory()
        this.fetchSlider()
    }
fetchAllData(){
    let tempNavbar=[]
    let tempCard=[]
    let tag=[]
    return axios({
        url: (apiBaseUrl+'blog/list'),
        method: 'GET',
        crossDomain: true,
        responseType: 'json', // important
      }).then((response) => {
        for(var i=0;i<response.data.length;i++){
            if(response.data[i].homepageNavbar){
                tempNavbar.push(response.data[i])
            }
            if(response.data[i].homepageCard){
                tempCard.push(response.data[i])
            }
            if(response.data[i].tags.length>0 && response.data[i].tags!==undefined){
                for(var j=0;j<response.data[i].tags.length;j++){
                    if(response.data[i].tags[j].trim()!==''){
                        tag.push(response.data[i])
                    }
                }
            }
            // tag=_.union(tag,response.data[i].tags)
        }
        let legalFinance = _.filter(response.data,function(data){
            return data.learningCategory=='legal-finance'
        })

        let exim = _.filter(response.data,function(data){
            return data.learningCategory=='exim'
        })

        let wealth = _.filter(response.data,function(data){
            return data.learningCategory=='stories-insights'
        })

        let opportunity = _.filter(response.data,function(data){
            return data.learningCategory=='opportunity-growth'
        })
        this.setState({
            resDataNavbar:tempNavbar,
            resDataCard:tempCard,
            legalFinance:legalFinance.reverse().splice(0,4),
            exim:exim.reverse().splice(0,4),
            wealth:wealth.reverse().splice(0,4),
            opportunity:opportunity.reverse().splice(0,4),
            // tagArray:tag.splice(0,20),
            resData:tag.splice(0,10),
        })
      })
}

fetchAllCategory(){
    return axios({
        url: (apiBaseUrl+'blogCategory/list'),
        method: 'GET',
        crossDomain: true,
        responseType: 'json', // important
      }).then((response) => {
        this.setState({resCategory:response.data})
        this.changeEntity('legal-finance')
      })
}

fetchSlider(){
    let verticalData=[]
    let horizontalData=[]
    return axios({
        url: (apiBaseUrl1+'homepageSlider/list'),
        method: 'GET',
        crossDomain: true,
        responseType: 'json', // important
      }).then((response) => {
          for(var i=0;i<response.data.length;i++){
              if(response.data[i].sliderType==='horizontal' && response.data[i].displaySlider){
                  horizontalData.push(response.data[i])
              }
              else if(response.data[i].displaySlider){
                  verticalData.push(response.data[i])
              }
          }
        this.setState({verticalSlider:verticalData,horizontalSlider:horizontalData})
      })
}

    returnCardCategory(cat){
        for(var i=0;i<this.state.resCategory.length;i++){
            if(this.state.resCategory[i].id===cat){
                return(this.state.resCategory[i].category)
            }
        }
    }

    changeEntity=(learningCategory)=>{
        var filterCat=[]
        let resCategory = this.state.resCategory
        for(var i=0;i<resCategory.length;i++){
            if(resCategory[i].learningCategory===learningCategory){
                filterCat.push(resCategory[i])
            }
        }
        
        document.getElementById(learningCategory).classList.add('khub-homepage-c-header-active')
        if(this.state.categoryType!==''){
            document.getElementById(this.state.categoryType).classList.remove('khub-homepage-c-header-active')
        }
        this.setState({filterCategory:filterCat,categoryType:learningCategory})
    }

    onClickVerticalItem=(i)=>{
        let url = this.state.verticalSlider[i].sliderUrl
        // if(url.split('//')[0]!=='https:'){
        //     url='https://'+url
        // }
        window.open(url,"_blank")
    }
    
    onClickHorizonatalItem=(i)=>{
        let url = this.state.horizontalSlider[i].sliderUrl
        // if(url.split('//')[0]!=='https:'){
        //     url='https://'+url
        // }
        window.open(url,"_blank")
    }

    filterCategory=(categoryId)=>{
        let blogType= this.state.categoryType

        window.location.replace(blogType+'?cl='+categoryId)
    }

    filterTag=(blogType,tag)=>{
        
        // let blogType= this.state.categoryType

        window.location.replace(blogType+'?tag='+tag)
    }

    handleChange(e){
        let target = e.target

        this.setState({[target.name]:target.value})
    }

    submitStayUpdated(){
        let InwardData={
            name:this.state.name,
            email:this.state.email
        }
        let flag=true
        if(this.state.name.trim()===''){
            flag=false
            alert('Please enter your name')
        }
        else if(this.state.email.trim()===''){
            flag=false
            alert('Please enter your email address')
        }

        if(flag){
            axios({
                url: (apiBaseUrl1+'stayUpdated/add'), 
                method: 'POST',
                data: InwardData
              }).then((response) => {
                if(response.status===200){
                  alert("Your details are submitted successfully!!");
                  this.setState({name:'',email:""})
                }
             });
        }
    }
    
    routeToDedicatedPage(blogId,heading,learningCategory){
        // sessionStorage.setItem('blogPrimaryId',blogId,heading)
        
        let urlHeading = heading.replaceAll(' ','_')
        urlHeading = urlHeading.trim('_')
        let urlBlogId = blogId.split('-').pop()
        let urlBlg=urlHeading+'_'+urlBlogId
        // console.log('route url:',urlHeading)
        window.location.replace('/'+learningCategory+'/resources?blg='+urlBlg)
    }

    routeToSourceUrl(sourceUrl){
        window.location.replace(sourceUrl)
    }
  render(){
      const {legalFinance,exim,wealth,opportunity} = this.state
  return (
    <div className="App" style={{overflowX:'hidden'}}>

<HomepageNavbarMain/>

    <div className="row" style={{marginTop:'108px'}}>
        <div className="row khub-homepage-navbar">

        <div>
        {/* <div className="khub_homepage_topbar_cat">
            {this.state.resCategory.map((cat)=>(
                <a>{cat.category}</a>
            ))}
        </div> */}
        </div>

       {/*}     <div className="col-md-12 col-lg-12 hidden-xs hidden-sm" style={{padding:'10px 50px 0px 25px'}}>
                {this.state.resDataNavbar.map((nav)=>(
                <div className="col-md-2 col-lg-2" style={{padding:'8px 0'}}>
                    <div className="navbar-item-thumbnail col-md-4 col-lg-4">
                        <a href={nav.blogType==='curated'?nav.sourceUrl:nav.learningCategory+'/resources/'+nav.id} title={nav.heading}>
                            <img className="navbar-item-img" src={nav.blogImageURL}/>
                        </a>
                    </div>
                    <h6 className="col-md-8 col-lg-8" style={{paddingRight:'0'}}><a href={nav.blogType==='curated'?nav.sourceUrl:nav.learningCategory+'/resources/'+nav.id}> {nav.heading} </a></h6>
                </div>
                ))}

                </div>*/}

            <div className="row hidden-sm hidden-xs" style={{margin:"0 40px",padding:'12px 0px'}}>
              <div style={{display:'flex'}} className="scroll-nav">
                {this.state.resDataNavbar.map((nav) => (
                  <div
                    className="circular-card"
                    style={{ display:'flex',padding: "8px 0" }}
                  >
                    <div>
                      <a
                        // href={
                        //   nav.blogType === "curated"
                        //     ? nav.sourceUrl
                        //     : nav.learningCategory + "/resources/" + nav.id
                        // }
                        title={nav.heading}
                      >
                        <img
                          className="navbar-item-img"
                          src={nav.blogImageURL}
                        />
                      </a>
                    </div>
                    <h6
                      className="scroll-nav-text"
                      style={{ 
                        wordBreak:'break-word',
                        minWidth: '180px',
                        paddingLeft: '9px'}}
                    >
                      <a
                        // href={
                        //   nav.blogType === "curated"
                        //     ? nav.sourceUrl
                        //     : nav.learningCategory + "/resources/" + nav.id
                        // }
                      >
                        
                        {nav.heading}
                      </a>
                    </h6>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="" style={{ padding: "0 2%" }}>

{/* <div className="khub-fa-list khub-fa-list-image-gradient  col-md-12 col-lg-12">
  {this.state.resDataCard.map((card) => (
    <div className="col-md-3 col-lg-3" style={{ padding: "0" }}>
      <article className="khub-homepage-article">
        {" "}
        <header className="khub-entry-header">
          <span className="khub-homepage-category">
          <a style={{color:'#fff'}}> {this.returnCardCategory(card.category)} </a>
          </span>
          <h2 className="khub-homepage-title">
          <a href={card.blogType==='curated'?card.sourceUrl:card.learningCategory+'/resources/'+card.id} style={{color:'#fff'}}> {card.heading} </a></h2> 
          <div className="khub-homepage-meta">
            <div className="khub-homepage-date">
              <span> {card.datePosted} </span>
            </div>
            <div className="khub-homepage-rt">
            {card.readTime} mins Read 
            </div>
          </div>
        </header>
        <a className="khub-homepage-card-thumbnail">
          <img
            style={{ background:"linear-gradient(to bottom, Transparente 0%,Transparente 50%,red 50%,red 100%)", width: "100%", height: "100%" }}
            src={card.blogImageURL}
          />
        </a>
      </article>
    </div>
  ))}
</div> */}

           <div>
                <div className="khub-fa-list col-md-12 col-lg-12">
                    {this.state.resDataCard.map((card)=>(
                    <div className="col-md-3 col-lg-3">
                    <article className="khub-homepage-article"> <header className="khub-entry-header">
                        <span className="khub-homepage-category"><a style={{color:'#fff'}}> {this.returnCardCategory(card.category)} </a></span>   
                        <h2 className="khub-homepage-title">
                            <a style={{color:'#fff'}}> 
                        <p onClick={()=>card.blogType==='curated'?this.routeToSourceUrl(card.sourceUrl):this.routeToDedicatedPage(card.blogId,card.heading,card.learningCategory)}> {card.heading}</p> 
                        </a>
                        </h2> 
                        <div className="khub-homepage-meta"> 
                            <div className="khub-homepage-date"><span> {card.datePosted} </span></div>
                            <div className="khub-homepage-rt"> {card.readTime} mins Read </div>
                        </div>
                    </header>
                    <a className="khub-homepage-card-thumbnail">
                        <img src={card.blogImageURL} />
                    </a>
                    </article>
                    </div>
                    ))}
                </div>
                    </div>

            <div style={{padding:'0 2%'}}>
                <div className="khub-card-container col-md-12 col-lg-12">
                <div className="col-md-9 col-lg-9">
                    <div className="col-md-6">
                        <div className="khub-card">
                            <div className="khub-card-content-legal">
                            <div className="khub-card-category-legal"> <h4> Legal & Finance </h4> <span style={{float:'right',padding:'18px'}}> <a href={'/legal-finance'}> View All </a></span> </div>
                            {legalFinance[0]!==undefined?
                            <div>
                            <div className="khub-card-top-ar">
                            {legalFinance[0].blogImageURL!==undefined?<a> <img style={{width:'100%',height:'40vh'}} src={legalFinance[0].blogImageURL}/> </a>:""}
                                <div className="khub-card-top-ar-cont">
                                <div className="khub-card-top-ar-header-legal"> 
                                <a> 
                                    <p onClick={()=>legalFinance[0].blogType==='curated'?this.routeToSourceUrl(legalFinance[0].sourceUrl):this.routeToDedicatedPage(legalFinance[0].blogId,legalFinance[0].heading,legalFinance[0].learningCategory)}>{legalFinance[0].heading}</p>
                                </a> </div>
                                <div className="khub-card-top-ar-date"> {legalFinance[0].datePosted} | {legalFinance[0].readTime} mins Read</div>
                                <div className="khub-card-top-ar-des">
                                <Truncate lines={2} ellipsis ={<span>...</span>}>
                                <p dangerouslySetInnerHTML={{__html:legalFinance[0].truncateContent}}></p>
                                </Truncate>
                                </div>
                                </div>
                            </div>
                            {legalFinance.splice(1,4).map((legal)=>(
                                <article >
                                    <div className="col-md-12 col-lg-12 khub-card-latest-ar">
                                    <a className="col-md-2 col-lg-2"> <img className="khub-card-latest-ar-img" src={legal.blogImageURL}/> </a>
                                    <div className="khub-card-latest-ar-heading">
                                    <a>
                                        <p onClick={()=>legal.blogType==='curated'?this.routeToSourceUrl(legal.sourceUrl):this.routeToDedicatedPage(legal.blogId,legal.heading,legal.learningCategory)}> {legal.heading} </p>
                                    </a> </div>
                                    <div className="khub-card-top-ar-date"> {legal.datePosted} | {legal.readTime} mins Read</div>
                                    </div>
                                </article>
                            ))}
                            </div>:''}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="khub-card">
                            <div className="khub-card-content-exim">
                            <div className="khub-card-category-exim"> <h4> EXIM </h4> <span style={{float:'right',padding:'18px'}} > <a href={'/exim'}> View All </a></span> </div>
                            {exim[0]!==undefined?
                            <div>
                            <div className="khub-card-top-ar">
                            {exim[0].blogImageURL!==undefined?<a> <img style={{width:'100%',height:'40vh'}} src={exim[0].blogImageURL}/> </a>:''}
                                <div className="khub-card-top-ar-cont">
                                <div className="khub-card-top-ar-header-exim"> 
                                    <a>
                                    <p onClick={()=>exim[0].blogType==='curated'?this.routeToSourceUrl(exim[0].sourceUrl):this.routeToDedicatedPage(exim[0].blogId,exim[0].heading,exim[0].learningCategory)}> {exim[0].heading} </p>
                                    </a> </div>
                                <div className="khub-card-top-ar-date"> {exim[0].datePosted} | {exim[0].readTime} mins Read</div>
                                <div className="khub-card-top-ar-des">
                                <Truncate lines={2} ellipsis ={<span>...</span>}>
                                <p dangerouslySetInnerHTML={{__html:exim[0].truncateContent}}></p>
                                </Truncate>
                                </div>
                                </div>
                            </div>
                            {exim.splice(1,4).map((exim)=>(
                                <article >
                                    <div className="col-md-12 col-lg-12 khub-card-latest-ar">
                                    <a className="col-md-2 col-lg-2"> <img className="khub-card-latest-ar-img" src={exim.blogImageURL}/> </a>
                                    <div className="khub-card-latest-ar-heading"> 
                                    <a> 
                                        <p onClick={()=>exim.blogType==='curated'?this.routeToSourceUrl(exim.sourceUrl):this.routeToDedicatedPage(exim.blogId,exim.heading,exim.learningCategory)}> {exim.heading} </p>
                                    </a> </div>
                                    <div className="khub-card-top-ar-date"> {exim.datePosted} | {exim.readTime} mins Read</div>
                                    </div>
                                </article>
                            ))}
                            </div>:''}
                            </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-3 col-lg-3">
                        <div className="khub-slider-card-hr">
                            <Carousel autoPlay={true} onClickItem={this.onClickVerticalItem}>
                                {this.state.verticalSlider.map((ver)=>(
                                    <img src={ver.sliderImageUrl}/>
                                ))}
                            </Carousel>
                        </div>
                        {/* Below two section is for larger screen only. On small screen this will get disable and code at the last will get enable */}
                        <div className="khub-slider-card-cont-us hidden-xs hidden-sm">
                            <h4 className="khub-slider-cont-heading"> Connect With Us </h4>
                            <ul className="khub-slider-cont-list">
                                <li title="LinkedIn"><a target='_blank' href='https://www.linkedin.com/company/businusdotcom/'><img src={linkedIn}/></a></li>
                                <li title="Youtube"><a target='_blank' href='https://www.youtube.com/channel/UCyuXLlNdLhlgF97f89yYrpg'><img src={Youtube}/></a></li>
                                <li title="Instagram"><a target='_blank' href='https://www.instagram.com/businusdotcom/'><img src={Instagram}/></a></li>
                                <li title="Facebook"><a target='_blank' href='https://www.facebook.com/businusdotcom'><img src={Facebook}/></a></li>
                                <li title="Twitter"><a target='_blank' href='https://twitter.com/businusdotcom'><img src={Twitter}/></a></li>
                            </ul>
                        </div>
                        <div className="khub-slider-card-st-upd hidden-xs hidden-sm">
                            <h4 className="khub-slider-cont-heading"> Stay Updated </h4>
                            <div>
                                <div><input className='khub-form-input' name="name" value={this.state.name} onChange={e=>this.handleChange(e)} placeholder="Name"/></div>
                                <div><input className='khub-form-input' name="email" value={this.state.email} onChange={e=>this.handleChange(e)} placeholder="Email"/></div>
                                <button className="khub-cont-sub" onClick={()=>this.submitStayUpdated()}> Submit </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="khub-card-container col-md-12 col-lg-12">
                <div className="col-md-9 col-lg-9">
                
                <div className="col-md-12 col-lg-12 hidden-xs hidden-sm">
                    <div className="khub-slider-card-vr">
                    <Carousel autoPlay={true} dynamicHeight={true} onClickItem={this.onClickHorizonatalItem} showThumbs={false}>
                        {this.state.horizontalSlider.map((hr)=>(
                            <img src={hr.sliderImageUrl}/>
                        ))}
                    </Carousel>
                    </div>
                </div>
                <div className="col-md-6">
                        <div className="khub-card">
                            <div className="khub-card-content-wealth">
                            <div className="khub-card-category-wealth"> <h4> Stories & Insights </h4> <span style={{float:'right',padding:'18px'}}> <a href={'/stories-insights'}> View All </a></span> </div>
                            {wealth[0]!==undefined?
                            <div>
                            <div className="khub-card-top-ar">
                            {wealth[0].blogImageURL!==undefined?<a> <img style={{width:'100%',height:'40vh'}} src={wealth[0].blogImageURL}/> </a>:""}
                                <div className="khub-card-top-ar-cont">
                                <div className="khub-card-top-ar-header-wealth"> <a>
                                    <p onClick={()=>wealth[0].blogType==='curated'?this.routeToSourceUrl(wealth[0].sourceUrl):this.routeToDedicatedPage(wealth[0].blogId,wealth[0].heading,wealth[0].learningCategory)}>{wealth[0].heading}</p> </a> </div>
                                <div className="khub-card-top-ar-date"> {wealth[0].datePosted} | {wealth[0].readTime} mins Read</div>
                                <div className="khub-card-top-ar-des">
                                <Truncate lines={2} ellipsis ={<span>...</span>}>
                                <p dangerouslySetInnerHTML={{__html:wealth[0].truncateContent}}></p>
                                </Truncate>
                                </div>
                                </div>
                            </div>
                            {wealth.splice(1,4).map((wealth)=>(
                                <article >
                                    <div className="col-md-12 col-lg-12 khub-card-latest-ar">
                                    <a className="col-md-2 col-lg-2"> <img className="khub-card-latest-ar-img" src={wealth.blogImageURL}/> </a>
                                    <div className="khub-card-latest-ar-heading"> <a>
                                        <p onClick={()=>wealth.blogType==='curated'?this.routeToSourceUrl(wealth.sourceUrl):this.routeToDedicatedPage(wealth.blogId,wealth.heading,wealth.learningCategory)}>{wealth.heading} </p>
                                    </a> </div>
                                    <div className="khub-card-top-ar-date"> {wealth.datePosted} | {wealth.readTime} mins Read</div>
                                    </div>
                                </article>
                            ))}
                            </div>:''}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="khub-card">
                            <div className="khub-card-content-opportunity">
                            <div className="khub-card-category-opportunity"> <h4> Opportunity & Growth </h4> <span style={{float:'right',padding:'18px'}}> <a href={'/opportunity-growth'}> View All </a></span> </div>
                            {opportunity[0]!==undefined?
                            <div>
                            <div className="khub-card-top-ar">
                            {opportunity[0].blogImageURL!==undefined?<a> <img style={{width:'100%',height:'40vh'}} src={opportunity[0].blogImageURL}/> </a>:''}
                                <div className="khub-card-top-ar-cont">
                                <div className="khub-card-top-ar-header-opportunity"> 
                                <a> 
                                    <p onClick={()=>opportunity[0].blogType==='curated'?this.routeToSourceUrl(opportunity[0].sourceUrl):this.routeToDedicatedPage(opportunity[0].blogId,opportunity[0].heading,opportunity[0].learningCategory)}>{opportunity[0].heading} </p>
                                </a> </div>
                                <div className="khub-card-top-ar-date"> {opportunity[0].datePosted} | {opportunity[0].readTime} mins Read</div>
                                <div className="khub-card-top-ar-des">
                                <Truncate lines={2} ellipsis ={<span>...</span>}>
                                <p dangerouslySetInnerHTML={{__html:opportunity[0].truncateContent}}></p>
                                </Truncate>
                                </div>
                                </div>
                            </div>
                            {opportunity.splice(1,4).map((opportunity)=>(
                                <article >
                                    <div className="col-md-12 col-lg-12 khub-card-latest-ar">
                                    <a className="col-md-2 col-lg-2"> <img className="khub-card-latest-ar-img" src={opportunity.blogImageURL}/> </a>
                                    <div className="khub-card-latest-ar-heading"> <a>
                                        <p onClick={()=>opportunity.blogType==='curated'?this.routeToSourceUrl(opportunity.sourceUrl):this.routeToDedicatedPage(opportunity.blogId,opportunity.heading,opportunity.learningCategory)}> {opportunity.heading} </p>
                                    </a> </div>
                                    <div className="khub-card-top-ar-date"> {opportunity.datePosted} | {opportunity.readTime} mins Read</div>
                                    </div>
                                </article>
                            ))}
                            </div>:''}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-12 visible-xs visible-sm">
                    <div className="khub-slider-card-vr">
                    <Carousel autoPlay={true} dynamicHeight={true} onClickItem={this.onClickHorizonatalItem} showThumbs={false}>
                        {this.state.horizontalSlider.map((hr)=>(
                            <img src={hr.sliderImageUrl}/>
                        ))}
                    </Carousel>
                    </div>
                </div>
                </div>


                    <div className="col-md-3 col-lg-3" style={{padding:'0px'}}>
                        <div className="col-md-12 col-lg-12 hidden-sm hidden-xs" >
                            <div className="khub-homepage-c-card">
                                <div className="khub-homepage-c-header">
                                    <ul className="col-md-12 col-lg-12" style={{padding:'0px'}}>
                                        <li className="col-md-3 col-lg-3" id='legal-finance' onClick={()=>this.changeEntity('legal-finance')}> Legal & Finance </li>
                                        <li className="col-md-3 col-lg-3" id='exim' onClick={()=>this.changeEntity('exim')}> Exim </li>
                                        <li className="col-md-3 col-lg-3" id='stories-insights' onClick={()=>this.changeEntity('stories-insights')}> Stories & Insights </li>
                                        <li className="col-md-3 col-lg-3" id='opportunity-growth' onClick={()=>this.changeEntity('opportunity-growth')}> Opportunity & Growth </li>
                                    </ul>
                                    <div>
                                        <ul style={{listStyle:'none'}}>
                                        {this.state.filterCategory.map((cat)=>(
                                            <li onClick={()=>this.filterCategory(cat.category)}> <h4 onClick={()=>this.filterCategory(cat.category)} className="sidetag"> {cat.category}</h4> </li>
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12">
                            <div className="khub-homepage-t-card">
                            <h4 className="heading9"> Tags </h4>
                            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            {this.state.resData.map((data)=>(
                                data.tags.length>0?
                                data.tags.map((tag)=>(
                                    tag.trim()!==''?
                                <button className='button99 col-md-3 col-lg-3 col-sm-3 col-xs-3' ><h4 className="heading24" onClick={()=>this.filterTag(data.learningCategory,tag)}>{tag}</h4></button>    :''
                                )):""
                            ))}
                            {/* {this.state.tagArray.map((tag,) => (
                                tag.trim()!==''?
                                // <button className='button99 col-md-3 col-lg-3 col-sm-3 col-xs-3' style={{margin:'4px 12px'}}><h4 className="heading24" onClick={()=>this.filterTag(tag)}>{tag}</h4></button>    :''
                                <button className='button99 col-md-3 col-lg-3 col-sm-3 col-xs-3' ><h4 className="heading24" onClick={()=>this.filterTag(tag)}>{tag}</h4></button>    :''
                                
                               ))} */}
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12" style={{padding:'0 64px 0 52px'}}>
            <div className="khub-slider-card-cont-us visible-xs visible-sm">
                            <h4 className="khub-slider-cont-heading"> Connect With Us </h4>
                            <ul className="khub-slider-cont-list">
                                <li title="LinkedIn"><a target='_blank' href='https://www.linkedin.com/company/businusdotcom/'><img src={linkedIn}/></a></li>
                                <li title="Youtube"><a target='_blank' href='https://www.youtube.com/channel/UCyuXLlNdLhlgF97f89yYrpg'><img src={Youtube}/></a></li>
                                <li title="Instagram"><a target='_blank' href='https://www.instagram.com/businusdotcom/'><img src={Instagram}/></a></li>
                                <li title="Facebook"><a target='_blank' href='https://www.facebook.com/businusdotcom'><img src={Facebook}/></a></li>
                                <li title="Twitter"><a target='_blank' href='https://twitter.com/businusdotcom'><img src={Twitter}/></a></li>
                            </ul>
                        </div>
            </div>
        </div>
    </div>

<div style={{marginTop:'-50px'}}><Footer/></div>

    
    </div>
  );
}

}


