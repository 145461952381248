import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import "./footer.css";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {apiBaseUrl1} from '../apiUtils';
import linkedIn from '../Homepage/images/linkedIn-flat-img.png';
import Youtube from '../Homepage/images/youtube-round-img.png';
import Instagram from '../Homepage/images/Instagram_img.png';
import Facebook from '../Homepage/images/faceook-round-img.png';
import Twitter from '../Homepage/images/Twitter-flat-img.png';
import axios from "axios";

export default class Footer extends Component {

  constructor(props){
    super();
    this.state={
email:"",
visible:false


    };
    this.submitForm = this.submitForm.bind(this);
  }

  // submitForm(e){
  //   this.setState({visible:false});
  //   e.preventDefault();
  //   var api = apiBaseUrl+"businusquery/query";
  //   $.ajax({
  //     url:  api,
  //    method: 'GET',
  //    data: {
      
  //     email: this.state.email,
     
  //     type:0
  //   },
  //     success: function(data) {
        
  //       this.setState({visible:true});
  //       }.bind(this),
  //     error: function(xhr, status, err) {
        
  //     }.bind(this)
  //   });
  // }

  submitForm(){
    let InwardData={
      name:'',
      email:this.state.email
  }
  let flag=true
  if(this.state.email.trim()===''){
      flag=false
      alert('Please enter your email address')
  }
  if(flag){
      axios({
          url:  (apiBaseUrl1+'stayUpdated/add'), 
          method: 'POST',
          data: InwardData
        }).then((response) => {
          if(response.status===200){
            this.setState({email:"",visible:true})
          }
       });
  }
  }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);

  }
  hide() {
    this.setState({ visible: false });
}
  
 render(){
   return(
<div>
<div className="footer big hidden-xs">
  <div className="container">
    <div className="row">
     

      </div>

    <div className="row">
      <div className="col-lg-3 col-sm-2">
      <h4 className="title-widget" id="footer-head">Company</h4>
        <ul>
          <div className = "footer-item"><Link id="footer-item" className = "footer-item"  to="/about">About us</Link></div>
          <div className = "footer-item"><Link id="footer-item" className = "footer-item"  to="/contact-us">Contact us</Link></div>
          <div className = "footer-item"><Link id="footer-item" className = "footer-item"  to="/work-with-us">Work with us</Link></div>
          <div className = "footer-item"><Link id="footer-item" className = "footer-item"  to="/site-map">Site Map</Link></div>
          
        </ul>
      </div>

     <div className="col-lg-3 col-sm-2 social-media-h-f">
        <h4 id="footer-head">Find Us</h4>
        <a href="https://www.facebook.com/businusdotcom" target="_blank"> <img src={Facebook}/> </a>
        <a href="https://twitter.com/businusdotcom" target="_blank"> <img src={Twitter}/> </a>
        <a href="https://www.linkedin.com/company/businusdotcom/" target="_blank"> <img src={linkedIn}/> </a>
        <a href="https://www.youtube.com/channel/UCyuXLlNdLhlgF97f89yYrpg" target="_blank"> <img src={Youtube}/> </a>
        <a href="https://www.instagram.com/businusdotcom/" target="_blank"> <img src={Instagram}/> </a>
      </div>

      <div className="col-lg-3 col-sm-2">
        <h4 id="footer-head">Subscribe to our Newsletter</h4>
        <form id="subscribe">
          <div className = "email-div">Your Email</div>
          <input type="email" size="24" onChange={this.onChange} value={this.state.email} className = "email-input" name="email"/>
          <button type='button'  onClick={()=>this.submitForm()} className="buttonsubs">Subscribe </button>
          <div id="success1"></div>
        </form>
      </div>
     
      <div className="col-lg-3 col-sm-2">
   
    <img className="footer-logo" style={{paddingLeft:'3px'}} src={require("../../Images/businusSchoolWhite.png")} /> 
     
   
   </div>
      

    </div>
  </div>
  </div>

  <div className="footer mobile">
  <div className="container">
   

    <div className="row footrow">
   
    <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-5">
        <h4 className="title-widget" id="footer-head">Company</h4>
        <ul>
          <div className = "footer-item"><Link id="footer-item"  to="/about-us">About us</Link></div>
          <div className = "footer-item"><Link id="footer-item"  to="/contact-us">Contact us</Link></div>
          <div className = "footer-item"><Link id="footer-item"  to="/contact">Work with us</Link></div>
          <div className = "footer-item"><Link id="footer-item"  to="/site-map">Site Map</Link></div>
          
        </ul>
      </div>
     

      <div className="col-lg-3 col-sm-2 col-5 social-media-h-f">
        <h4 id="footer-head">Find Us</h4>
        <a href="https://www.facebook.com/businusdotcom" target="_blank"> <img src={Facebook}/> </a>
        <a href="https://twitter.com/businusdotcom" target="_blank"> <img src={Twitter}/> </a>
        <a href="https://www.linkedin.com/company/businusdotcom/" target="_blank"> <img src={linkedIn}/> </a>
        <a href="https://www.youtube.com/channel/UCyuXLlNdLhlgF97f89yYrpg" target="_blank"> <img src={Youtube}/> </a>
        <a href="https://www.instagram.com/businusdotcom/" target="_blank"> <img src={Instagram}/> </a>
      </div>
    
      </div>
      <div className="row footrow">
     
      <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-10">
        <h4 id="footer-head">Subscribe to our Newsletter</h4>
        <form id="subscribe">
          <div className = "email-div">Your Email</div>
          <input type="email" size="24" onChange={this.onChange} value={this.state.email} className = "email-input" name="email"/>
          <button type='button'  onClick={()=>this.submitForm()}  className="buttonsubs">Subscribe </button>
          <div id="success1"></div>
        </form>
      </div>
    </div>
      

      
    <div className="row footrow">
     
    <div class="col-1 col-sm-2 mobile"></div>
      <div className="col-lg-3 col-sm-2 col-10">
      {/* <img style={{paddingLeft:'3px'}} src={require("../../images/logo3.png")} /> */}
        
      </div>
      </div>

      </div>
  </div>
  

<footer className="py-5  copyright">
  <div className="">
    {/* <div className="row">
      <div className="col-md-1 col-1 col-sm-1"></div>
      <div className="col-md-1 col-5 col-sm-2">
        <Link to="/terms-and-conditions" id="footer-icon">
            <img src={require("../../images/icontandc.png")}/>
            <h6 className="copytext">Terms and Conditions</h6></Link>
        </div>
        <div className="col-md-1 col-1 col-sm-2"></div>
        <div className="col-md-1 col-5 col-sm-2">
        <Link to="/privacy-policy" id="footer-icon">
            <img src={require("../../images/iconpri.png")}/>
            <h6 className="copytext">Privacy Policy</h6></Link>
        </div>
       
        <div className="col-md-4">
    
    </div>
    <div className="col-md-1 col-1 col-sm-1 mobile"></div>
    <div className="col-md-1 col-sm-1 col-5">
        <Link to="/confidentiality-policy" id="footer-icon">
            <img src={require("../../images/iconconf.png")}/>
            <h6 className="copytext">Confidentiality Policy</h6></Link>
        </div>
        <div className="col-md-1 col-sm-2 col-1"></div>
        <div className="col-md-1 col-sm-2 col-5">
        <Link to="/disclaimer-policy" id="footer-icon">
            <img src={require("../../images/icondis.png")}/>
            <h6 className="copytext">Disclaimer Policy</h6></Link>
        </div>
        <div className="col-md-1 col-sm-1 col-1"></div>
  </div> */}
  <div class="row"> <div className="col-md-4"></div><div className="col-md-4">
    <p className="m-0 text-center text-white khub_com_t_cy-rg">Copyright &copy; businus.school 2022</p>
    </div>
    <div className="col-md-4"></div>
  </div>
  </div>
</footer>
<Rodal visible={this.state.visible} height={100} className="pop-up" animation="flip" onClose={this.hide.bind(this)} >
  <div><p>Thank you for subscribing us</p></div>
</Rodal>
</div>

    );
  }
}
