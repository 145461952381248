import React, {useState} from "react"
import './comment.css'

export default function AddComment(props){
    const [comment, setComment] = useState('');
    const {handleComment} = props;
   return(
            <div>

                <div className='card mt-4 mb-3'>
                    <div className=' card-header'> 
                        
                    </div>
                    <div className='card-body'>

                <textarea name='comment' className='khub_comment_ta' placeholder='Add Your Comment...' 
                onChange={event=> setComment(event.target.value)} value={comment}></textarea>
                
                </div>
                </div>
                <div className='commentButton'>
                <button style={{marginRight:"1%", marginLeft:"-4%"}} className='btn btn-primary mr-3'
                onClick={event=> {
                    handleComment(comment)
                    setComment('');
                }}> Post Comment</button>

                <button className='btn btn-warn mr-3'
                onClick={event=> {
                    setComment('');
                }}> Cancel</button></div>
            </div>
        )
    }
