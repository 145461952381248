import React,{Component} from 'react'
import '../Navbar/navbarmain.css';
import { Link, } from 'react-router-dom';
import axios from 'axios';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import _ from 'underscore';
import BreadCrumbs from '../Common/BreadCrumbs';
import Topbar from '../Topbar/topbar';
import SideBar from '../Sidebar/SideBar';
import ReadOnly from '../Editor/ReadOnlyLegal';
import FirstColumnSkeleton from '../Skeleton/Legal/FirstCoulmnSkeleton';
import SecondColumnSkeleton from '../Skeleton/Legal/SecondColumnSkeleton';
import ThirdColumnSkeleton from '../Skeleton/Legal/ThirdColumnSkeleton';
import ReactTooltip from 'react-tooltip';
import Footer from '../Common/footer';

export default class GST extends Component {
    constructor(){
        super()
        this.state={
        allLegalContent:[],
        cont:[],
        opencont:true,
        file:'',
        data:[],
        distinct_law:[],
        heading:[],
        subHeading:[],
        external:[],
        internal:[],
        more:{},
        openmore1:false,
        openmore2:false,
        openshare1:false,
        openshare2:false,
        identity:'',
        tabdata:[],
        lawId:'',
        styletab:20,
        tabcontent:12,
        allid:[],
        tabids:[],
        shareurl:'',
        openmenu:false,
        copied:false,
        bookmark:false,
        allbookmark:[],
        by:'',
        email:'',
        type:'gst',
        stateOpen:false,
        allCategory:[],
        noCategoryFlag:false,
        noDataFlag:false,
        openMenu:false,
        }
        this.handlechange=this.handlechange.bind(this)
        this.more=this.more.bind(this)
        this.closemenu=this.closemenu.bind(this)     
        this.setType=this.setType.bind(this)
        this.legalContent=this.legalContent.bind(this)
        this.fetchCategory=this.fetchCategory.bind(this)
        this.userDetail=this.userDetail.bind(this)
        this.toggleMenu = this.toggleMenu.bind(this)
    }

    componentWillMount(){
      this.setType()
      this.userDetail()
      this.getbookmarks()
      this.reloadTimer()
    }

    userDetail(){
      if(sessionStorage.getItem("isLoggedIn")==="YES"){
        var name = sessionStorage.getItem("userName")
        name = name.trim()
        for(var i=0;i<name.length; i++){
          if(name[i]==='@'){
            name=name.slice(0,i)
            break
          }
        }
    
        this.setState({isLoggedIn:true});
        this.setState({by:name});
        this.setState({email:sessionStorage.getItem("email")});      
      }
    }

    async setType(){
      var token = this.props.match.params

      var type =''
      if(token.type==='case-law'){
        type='Case Law'
        
      }
      else if(token.type==='acts'){
        type='Act'  
      }
      else if(token.type==='rules'){
        type='Rules'      
      }
      else if(token.type==='circular-notification'){
        type='Circulars and Notification'      
      }
        
      await this.fetchCategory(type)
      this.legalContent(type)
    }

    fetchCategory(type){
      let allCategory=[]
  
      return axios({
        url: 'https://3j0okb7n5h.execute-api.us-east-1.amazonaws.com/dev/businus/legalCategory/list',
        method: 'GET',
        crossDomain: true,
        responseType: 'json', // important
      }).then((response) => {
        for(var i=0;i<response.data.length;i++){
          if(response.data[i].legalType==='GST')
            if(response.data[i].type===type){
            allCategory.push(response.data[i])
          }
        }
        allCategory=_.sortBy(allCategory,"index")
        this.setState({allCategory:allCategory})
       
     });
  }
    
    legalContent(type){
      let allLegalContent=[]
      var val=[]
      return axios({
        
        url:"https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/list",
        method:'GET',
        crossDomain: true,
        responseType: 'json',
        }).then((response) => {
          
          allLegalContent=response.data
        
        //filter all the data of case law in GST

        for(var i=0; i<allLegalContent.length; i++){
          if(allLegalContent[i].legalType==='GST'){
          if(allLegalContent[i].type===type){
            val.push(allLegalContent[i]); 
          }}        
        }
    
        var temp=[]

          var limitval=[]
          for(var i=0; i<val.length; i++){
            if(val[i].law===this.state.allCategory[0].id){
              limitval.push(val[i])
            }
          }

          limitval=_.sortBy(limitval,'index')
        this.setState({distinct_law:temp,data:limitval,lawId:this.state.allCategory[0].id,type:type,allLegalContent:val})
        if(limitval.length>0){this.handlechange(limitval[0].id)}
        this.isactive(type) // to call the active class for case law 
        this.isactivelaw(this.state.allCategory[0].id)  // to call the active class for law
        this.getbookmarks();

        })

    }

    async reloadTimer(){

      await setTimeout(()=>{
        if(this.state.allCategory.length===0){
          this.setState({noCategoryFlag:true})
        }
        if(this.state.data.length===0){
          this.setState({noDataFlag:true})
        }
      },4000)
    
    
  }


//this function is used to display the correct content in second sidebar
handlesection(type,law_id){
  var val=[]
  for(var i=0; i<this.state.allLegalContent.length;i++){
      if(this.state.allLegalContent[i].law===law_id){
        val.push(this.state.allLegalContent[i])
    }
    val = _.sortBy(val,'index')
 
  }
  if(val.length!==0){
    this.handlechange(val[0].id) // to active the first section from active law
  }
  
  this.setState({openshare2:false,openmore2:false,opencont:false,data:val,noDataFlag:val.length>0?false:true,lawId:law_id,tabdata:[],allid:[],tabids:[],styletab:20,tabcontent:12,copied:false})  
}


    handlechange(id){
      
      return axios({
        
        url:"https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/article/get/"+id,
        method:'GET',
        crossDomain: true,
        responseType: 'json',
        }).then((response) => {  
          this.setState({
           file:response.data.content,
        })
       
          var m=[]
          var n=[]
          var x={}
          var val="";
          this.setState({opencont:!this.state.opencont })
           // saving the data in json format so that we can map it and use it in further code. 
        for(var i=0; i<this.state.allLegalContent.length;i++){
          if(this.state.allLegalContent[i].id==id){
            val = this.state.allLegalContent[i]
            m=this.state.allLegalContent[i].heading
            n=this.state.allLegalContent[i].subHeading
            x=this.state.allLegalContent[i].more
        //allid is used to store ids of all the content which are open as a form of tab.
            //if id is present in allid it simply active the clicked tab
            if(this.state.allid.includes(id)){
              this.isactivetab(id)             
            }
            // if id is not present in allid then it will add all the data corresponding to that id in the previuosly created json variable
            //and add the id in allid
            else{
              this.state.tabdata.push({id:id,
                heading:val.heading,
              subHeading:val.subHeading,
              more:val.more,
            content:response.data.content,
            articleId:val.articleId})
            this.state.allid.push(id)
            }
           
          }
         
        }
        var st=this.state.styletab           //set the default tab size value to st
        var tc=this.state.tabcontent              // set the default number of alphabet visible in tab to tc

        //if new tab is not open then the value of st and tc will remains same
      if(this.state.tabids.includes(id)){
        st=this.state.styletab
        tc=this.state.tabcontent}
         //if the new tab is open then the value of st and tc will change 
        // the size and number of alphabet visible in tab will decrement by 1  
      else{
        this.state.tabids.push(id)
        if(this.state.styletab>8){
          st=st-1
        }
        if(this.state.tabcontent>=5){
          tc=tc-1
        }
      }  
      

        this.setState({openshare2:false,openmore2:false, more:x,heading:m,subHeading:n,cont:m,identity:id,styletab:st,tabcontent:tc,copied:false})
       this.checkbookmark(id)
      // this.isactivetab(id)
    })  
    }
 
    download() {

      html2canvas(document.getElementById("divcontents")).then(function(canvas){
        var imgheight = canvas.height*208/canvas.width
        var heightleft = imgheight
        
        const imgData = canvas.toDataURL('image/png')
        var doc = new jsPDF('p','mm','a4')

        doc.addImage(imgData,'PNG',0,0,208,imgheight)
        doc.save(this.state.heading)
      })

      // var input= document.getElementById('down1');
      // // var pdfInMM=210
      //   const pdf= new jsPDF();                            //jsPDF is reactjs module  used to create a pdf
      //  var temp= input.textContent || input.innerText;      // storing the value in input in temp variabls
      
      //   var split=pdf.splitTextToSize(temp,260)             // splitting the words in content and storing in the form of array. Here all the words will treat as a single element
      //   pdf.setFontSize(16);                               // setting fontsize for heading
      //   pdf.text(70,10,this.state.heading)                  //showing heading on screen. Here (70 is the left margin and 10 is top margin)
      //   pdf.setFontSize(14)                                 // setting fontsize for sub-heading
      //   pdf.text(70,30,this.state.subHeading)                   //showing sub-heading on screen 
      //   pdf.setFontSize(12)                                     // setting fontsize for content
      //   var y=45;                                               // setting initial top margin value for first page and it will be greater because there is heading and subheading on the first page so that it doesn't overlap
      //   //for loop is used to exract the each element in the variable temp storing the actual content and display it one by one by dynamically changing the values of the variables 
      //   for(var i=0;i<split.length;i++){
      //     if(y > 280){                                          //when y reach  at certain value we will add new page and we will start displaying content in that page, otherwise the content will be out of frame 
      //       y=10;
      //       pdf.addPage();
      //     }
      //     pdf.text(8,y,split[i]);
      //     y=y+7;
      //   }
        
      //   pdf.save('download.pdf');
       

    }

    getbookmarks(){
      return axios({
        url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/bookmark/list',
        method: 'GET',
        crossDomain: true,
        responseType: 'json', // important
      }).then((response) => {
        let allbookmark=[]
        for(var i =0; i<response.data.length; i++){
          if(response.data[i].userId===sessionStorage.getItem("userId")){
            allbookmark.push(response.data[i])
          }
        }
        this.setState({allbookmark:allbookmark}) ;
    
     })
    }
    
    checkbookmark(id){
      var bookmark = this.state.allbookmark;
      var flag = false
    
      for (var i=0; i<bookmark.length; i++){
        if(bookmark[i].khubId===id){
          flag=true
          break
        }
      }
      if(flag){
        this.setState({bookmark:true})
      }
      else{
        this.setState({bookmark:false})
      }
    }


    bookmarks(tabDetail,id){
      var bookmark = this.state.allbookmark
      var flag = true
      if(sessionStorage.getItem('isLoggedIn')==='YES'){
        for(var i=0; i<bookmark.length; i++){
          if(bookmark[i].khubId===id){
            this.deletebookmark(bookmark[i].id)
            flag = false
            break
          }
        }
        if(flag){
          this.updatebookmark(tabDetail)
        }
      }
      else{
        alert("You are not logged in")
      }
    }
    
    updatebookmark(data){
      this.setState({bookmark:true})
      var data = data
      var bookmark={
        bookmarkType:'article',
        userId:sessionStorage.getItem("userId"),
        khubId:data.id,
        title:data.heading,
        by:sessionStorage.getItem("userName"),
        email:sessionStorage.getItem("email")
      }
      axios({
        url:'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/bookmark/add/',
        method:'POST',
        data: bookmark,
      }).then((response)=>{
        if(response.status){
          this.setState({countbookmark:1})
          this.getbookmarks()
        }
      })
    }
    
    deletebookmark(id){
      axios({
        url: 'https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/bookmark/get/' + id,
        method: 'Delete',
      }).then((response) => {
        if(response.status){
          this.setState({bookmark:false})
          this.getbookmarks()
        }
      });
    }
    

    openMenu(){
      this.setState({openmenu:true,})
      }

      toggleMenu(){
      
        if(this.state.openmenu){
        
          this.setState({openmenu:false});
          
        }
        else{
          this.setState({openmenu:true});
          this.refs.child.setInitialState('GST');
         
        }
      }
      
      closemenu(openmenu){
        if(openmenu){
          this.setState({openmenu:false})
        }
      }
    
    more(i){
      var ex_temp=[]
      var in_temp=[]
      //check whether it is a call from modal more or the 3rd sidebar more
      if(i==='1'){
          this.setState({openmore1:!this.state.openmore1,openshare1:false})
      }
      else{
          this.setState({openmore2:!this.state.openmore2,openshare2:false})
      }
      
      
      if(this.state.more!==undefined){
        ex_temp=this.state.more.external
        in_temp=this.state.more.internal

         
      }
      // if(this.state.more==undefined){
      //   ex_temp=['No references']
      //   in_temp=[heading='No references']
      // }
      this.setState({internal:in_temp,external:ex_temp})  
    }

    share(i){
         //check whether it is a call from modal more or the 3rd sidebar more
      if(i==='1'){
          this.setState({shareurl:window.location.href, openshare1:!this.state.openshare1,openmore1:false})              // creating a dynamic url to share using id 
      }
      else{
          this.setState({shareurl:window.location.href, openshare2:!this.state.openshare2,openmore2:false})
      }
    }

    // this function is for allocating active class to correct name in navbar  
    isactive(value){
      return 'highlight '+((value==this.state.type)?'activenav':'default') 
    }
    // this function is for allocating active class to correct law name in 1 sidebar  
    isactivelaw(name){
        return "filter-buttons "+((name==this.state.lawId)?'activelaw':"default")
    }
// to change the state of clicked section title and id so that we can show active class
    secactive(id){
      this.setState({identity:id,copied:false,openmore2:false,openshare2:false})
      this.isactivesec(id)
      this.checkbookmark(id)
    }

// to  remove the tab and tab content when user clicked on cross.
    removetab =(i)=>{
      // here we storing all the data related to tabs into a temparory variable so that we can perform all the operation on them
      // after all the operation we will assign these varaibles value to their respective state.
      var activeid= this.state.allid[i]
      var newTabdata = this.state.tabdata;
      var newallid= this.state.allid;
      // deleting the tab data and id from allid and tab id
      var del = newTabdata.splice(i,1);
      var del1= newallid.splice(i,1);
      var del2= this.state.tabids.splice(i,1)
       //  assigning the tab width to st and number of character to tc
      var st=this.state.styletab
      var tc=this.state.tabcontent
        //increasing the size of tab and number of character after cancelling the tab 
      if(this.state.styletab<20){
        st=st+1  
      }
      if(this.state.tabcontent<=12){
        tc=tc+1
      } 

      //here we decide which tab should open if we cancel the tab
      //if we cancel some other tab instead of which we are reading then the same tab will remains open  
      // if we cancel the reading tab and it is first tab in the queue then the next tab will open
      //if we cancel the reading tab and it is not the first  tab then the previous tab will open
      if(activeid==this.state.identity){
          if(i==0){
            var id=this.state.allid[i]
          }
        if(i!=0){
          var id=this.state.allid[i-1]
          }
      } 
      else{
        for(var i=0; i<newallid.length; i++){
          if(newallid[i]==this.state.identity){
            var id=newallid[i]
          }
        } 
     }
      
      this.setState({tabdata:newTabdata,allid:newallid,styletab:st,tabcontent:tc,identity:id});

    }

 // this function is used to copy the link to clickboard and e is the id which is passed through function.
    copy =(e)=>{
     
      var link=window.location.href
      var textField = document.createElement('textarea')
      textField.innerText = link
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      this.setState({copied:e})
    }

    print=()=>{
      var content = document.getElementById("readonly");                                   // all content will save here
      var pri = document.getElementById("ifmcontentstoprint").contentWindow;                  // open up the print functionality on new page
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }
// this function used for active class tab 
    isactivetab(id){
      return ((id==this.state.identity)?'activetab':'notactivetab')
    } 
    //this the function used for active class for content
    isactivetabcont(id){
      return  id+ ' tab-pane fade '+ (id==this.state.identity?' active in':'') 
    }
    
    isactivesec(id){
      return 'section '+((id==this.state.identity)?'activesec':'default')
    }
    //this function will set more and share condition as false so that when user opt for popup then they will also close 
    closeall(){
      this.setState({openmore1:false,openshare1:false})
    } 
    //this function will set more and share condition as false so that when user close the popup then they will also close
    closeall1(){
      this.setState({openmore2:false,openshare2:false})
    }
    render(){
      
      return(
        <div>      
          <nav className="navbar main-navbar navbar-default" style={{paddingTop: '38px',height: '14vh',}}>
            <div>
              {/* <NavbarMain/> */}
              <ul className="nav navbar-nav modification"> 
              <span style={{float:'left',cursor:'pointer',padding:'10px'}} 
              onClick={this.toggleMenu}> <i class="fa fa-bars" aria-hidden="true"></i> </span>
              
              <SideBar ref='child' stateOpen={this.state.openmenu} closeMenu={this.closemenu} type='gst' url='gst' />
              <li > 
              <div className='company-logo'>
              <Link to ="/legal-finance"> <img  className="businus-logo"src={require('../Training/images/businusSchool.png')}/> </Link>
              <div>{""}</div>
              <h5 className="subhead" style={{marginTop:'-5px'}}>GST</h5>
              </div>
              </li>

              <li className="sub-cat" style={{paddingLeft:"2%"}}><a href='/company-law/case-law' className={this.isactive('case laws') + ' company-padding'}>Case Laws</a></li>
              <li className="sub-cat"><a href='/GST/acts' className={this.isactive('Act')}>Acts </a> </li>
              <li className="sub-cat"><a href='/GST/rules' className={this.isactive('Rules')}>Rules </a> </li>
              <li className="sub-cat"><a href='/GST/circular-notification' className={this.isactive('Circulars and Notification')}>Circular & Notification </a></li>
              </ul>
            </div>
                  
  
           </nav>
  
  <div> <BreadCrumbs/> </div>


           {/* <nav className='sec-navbar navbar navbar-default'>
             <div className='sec-navbar2'>
               <ul className='nav navbar-nav' style={{width:'100%'}}> */}
                  {/* <li className='sec-cat ' style={{cursor:'pointer'}}>
                    <a  href="#" className={this.isactive('')} >Home</a> 
                  </li> */}
{/*   
                  <li className='sec-cat' style={{cursor:'pointer'}}>
                    <a href='#' className={this.isactive('case laws')} onClick={()=> this.case_laws()}> Case Laws</a> 
                  </li>
                 
                  <li className='sec-cat ' style={{cursor:'pointer'}}>
                    <a  href="#" className={this.isactive('acts')} onClick={()=> this.acts()}>Acts</a> 
                  </li>
  
                  <li className='sec-cat' style={{cursor:'pointer'}}>
                    <a  href="#" className={this.isactive('rules')} onClick={()=> this.rules()}>Rules</a> 
                  </li>
                  <li className='sec-cat' style={{cursor:'pointer'}}>
                    <a  href="#" className={this.isactive('circular')} onClick={()=> this.circular()}>Circular & Notification</a> 
                  </li>
               </ul>
             </div>
  
           </nav> */}

<div className="Searchbarr">
            <Topbar/>
            
          </div>
         
           <div className="row">
                  <ul className="nav nav-pills set_margin" style={{}}>
   
                  {this.state.tabdata.length!==0?
                  this.state.tabdata.map((tab,index) =>(

                  <li className='tabclass' key={index} style={{width:this.state.styletab+'%',marginBottom:'-1vh'}}  ><a data-toggle="tab" href={"#tab"+tab.id} className={this.isactivetab(tab.id)} style={{fontSize:'12px'}} >
                  <a data-for='tab-heading' data-tip={tab.heading} onClick={()=> this.secactive(tab.id)}>  {tab.heading.substring(0,this.state.tabcontent)}</a>  
                  </a><span className='closetab' onClick={()=>this.removetab(index)}><i class="fas fa-times"></i></span>
                  <ReactTooltip place='top' id='tab-heading' />
                  </li> 
                 
                   )):
                   <li style={{width:"10%"}} className='tabclass'>
                     <a className="notactivetab"></a>
                     <a></a>
                     </li>
                   }
                  </ul>
                    
                    </div> 
            
            <div className='col-md-2 text-middle'>
            <div className="filter1-card" style={{marginTop:"-33px"}}>
                <h4 style={{fontSize:'22px',fontWeight:'800'}}> </h4>
                   {this.state.noCategoryFlag?
                <div>
                  <h4>No Data</h4>
                </div>:
                this.state.allCategory.length!==0?
                   this.state.allCategory.map((laws_name,index)=>(
                    <button className={this.isactivelaw(laws_name.id)} onClick={()=>this.handlesection(laws_name.type, laws_name.id)}> 
                    <h5  className='second-head' style={{cursor:'pointer',color:'rgb(108, 205, 223)'}}  >
                        {laws_name.law}    </h5>
                        <div className='second-subhead' >{laws_name.lawDes}</div>  
                    </button>
                        )
                    ):<div> <FirstColumnSkeleton/> </div>
                    }
                  </div>
            </div> 

            <div className="row">
            <div className='   col-md-3 text-middle'>
            <div className='filter2-card' style={{marginTop:"-33px"}}>
                {this.state.noDataFlag?
                <div>
                  <h4>No Data</h4>
                </div>
                :
                this.state.data.length!==0?
                this.state.data.map((laws_name,index) =>(
                  
                 <div className={this.isactivesec(laws_name.id)}>
                 <div  className="second-head" id={laws_name.id} style={{cursor:'pointer' ,color:'rgb(108, 205, 223)'}} onClick={()=> this.handlechange(laws_name.id)}> 
                 {laws_name.section}</div>
                 <div className="second-subhead">{laws_name.sectionDes}</div>
   
                  <br/> </div>
                )
                  ):<div> <SecondColumnSkeleton/> </div>}
                  </div>
            </div>
            
            <div className='filter3-card col-md-5 text-middle'>
                   
          
            <div class="tab-content">
            {this.state.noDataFlag?
                <div>
                  <h4>No Data</h4>
                </div>
                :
            this.state.tabdata.length!==0?
            this.state.tabdata.map((tab,index) =>(

            <div id={"tab"+tab.id} className={this.isactivetabcont(tab.id)}>
            
            <div>
                
                <div className={"modal fade "+ index} tabindex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" id={tab.id}>
                <div className="modal-dialog modal-lg">
                <div className="modal-content">   
                <div className='bottom-line1 btm-2'>
               
              <div className="btn button-color" data-dismiss='modal' aria-label='close' onClick={()=> this.closeall()}>
               <span data-for="resize"
              data-tip="Minimize" className="glyphicon glyphicon-resize-small"></span> 
               <ReactTooltip place="bottom" id="resize"/> 
                </div>
              {/* <div className="btn button-color" onClick={()=>this.download()}>
               <span data-for="download" data-tip="Download" className="glyphicon glyphicon-download-alt"></span> 
               <ReactTooltip place="bottom" id="download"/> 
                </div> */}
                <div  className='more'>                  
                  <h5 data-for='more' data-tip='More' style={{paddingTop:'-5px',fontSize:'14px'}} onClick={()=> this.more('1')}>  More </h5>                  
                  <ReactTooltip place='bottom' id='more' />
                </div>
                
                  
                <div className='share'>                    
                      <div onClick={()=>this.share(tab.id,'1')}>
                  <i data-for="main"
              data-tip="Share"
              data-iscapture="true" class="fas fa-share-alt"></i>      
              <ReactTooltip id="main"/>              
                  </div>
                  </div>
                {!this.state.bookmark?
                <div data-for="bookmark" data-tip="Bookmark" className='bookmark' onClick={()=> this.bookmarks(tab,tab.id)}> <span><i class="far fa-bookmark"></i></span>
                </div>:
                 <div data-for="bookmark" data-tip="Bookmark" className='bookmark'  onClick={()=> this.bookmarks(tab,tab.id)}> <span><i class="fas fa-bookmark"></i></span></div>
                }
                <ReactTooltip place="bottom" id="bookmark"/>
                {!this.state.copied?
            <div data-for="copy" data-tip="Copy" title='Copy' className='copy1' onClick={()=>this.copy(true)}><i class="far fa-copy"></i>
            </div> :
            <div data-for="copy" data-tip="Copy" title='Copy' className='copy1' onClick={()=>this.copy(false)}><i class="fas fa-copy"></i>
              </div>}
              <ReactTooltip place="bottom" id="copy"/>
                </div>
                <div className="modal-content-main">
                {this.state.openmore1?
               
               <div >
                 <div className='arrow-up1'></div>
                 <div className='moreopt1'>
                   {this.state.external!=''?
                   <table className='table'>
                   <thead>
                     <tr>
                       <th scope='col'>Source</th>
                       <th scope='col'>Name</th>
                       <th scope='col'>URL</th>
                     </tr>
                   </thead>
                    {this.state.external.map((show_more,index)=>(
            
                  <tbody>
                    <tr>
                      <td>
                        <div style={{color:'#353333'}}>
                        <small>{show_more.src.toUpperCase()}</small></div>
                      </td>
                      <td>
                        <small> <a style={{color:'#6b6868'}}>{show_more.name}</a> </small>
                      </td>
                      <td>
                        <small> <a href={show_more.url} target="_blank" style={{color:'blue'}}>{show_more.url}</a> </small>
                      </td>
                    </tr>
                  </tbody>
   
               ))}
               </table>:
               <div> No Information Found </div>
                  }
                 </div>   

              </div> :''}
                <div style={{marginTop:'15px'}} className="modal-header">

                  
                  <h5 class="modal-title" id="exampleModalLongTitle">
                  <div className='heading-case'>{tab.heading}</div>
                  <div className='subheading-case' id='down'>{tab.subHeading}</div>
                  </h5>
                  
                </div>
               <div class="modal-body">

             

               {this.state.openshare1?
                <div >
                <div className='shareall1'>
              <a href={"https://www.facebook.com/sharer.php?u=https://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="blank">
               <div className='share-option'>  
               <i className="fab fa-facebook-square"></i></div></a>

               <a href={"mailto: ?body= http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_top">
               <div className='share-option'>
               <i className="fas fa-envelope"></i>
               </div></a>

               <a href={"https://api.whatsapp.com/send?text=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
               <div className='share-option'>    
               <i class="fab fa-whatsapp"></i></div></a>

               <a href={"https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
               <div className='share-option'>    
               <i class="fab fa-twitter"></i></div></a>

               <a href={"https://www.linkedin.com/shareArticle?mini=true&url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
               <div className='share-option'>    
               <i class="fab fa-linkedin"></i></div></a>
               </div>
                 </div> :'' 
                }
               {/* <div className='content-case' dangerouslySetInnerHTML ={{__html: tab.content}}></div> */}
               <div id='readonly'>
                    <ReadOnly data={tab.content} />
                  </div>
              
              </div>
              </div>
              </div>
              </div>
              </div>
                  
                <div>
                <div className='bottom-line'>
              <div className="btn button-color" data-toggle="modal" data-target={'.'+index} onClick={()=> this.closeall1()}>
               <span data-for="resize" data-tip="Maximize" className="glyphicon glyphicon-resize-full"></span> 
               <ReactTooltip place="top" id="resize"/> 
                </div>
              {/* <div className="btn button-color" onClick={()=>this.download()}>
               <span data-for="download" data-tip="download" className="glyphicon glyphicon-download-alt"></span> 
               <ReactTooltip place="top" id="download"/> 
                </div> */}

                <div className='more'>                  
                  <h5 data-for='more' data-tip='More' style={{paddingTop:'-5px',fontSize:'14px'}} onClick={()=> this.more('2')}>  More </h5>                  
                  <ReactTooltip place='bottom' id='more' />
                </div>


                <div data-for="main" data-tip="Share"  className='share'>                    
                    <div onClick={()=>this.share(tab.id,'2')}>
                   
                  <i  data-toggle="tooltip" data-placement="top" title="Tooltip on top" className="fas fa-share-alt"></i>                 
                  </div>
                  </div>    <ReactTooltip place="top" id="main"/> 

                  
                  
                {!this.state.bookmark?
                <div data-for="bookmark" data-tip="Bookmark" className='bookmark' onClick={()=> this.bookmarks(tab,tab.id)}> <span><i class="far fa-bookmark"></i></span>
                </div>:
                 <div data-for="bookmark" data-tip="Bookmark" className='bookmark'  onClick={()=> this.bookmarks(tab,tab.id)}> <span><i class="fas fa-bookmark"></i></span></div>
                }
                <ReactTooltip place="top" id="bookmark"/>
              {!this.state.copied?
            <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(true)}><i class="far fa-copy"></i>
            </div> :
            <div data-for="copy" data-tip="Copy" className='copy1' onClick={()=>this.copy(false)}><i class="fas fa-copy"></i>
              </div>}
              <ReactTooltip place="top" id="copy"/>
                {/* <div data-for="print" data-tip="print" className='print'>                  
                  <div  onClick={()=> this.print()}> <i class="fas fa-print"></i> </div>                  
                  </div>
                  <ReactTooltip place="top" id="print"/>  */}
                </div>
              
               <div className="content-box" >
                 
                 {this.state.openshare2?
                <div >
                <div className='shareall'>
              <a href={"https://www.facebook.com/sharer.php?u=https://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="blank">
               <div className='share-option'>  
               <i className="fab fa-facebook-square"></i></div></a>

               <a href={"mailto: ?body= http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_top">
               <div className='share-option'>
               <i className="fas fa-envelope"></i>
               </div></a>

               <a href={"https://api.whatsapp.com/send?text=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
               <div className='share-option'>    
               <i class="fab fa-whatsapp"></i></div></a>

               <a href={"https://twitter.com/intent/tweet/?text=Super%20fast%20and%20easy%20Social%20Media%20Sharing%20Buttons.%20No%20JavaScript.%20No%20tracking.&amp;url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
               <div className='share-option'>    
               <i class="fab fa-twitter"></i></div></a>

               <a href={"https://www.linkedin.com/shareArticle?mini=true&url=http://knowledge-hub-ui.firebaseapp.com"+this.state.shareurl} target="_blank"> 
               <div className='share-option'>    
               <i class="fab fa-linkedin"></i></div></a>
               </div>
                 </div> :'' 
                }

               {this.state.openmore2?
               
               <div >
                 <div className='arrow-up'></div>
                 <div className='moreopt'>
                   {this.state.external!=''?
                   <table className='table'>
                   <thead>
                     <tr>
                       <th scope='col'>Source</th>
                       <th scope='col'>Name</th>
                       <th scope='col'>URL</th>
                     </tr>
                   </thead>
                    {this.state.external.map((show_more,index)=>(
   
                  <tbody>
                    <tr>
                      <td>
                        <div style={{color:'#353333'}}>
                        <small>{show_more.src.toUpperCase()}</small></div>
                      </td>
                      <td>
                        <small> <a style={{color:'#6b6868'}}>{show_more.name}</a> </small>
                      </td>
                      <td>
                        <small> <a href={show_more.url} target="_blank" style={{color:'blue'}}>{show_more.url}</a> </small>
                      </td>
                    </tr>
                  </tbody>
   
                 
                 ))}</table>:
                      <div> No Information Found </div>
                  }
                 
                {/* {this.state.internal.map((show_int,index)=>(
                <div onClick={()=>this.handlechange(show_int.id) }style={{color:'#353333'}}>
                  {show_int.heading}<br/>
              </div>
            ))} */}
            </div>
          
              

              </div>:''
               }

                <div id='divcontents'>
                <div className='heading-case'>{tab.heading}</div>
                <div className='subheading-case'>{tab.subHeading}</div>

                <div id='readonly'>
                    <ReadOnly data={tab.content} />
                  </div>
              
                  {/* <div className='content-case' id='down1' dangerouslySetInnerHTML ={{__html: tab.content}}></div> */}
               </div>
               <iframe id="ifmcontentstoprint" style={{height:"0px", width:"0px", position:"absolute"}}></iframe>
                </div><br/></div> 
                
               
               
                </div>
          
                  </div>

            )):
            <div> <ThirdColumnSkeleton/> </div>
            }
          </div>
            
            </div>
            </div>


  <Footer />

          </div>
      );
    }
  }
  