import React from "react";
import SkeletonElement from "../SkeletonElement";

const SecondColumnSkeleton =()=>{
    return(
        <div>
            {[1,2,3,4,5].map((n)=> 
            <div className="skeleton-wrapper-legal">
            <div>
                <SkeletonElement type="legal-title"/>
                    <SkeletonElement type="sk-text"/>
                    <SkeletonElement type="sk-text"/>

            </div>
                
                </div>)}
        </div>
    )
}

export default SecondColumnSkeleton