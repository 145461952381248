import React, {Component} from 'react'
import Moment from 'react-moment'
import {Link} from 'react-router-dom'
import './QnA.css'

export default class QnA extends Component{
    constructor(props){
        super(props)
        this.state={
            isReply:false,
            replyArray:[],
            reply:'',
            lastIndex:2,
            // isMoreReply:this.props.reply.length>=3?true:false,
            // isLessReply:false,
        }
        this.handleReply= this.handleReply.bind(this)
    }


// componentWillReceiveProps(nextProps){
//     if(nextProps.reply===this.props.reply){
//         this.setState({replyArray:nextProps.reply.slice(0,2)})
//     }
//     if(nextProps.reply!==this.props.reply){
//         this.setState({replyArray:nextProps.reply.slice(0,2),isLessReply:false,isMoreReply:nextProps.reply.length>=3?true:false})
//     }
// }

handleReply(){
    this.setState({
        isReply:!this.state.isReply
    })
}

reply(reply){
    this.setState({reply:reply})
}

postReply(blankReply,id){

  if(sessionStorage.getItem("isLoggedIn")==="YES"){
    this.props.handleCommentReply(id,this.state.reply)
    this.reply(blankReply)
    this.handleReply()
  }
  else{
      alert("Please Login first before replying")
  }
}

cancelReply(){
    this.setState({reply:'',isReply:false})
}

seeMoreReply(){
    let replyArray = this.props.reply.slice(0,this.state.lastIndex+5)
    let isMoreReply = this.props.reply.length<=replyArray.length?false:true
    this.setState({replyArray:replyArray,lastIndex:this.state.lastIndex+5,isLessReply:true,isMoreReply:isMoreReply})
}

seeLessReply(){
    this.setState({replyArray:this.props.reply.slice(0,2),lastIndex:2,isLessReply:false,isMoreReply:true})
}

    render(){
        return(
            <div className='singleQuestion'>
                <img class="commentPic" src={require('../../Images/alphabet-images/'+this.props.pic+'.png')}></img>
                <span className="commentName">
                        {this.props.name}
                    </span>
                    <Moment className='commentTime' fromNow>{this.props.time}</Moment>
                    <div className='question'>
                      <Link to={'/businus/legal/blogs/'+this.props.key}>  {this.props.question}  </Link> 
                    </div>
                    <div className='questionDescription'>
                        {this.props.description}
                    </div>
                    {/* {this.state.replyArray!=[]?
                        this.state.replyArray.map((reply,index)=>(
                            <div>
                                <img className='replyPic' src={require("../../Images/alphabet-images/"+reply.pic+".png")}/>
                            <span className="replyName">
                                {reply.name}
                            </span>
                            <Moment className='commentTime' fromNow>{reply.time}</Moment>
                            <div className='reply'>
                                {reply.comment}
                            </div>
                            </div>
                        )):''
                    }
                    <div className="seeReply col-md-12 col-lg-12">
                        <div className='col-md-8 col-lg-8'></div>
                        <div className='col-md-4 col-lg-4'>
                        {this.state.isMoreReply?
                        <span style={{marginLeft:'40%',marginRight:"5%"}}>
                            <span style={{marginRight:'2%'}} onClick={()=>this.seeMoreReply()}>See More</span>
                            <i class="fas fa-angle-double-down"></i>
                        </span>                        
                        :''
                        }
                        {this.state.isLessReply?
                            <span style={{float:'right',width:'3vw'}}>
                                <span  style={{marginRight:'3%'}} onClick={()=> this.seeLessReply()}>Less</span>
                                <i class="fas fa-angle-double-up"></i>
                            </span>
                        :''
                        }</div>
                    </div>
                    {!this.state.isReply?
                    <div style={{paddingLeft:'2%',cursor:'pointer'}} onClick={()=> this.handleReply()}>
                       <img src={require("../../Images/reply-black-18dp/1x/reply.png")}/> Reply
                    </div>:''
                    }
                    {this.state.isReply===true?
                    <div style={{paddingLeft:'2%'}}>
                        <textarea className='form-control' style={{width:'88%'}} placeholder='Reply...' name='reply' onChange={event=>this.reply(event.target.value)}>

                        </textarea>
                        <div style={{marginTop:'1%'}}>
                        <button style={{marginRight:'1%'}} className="btn btn-primary mr-3" onClick={()=> this.postReply('',this.props.id)}> Post </button>
                        <button className="btn btn-warn mr-3" onClick={()=>this.cancelReply('')}> Cancel </button></div>
                    </div>:''
                    } */}
            </div>
        )
    }
}