import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactTooltip from 'react-tooltip';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter , Route, Link, Routes } from "react-router-dom";
import Guides from './components/LegalFinance/guides';
import Resourcespage from './components/LegalFinance/resourcespage';
import Company_law from './components/Guide/company_law';
import GST from './components/Guide/gst';
import Custom from './components/Guide/custom';
import Contact from './components/Common/Contact/contact';
import About from './components/Common/About/about';
// import Strategic from './components/Training/Training/strategic';
// import Functional from './components/Training/Training/functional'
// import SendQuery from './components/Training/Training/sendquery'
// import RequestQuoteTrain from './components/Training/Training/requestquotetraining'
import Amplify from 'aws-amplify';
import config from './awsconfig';
import Login from './components/LoginProfile/Login/Login';
import DedicatedQnA from './components/QnA/dedicatedQnA';
import Register from './components/LoginProfile/Login/register';
import EximLandingPage from './components/Exim/EximLandingPage';
import EximDedicatedPage from './components/Exim/EximDedicatedPage';
import WealthLandingPage from './components/Wealth/WealthLandingPage';
import WealthDedicatedPage from './components/Wealth/WealthDediactedPage';
import OpportunityLandingPage from './components/Opportunity/OpportunityLandingPage';
import OpportunityDedicatedPage from './components/Opportunity/OpportunityDedicatedPage';
import Termsandconditions from './components/Terms/termsandconditions';
import Disclaimer from './components/Terms/disclaimerterms';
import Confidentialityterms from './components/Terms/confidentialityterms';
import Privacyterms from './components/Terms/privacyterms';
import Homepage from './components/Homepage/Homepage';
import GuideHomepage from './components/Guides/guidesHomepage';
import GuideDedicatedPage from './components/Guides/guidesDedicatedPage';
import Sitemap from './components/Common/sitemap/Sitemap';
Amplify.configure({
    Auth:{
      mandatorySignId: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
    }
  })

ReactDOM.render(<div>
    <div>
    <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<Homepage/>} />
    <Route exact path="/legal-finance" element={<Guides/>} />
    <Route exact path="/login" element={<Login/>} />
    <Route exact path="/contact-us" element={<Contact/>} />
    <Route exact path="/work-with-us" element={<Contact/>} />
    <Route exact path="/about" element={<About/>} />
    <Route exact path='/site-map' element={<Sitemap/>}/>
    <Route exact path="/register" element={<Register/>} />
    {/* <Route exact path='/legal-finance/resources/:id' element={<Resourcespage/>}/> */}
    <Route exact path='/legal-finance/resources' element={<Resourcespage/>}/>

    <Route exact path='/legal-finance/guides' element={<GuideHomepage/>}/>
    <Route exact path='/legal-finance/guides/:id' element={<GuideDedicatedPage/>}/>

    <Route exact path="/exim" element={<EximLandingPage/>} />
    <Route exact path="/exim/resources" element={<EximDedicatedPage/>} />

    <Route exact path="/stories-insights" element={<WealthLandingPage/>} />
    <Route exact path="/stories-insights/resources" element={<WealthDedicatedPage/>} />

    <Route exact path="/opportunity-growth" element={<OpportunityLandingPage/>} />
    <Route exact path="/opportunity-growth/resources" element={<OpportunityDedicatedPage/>} />


    {/* <Route exact path='/company-law/:type' element={Company_law}/>
    <Route exact path='/GST/:type' element={GST}/>
    <Route exact path='/custom/:type' element={Custom}/> */}
    
    <Route exact path='/terms-and-conditions' element={<Termsandconditions/>}/>
    <Route exact path='/privacy-policy' element={<Privacyterms/>}/>
    <Route exact path='/confidentiality-policy' element={<Confidentialityterms/>}/>
    <Route exact path='/disclaimer-policy' element={<Disclaimer/>}/>
    <Route exact path='/businus/legal/blogs/:questionkey/:id' element={<DedicatedQnA/>} />
    

    {/* <Route exact path = "/" component = {Strategic} /> */}
    {/* <Route exact path = "/strategic-training" component = {Strategic} />
   
    <Route exact path = "/functional-training" component = {Functional} />
    <Route exact path = "/send-a-query" component = {SendQuery} />
    <Route exact path = "/request-a-proposal" component = {RequestQuoteTrain} /> */}  
</Routes>
    </BrowserRouter></div>
    </div>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
