import React, { Component } from 'react';
import '../Navbar/navbarmain.css';
import { Link, BrowserRouter } from 'react-router-dom';
import Topbar from '../Topbar/topbar';
import SideBar from '../Sidebar/SideBar';



export default class OpportunityNavbarMain extends Component {
  constructor(props){
    super(props);
    this.state={
      openmenu:false,
    }
    
    this.closemenu=this.closemenu.bind(this)
    this.toggleMenu=this.toggleMenu.bind(this)
  }


openMenu(){
this.setState({openmenu:true,})
}

toggleMenu(){
      
  if(this.state.openmenu){
  
    this.setState({openmenu:false});
    
  }
  else{
    this.setState({openmenu:true});
    this.refs.child.setInitialState("Opportunity");
   
  }
}

closemenu(openmenu){
  if(openmenu){
    this.setState({openmenu:false,})
  }
}

 
  render(){
    return(
      <div>
      <div className='main-navbar'>
        <nav className=" navbar navbar-default modification" style={{marginBottom:'0px', paddingTop:'7px'}}>
          <div className='col-md-6'>
          <div>
            <ul className="nav navbar-nav navbar-contained"> 
            <li style={{float:'left',cursor:'pointer',padding:'10px'}} 
            onClick={this.toggleMenu}> 
            <i class="fa fa-bars" aria-hidden="true"></i> 
            </li>
            
            <SideBar ref='child' stateOpen={this.state.openmenu} closeMenu={this.closemenu} type="opportunity"/>
          
            
            <li > 
            <div className='company-logo'>
            <Link to ="/"> <img  className="businus-logo"src={require('../Training/images/businusSchool.png')}/> </Link>

              <div>{""}</div>
              <h5 className="subhead">Opportunity & Growth</h5>
              </div>
            </li>
              <li className='sub-cat hidden-xs hidden-sm' style={{cursor:'pointer'}}>
                <Link to ="/opportunity-growth" className='highlight2'>Articles</Link>       
              </li>
              
       
              </ul>
              </div>
        </div>

         </nav>

         </div>
    
          <Topbar showBlogCategory={true} />
        
        </div>
    );
  }
}
